import { FieldProps } from "formik";
import { v4 as uuidv4 } from "uuid";
import { Container } from "./styles";
import { useRef, useState } from "react";
import { Text } from "alisson-application";
import imgPlus from "@/presentation/assets/icone/plus.svg";

export interface Field {
    label: string;
    value: string;
}

export function CustomField(props: FieldProps) {
    const { form } = props;
    const { setFieldValue } = form;
    const [fields, setFields] = useState<Field[]>([]);
    const [tempFields, setTempFields] = useState<Field[]>([]);

    const uniqueValuesSet = new Set();
    const ref = useRef<HTMLInputElement>(null);

    function handleAdd() {
        if (ref.current?.value) {
            const value = ref.current.value;
            const hasIsValue = fields.find(
                (item) => item.label.toLowerCase() === value.toLocaleLowerCase()
            )?.value;

            if (hasIsValue) {
                console.log("já existe esse valor");
                return;
            }

            const update = [
                ...fields,
                { value: uuidv4(), label: ref.current.value },
            ];

            setFields(update);

            getCurrentValue(update);
            ref.current.value = "";
        }
    }

    function handleRemoveArea(id: string) {
        const update = fields.filter((a) => a.value !== id);
        setFields(update);
        getCurrentValue(update);
    }

    function handleChange(newValue: string, oldValue: string) {
        const updateArea = fields.filter((area) => {
            if (area.label === oldValue) {
                area.label = newValue;
                return area;
            }
            return area;
        });

        const filteredArr = [...updateArea, ...tempFields].filter((obj) => {
            const isPresentInSet = uniqueValuesSet.has(obj.value);
            uniqueValuesSet.add(obj.value);
            return !isPresentInSet;
        });

        setFields(filteredArr);
        setTempFields(filteredArr);
        getCurrentValue(filteredArr);
    }

    function getCurrentValue(update: Field[]) {
        setFieldValue(
            "extra_fields",
            update.map((item) => item.label)
        );
    }

    return (
        <Container>
            <button type="button" onClick={handleAdd}>
                <img src={imgPlus} alt="" /> Adicionar campo personalizado
            </button>

            <div className="container-input add">
                <input
                    ref={ref}
                    type="text"
                    placeholder="Campo personalizado"
                />
            </div>

            {fields.map((field) => {
                return (
                    <div className="container-input" key={field.value}>
                        <input
                            type="text"
                            onChange={(e) => {
                                handleChange(e.target.value, field.label);
                            }}
                            placeholder="Campo personalizado"
                            value={field.label}
                        />
                        <button onClick={() => handleRemoveArea(field.value)}>
                            <Text as="p" fontSize="xs" color="secondarydark">
                                remover
                            </Text>
                        </button>
                    </div>
                );
            })}
        </Container>
    );
}
