import { Footer as Container } from "./styles";
import imgLogo from "@/presentation/assets/img/logo-yetz.svg";

export function Footer() {
    const date = new Date();
    const currentYear = date.getFullYear();
    return (
        <Container>
            <span>
                YETZ LTDA | CNPJ: 28.325.166/0001-05 {currentYear} | Todos os
                direitos reservados
            </span>

            <div className="container-img">
                <img src={imgLogo} alt="" />
            </div>
        </Container>
    );
}
