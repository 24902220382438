import styled from "styled-components";

export const Container = styled.div`
    .container-cards {
        display: flex;
        align-items: center;
        div {
            margin-right: 4px;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
`;
