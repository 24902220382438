import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.theme.colors.gradients.violet};
    height: calc(100vh - 82px);
    width: 100%;
    position: relative;
    color: ${(props) => props.theme.colors.white};
    font-weight: 400;

    .container-global-dashboard {
        display: flex;
        flex-direction: column;
        padding: 64px 0px;
    }

    .container-topo-logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .container-topo-sair {
        display: flex;
        align-items: center;
    }

    .container-titulo {
        display: flex;
        justify-content: center;
        margin-top: 132px;
        font-size: 42px;
    }

    .container-sair img {
        margin: 0 10px 0 50px;
    }

    .container-sair {
        display: flex;
        p {
            color: ${(props) => props.theme.colors.white};
        }
    }

    .container-cards {
        display: flex;
        justify-content: center;
    }
`;
