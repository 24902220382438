import styled from "styled-components";

export const Container = styled.div`
    width: 800px;
    padding: 48px 72px 27px 72px;
    position: relative;
    .container-header {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
    }
`;
