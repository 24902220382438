import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { ContractorActionsModel } from "@/domain/models";
import { ListContractorsActions } from "@/domain/useCases/listContractorsActions";

function isContractorActionsResponse(obj: any): obj is { contractors: ContractorActionsModel[] } {
    return typeof obj === "object" && "contractors" in obj && Array.isArray(obj.contractors);
}

export class RemoteListContractorsActions implements ListContractorsActions {
    private data: ContractorActionsModel[] = [];

    constructor(
        private readonly httpGetClient: HttpClient<ContractorActionsModel>,
        private readonly url: string = "contractor/actions"
    ) {}

    async loadAll(): Promise<ContractorActionsModel[]> {

        const response = await this.httpGetClient.request({
            url: this.url,
            method: "get",
        });

        const remoteData = response.body;

        if (isContractorActionsResponse(remoteData)) {
            this.data = remoteData.contractors;
            return this.data;
        }

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return [];
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [];
            default:
                throw new UnexpectedError();
        }
    }

    filter(search?: string): ContractorActionsModel[] {
        if (!search) return this.data;

        return this.data.filter((contractor) =>
            JSON.stringify(contractor)
                .toLowerCase()
                .includes(search.toLowerCase())
        );
    }

    getData(): ContractorActionsModel[] {
        return this.data;
    }
}
