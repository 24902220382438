import styled from "styled-components";

export const Container = styled.div`
    width: 383px;
    p {
        text-transform: none !important;
    }
    .container-select {
        position: relative;
        .container-input {
            &.personalizado {
                position: absolute;
                top: 0px;
                width: 383px;
                right: -401px;
                opacity: 0;
                transition: all 0.5s;
                &.active {
                    opacity: 1;
                    transition: all 0.5s;
                }
            }
        }
    }

    .container-checkBox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .container-input {
        &.transparent {
            position: relative;
            top: 1px;
            img {
                display: block;
                margin-top: 5px;
                margin-right: 10px;
            }
        }
    }
`;
