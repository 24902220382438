import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { ActionCustumizeParams, FindActionModel } from "@/domain/models";

import { ActionCustumize } from "@/domain/useCases";
export type keyOfActionProductsCustomize<T> = keyof T;

export class RemoteActionCustumize implements ActionCustumize {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlActionCustumize: string = "action/customize"
    ) {}

    async actionCustumize(
        params: ActionCustumizeParams
    ): Promise<FindActionModel> {
        const response = await this.httpClient.request({
            method: "post",
            url: `${this.urlActionCustumize}/${params.id}`,
            body: this.formatActionCustumize(params),
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }

    formatActionCustumize(params: ActionCustumizeParams): FormData {
        const formData = new FormData();
        const removeKeys = ["primary_logo", "secondary_logo"];
        const keys = Object.keys(
            params
        ) as keyOfActionProductsCustomize<ActionCustumizeParams>[];

        keys.forEach((key) => {
            if (!removeKeys.includes(key)) {
                formData.append(key, params[key] as string);
            }
        });

        if (params.primary_logo) {
            formData.append("primary_logo", params.primary_logo);
        }

        if (params.secondary_logo) {
            formData.append("secondary_logo", params.secondary_logo);
        }

        return formData;
    }
}
