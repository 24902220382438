import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { DetailActionUserModel } from "@/domain/models";
import { DetailActionUser } from "@/domain/useCases/DetailActionUser";
import { ValidationDate } from "@/services/date";

export class RemoteDetailActionUser implements DetailActionUser {
    protected body: DetailActionUserModel;
    constructor(
        protected readonly httpClient: HttpClient<DetailActionUserModel>,
        protected readonly urlLoadUser: string = "action/users"
    ) {
        this.body = {} as DetailActionUserModel;
    }
    async loadAllUser(id: string): Promise<DetailActionUserModel> {
        const response = await this.httpClient.request({
            method: "get",
            url: this.urlLoadUser + `/${id}`,
        });
        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    this.body = this.formtatData(response.body);
                    return this.body;
                }

                return {} as DetailActionUserModel;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }

    filterLoadUser(value: string): DetailActionUserModel {
        if (!this.body.data?.length) return {} as DetailActionUserModel;

        const update = this.body.data.filter((data) => {
            return JSON.stringify(data, [
                "id",
                "login",
                "name",
                "validator",
                "mobile",
            ])
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase());
        });

        return {
            ...this.body,
            data: update,
        };
    }

    formtatData(data: DetailActionUserModel): DetailActionUserModel {
        if (!data.data?.length) return {} as DetailActionUserModel;

        const update = data.data.map((data) => {
            const first_access =
                new ValidationDate(data.first_access || "").userDateTime() ||
                "-";
            return {
                ...data,
                first_access,
            };
        });

        return {
            ...data,
            data: update,
        };
    }
}
