import { HttpResponse, HttpRequest, HttpClient } from "@/data/protocols/http";
import { StorageLocation } from "@/infra/cache/storageLocation";
import { AxiosInstance, AxiosResponse } from "axios";
import axios, { AxiosError } from 'axios';


import { baseUrl, api } from "./api";

export class AxiosHttpClient<R = unknown> implements HttpClient<R> {
    private baseUrl = baseUrl ? baseUrl : "";
    private token: StorageLocation;
    public axiosInstance: AxiosInstance;
    constructor() {
        this.token = new StorageLocation();
        this.axiosInstance = api;
    }

    async request(data: HttpRequest): Promise<HttpResponse> {
        let axiosResponse: AxiosResponse;

        try {
            axiosResponse = await this.axiosInstance.request({
                url: this.baseUrl + data.url,
                method: data.method,
                data: data.body,
                headers: this.authenticated(data.headers),
                responseType: data?.responseType,
            });

            if (axiosResponse instanceof AxiosError && axiosResponse.response) {
                return {
                    statusCode: axiosResponse.response.status,
                    body: axiosResponse.response,
                };
            }
        } catch (error: any) {
            axiosResponse = error.response;
        }
        return {
            statusCode: axiosResponse?.status,
            body: axiosResponse?.data,
        };
    }

    public authenticated(data: any) {
        const account = this.token.get("access_token");
        if (account?.access_token) {
            data = {
                ...data,
                Authorization: `Bearer ${account.access_token}`,
            };
        }

        return data;
    }
}
