import { useConverter } from "@/main/hooks/useConverter";
import imgCopy from "@/presentation/assets/icone/copy.svg";
import { Container } from "./styles";
import { toast } from "react-toastify";
export function Cryptography() {
    const { state } = useConverter();

    function copy(text: string) {
        const el = document.createElement("textarea");
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        toast.success(`Copiado`);
        return "Texto copiado com sucesso!";
    }

    return (
        <Container>
            <p>Chave criptografada:</p>

            <div className="container-box">
                <span>{state.value_encrypted}</span>
                <button onClick={() => copy(state.value_encrypted)}>
                    <img src={imgCopy} alt="" />
                    Copiar
                </button>
            </div>
        </Container>
    );
}
/**
 * small {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: ${({ theme }) => theme.colors.secondarydark};
    }
 */
