import { useRef, useState } from "react";
import { Text } from "alisson-application";
import { Action, DetailVoucherModel } from "@/domain/models";
import imgIndicador from "@/presentation/assets/img/indicaro-cima.svg";
import { InfoCardAction } from "@/presentation/components/InfoCardAction";
import { ShowDetailAction } from "@/presentation/components/ShowDetailAction";
import { QuantityList } from "@/presentation/components/QuantityList";
import { useAction } from "@/main/hooks/action";
import { Loader } from "@/presentation/components/Loader";
import { Container } from "./styles";
import { Link } from "react-router-dom";
import imgSeta from "@/presentation/assets/img/seta-diagonal.svg";
import { ShowClienAndAction } from "../ShowClienAndAction";
import { DetailVoucher } from "../DetailVoucher";

export function StockList(props: Action) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [calls, setCalls] = useState(0);
    const [vouchers, setVouchers] = useState<DetailVoucherModel>([]);
    const { detailVoucher, activeGroup } = useAction();

    function handleClick(asyncheight?: number) {
        if (ref.current) {
            const el = ref.current as HTMLDivElement;
            const target = el.parentElement;
            const height = el.offsetHeight;

            if (target) {
                const hasActive = target?.classList.value.includes("active");

                if (hasActive) {
                    target.classList.remove("active");
                    target.style.height = `0px`;
                    setActive(false);
                } else {
                    target.classList.add("active");
                    target.style.height = `${
                        asyncheight ? asyncheight : height
                    }px`;
                    setActive(true);
                }
            }
        }
    }

    async function fetchData() {
        if (calls >= 1) return;
        setLoading(true);
        try {
            const response = await detailVoucher.loadAllDetailVoucher(props.id);
            setVouchers(response);
            setCalls(calls + 1);
            setLoading(false);
            handleClick(response.length * 72 + 52 + 56);
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <Container data-testid="container-detalhes">
            <div
                className={`content-detalhes ${activeGroup ? "active" : ""} ${
                    active && activeGroup ? "table" : ""
                }`}
            >
                <div className="detalhes">
                    <div className="content-action">
                        <div className="show-actions">
                            <InfoCardAction {...props} />
                            <ShowClienAndAction
                                contractorName={props.contractor_name}
                                name={props.name}
                            />
                        </div>

                        <ShowDetailAction {...props} />
                    </div>

                    <div className="quantity-list">
                        <Text as="span" mb="13px" color="secondarydark">
                            Vouchers
                        </Text>
                        <QuantityList
                            avaliable={props.vouchers.avaliable}
                            revoked={props.vouchers.revoked}
                            rewarded={props.vouchers.rewarded}
                            total={props.vouchers.total}
                        />
                    </div>
                </div>

                <DetailVoucher currentRef={ref} vouchers={vouchers} />

                <button
                    className={`btn-action-toogle ${active ? "active" : ""}`}
                    data-testid="btn-handleClick"
                    disabled={loading}
                    onClick={async () => {
                        await fetchData();
                        if (calls >= 1) handleClick();
                    }}
                >
                    {loading ? (
                        <Loader height={15} width={15} />
                    ) : (
                        <img src={imgIndicador} alt="" />
                    )}
                </button>

                <div className="header">
                    <Link
                        to={{
                            pathname: `/detalhe/acao/${props.id}`,
                            state: {
                                id: props.id,
                                client: props.contractor_name,
                                avaliable: props.vouchers.avaliable,
                                revoked: props.vouchers.revoked,
                                rewarded: props.vouchers.rewarded,
                                total: props.vouchers.total,
                            },
                        }}
                    >
                        <img src={imgSeta} alt="" />
                        Ir para ação
                    </Link>
                </div>
            </div>
        </Container>
    );
}
