import React, { createContext, useContext, useEffect, useState } from "react";
import { useDetailAction } from "@/main/hooks/detailAction";
import { ActionReportsDownloadModel } from "@/domain/models";
import { Report } from "@/data/useCases/report/report";

interface UseDetailActionReportProps {
    children: React.ReactNode | React.ReactNode[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseStateAction {
    reports: ActionReportsDownloadModel[];
    loading: boolean;
    downloadReport: (reportId: string) => Promise<void>;
}


const Context = createContext<UseStateAction>({} as UseStateAction);

export function UseDetailActionReportProvider(
    props: UseDetailActionReportProps
) {
    const { children } = props;
    const { report, stateHistory } = useDetailAction();
    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState<ActionReportsDownloadModel[]>([]);
    const reportService = new Report();

    async function fetchData() {
        setLoading(true);
        try {
            const response = await report.loadReport(stateHistory.id);
            setReports(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    async function downloadReport(reportId: string): Promise<void> {
        try {
            await reportService.download(reportId);
        } catch (error) {
            console.error("Erro ao baixar o relatório", error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Context.Provider value={{ reports, loading, downloadReport }}>
            {children}
        </Context.Provider>
    );
}

export function useDetailActionReport() {
    const context = useContext(Context);
    return context;
}
