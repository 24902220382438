import imgProcessamento from "@/presentation/assets/icone/processando.svg";
import imgCheck from "@/presentation/assets/icone/check-report.svg";
import imgError from "@/presentation/assets/icone/erro-report.svg";

import { Container } from "./styles";

export type Status = "Processando" | "Concluído" | "Erro";
interface StatusReportProps {
    status: Status;
}

export function StatusReport(props: StatusReportProps) {
    const { status } = props;
    switch (status) {
        case "Processando":
            return (
                <Container>
                    <img src={imgProcessamento} alt="processando" />
                    Processando
                </Container>
            );
        case "Concluído":
            return (
                <Container>
                    <img src={imgCheck} alt="Concluído" />
                    Concluído
                </Container>
            );
        case "Erro":
            return (
                <Container>
                    <img src={imgError} alt="Erro" />
                    Erro
                </Container>
            );

        default:
            return <></>;
    }
}
