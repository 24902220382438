import { Route, Redirect } from "react-router-dom";
import { routesProps } from "@/main/routes/types";
import { useApi } from "@/main/contexts/apiContext";

export default function SimpleRoute(props: routesProps) {
    const { path } = props;
    const { getStorage } = useApi();
    const token = getStorage("access_token");

    if (path === "*") {
        return <Redirect to="/" from={path} />;
    }

    if (token) {
      //  return <Redirect to="/dashboard" from={path} />;
    }

    return <Route {...props} />;
}
