import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { SmsValidateParams, SmsValidateResponse } from "@/domain/models";
import { SmsValidate } from "@/domain/useCases/smsValidate";

export class RemoteSmsValidate implements SmsValidate {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly url: string = "sms/validate"
    ) {}

    async validate(params: SmsValidateParams): Promise<SmsValidateResponse> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.url,
            body: params,
            headers: {
                Authorization: `Bearer ${params.access_token}`,
            },
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;
            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
}
