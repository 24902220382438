import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { ContractorModel } from "@/domain/models";
import { Contractor } from "@/domain/useCases";

export class RemoteContractor implements Contractor {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly url: string = "contractor/all"
    ) {}

    async loadAll(): Promise<ContractorModel> {
        const response = await this.httpClient.request({
            method: "get",
            url: this.url,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
}
