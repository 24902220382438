import React, { Fragment } from "react";

interface WhenProps {
    expr: boolean;
    children: React.ReactElement<any, any>;
}

export function When(props: WhenProps) {
    const { expr, children } = props;
    if (typeof expr === "function" && !expr) return <Fragment />;
    if (!expr) return <Fragment />;

    return children;
}
