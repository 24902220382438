import { Text } from "alisson-application";
import { Container } from "./styles";

interface HeaderProps {
    icone: string;
    title: string;
    subTitle?: string;
    children?: React.ReactNode | React.ReactNode[];
}

export function Header(props: HeaderProps) {
    const { icone, title, subTitle, children } = props;
    return (
        <Container icone={icone}>
            <div className="container-esq">
                <div className="container-img"></div>

                <div className="container-title">
                    <Text ml="11px" as="h1">
                        {title}
                    </Text>
                    <Text ml="11px" as="p" color="secondarydark">
                        {subTitle}
                    </Text>
                </div>
            </div>

            <div className="container-dir">{children}</div>
        </Container>
    );
}
