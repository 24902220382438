import styled from "styled-components";
import iconSair from "@/presentation/assets/icone/icon-sair.svg";

export const Container = styled.div`
    width: 137px;
    height: calc(100vh - 82px);
    padding-top: 35px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 107px;
    position: relative;
    &:after {
        content: "";
        display: block;
        width: 1px;
        height: 100vh;
        background-color: #efefef;
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .container-img {
        margin-bottom: 54px;
    }

    .container-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: auto;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: ${(props) => props.theme.colors.input};
        }

        button {
            position: relative;
            width: 51px;
            margin-top: 11px;
            p {
                text-align: right;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.primary};
                -webkit-mask: url(${iconSair}) no-repeat;
                width: 15px;
                height: 15px;
                z-index: 20;
            }
        }
    }
`;
