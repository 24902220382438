import styled from "styled-components";
import { Container as ShowClienAndAction } from "@/presentation/components/ShowClienAndAction/styles";

export const Container = styled.div`
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    ${ShowClienAndAction} {
        margin-left: 35px;
    }

    .container-descricao {
        width: 500px;
    }

    .containr-cards {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 6px;
        width: 100%;
        margin-bottom: 18px;
    }

    .container-config {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 77px;

        .left {
            width: 518px;
        }
    }

    .item {
        margin-right: 21px;
        span {
            display: flex;
        }
    }
`;
