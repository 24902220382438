import { Modal } from "@/presentation/components/Modal";

import { ModalUploadLoad } from "@/presentation/components/ModalUploadLoad";
import { FormLoadUser } from "@/presentation/components/FormLoadUser";
import { useModal } from "@/main/contexts/modal";
import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";

export function ModalUploadUserLoad() {
    const { state, handleClose } = useModal();
    const { setFile } = useDetailActionUser();

    function closeModal() {
        handleClose("loadUsers");
        setFile(null);
    }

    return (
        <Modal handleClose={closeModal} open={state.loadUsers}>
            <ModalUploadLoad
                title="NOVA CARGA DE USUÁRIO"
                description="PREENCHA OS CAMPO ABAIXO PARA ENVIAR O ARQUIVO."
                handleClose={closeModal}
            >
                <FormLoadUser />
            </ModalUploadLoad>
        </Modal>
    );
}
