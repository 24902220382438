import { Header } from "@/presentation/components/Header";
import { ButtonGoback } from "@/presentation/components/ButtonGoback";
import { SubHeaderNeedHelp } from "@/presentation/components/SubHeaderNeedHelp";
import { TableNeedHelp } from "@/presentation/components/TableNeedHelp";

import { Container } from "./styles";
import imgChat from "@/presentation/assets/icone/chat.svg";

export function NeedHelp() {
    return (
        <Container>
            <Header icone={imgChat} title="PRECISA DE AJUDA?">
                <ButtonGoback />
            </Header>

            <SubHeaderNeedHelp />

            <TableNeedHelp />
        </Container>
    );
}
