import { ErrorMessage, Field, Form, Formik } from "formik";
import { Container as Button } from "@/presentation/components/Button";
import { Container } from "./styles";
import { initialValues, validationLogin } from "./config";

import { useConverter } from "@/main/hooks/useConverter";

export function ConversorForm() {
    const { convert, loading } = useConverter();

    return (
        <Container>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationLogin}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={convert}
            >
                {() => {
                    return (
                        <Form>
                            <div className="container-form">
                                <div className="container-input">
                                    <Field
                                        name="value"
                                        placeholder="Digite o login"
                                    />
                                    <Button isLoading={loading}>
                                        Converter
                                    </Button>
                                </div>
                                <ErrorMessage name="value" component="span" />
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
