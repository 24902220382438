import { Text } from "alisson-application";
import { Action } from "@/domain/models";
import imgCalendario from "@/presentation/assets/img/calendario.svg";
import imgGrupo from "@/presentation/assets/img/user-grupo.svg";
import { Container } from "./styles";

export function ShowDetailAction(props: Action) {
    return (
        <Container>
            <div className="container-item">
                <div className="container-icone">
                    <img src={imgCalendario} alt="calendario" />
                </div>
                <div className="detalhe">
                    <Text color="secondarydark" as="span" mb="8px">
                        Início
                    </Text>
                    <Text as="h1">{props.start_date}</Text>
                </div>
            </div>

            <div className="container-item">
                <div className="container-icone">
                    <img src={imgCalendario} alt="calendario" />
                </div>
                <div className="detalhe">
                    <Text color="secondarydark" as="span" mb="8px">
                        Fim
                    </Text>
                    <Text as="h1">{props.end_date}</Text>
                </div>
            </div>

            <div className="container-item">
                <div className="container-icone">
                    <img src={imgGrupo} alt="calendario" />
                </div>
                <div className="detalhe">
                    <Text color="secondarydark" as="span" mb="8px">
                        Nº de part.
                    </Text>
                    <Text as="h1">{props.quantity_of_users}</Text>
                </div>
            </div>
        </Container>
    );
}
