import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import {
    ActionLoadsPeddingModel,
    ActionLoadsPeddingParams,
} from "@/domain/models";
import { ActionLoadsPeddingList } from "@/domain/useCases";

export class RemoteActionLoadsPeddingList implements ActionLoadsPeddingList {
    constructor(
        protected readonly httpGetClient: HttpClient,
        protected readonly urlLoadPedding: string = "action/loads/pending"
    ) {}

    async loadAllPedding(
        params: ActionLoadsPeddingParams
    ): Promise<ActionLoadsPeddingModel[]> {
        const response = await this.httpGetClient.request({
            url: `${this.urlLoadPedding}/${params.id}`,
            method: "get",
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    return response.body;
                }
                return [] as ActionLoadsPeddingModel[];
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [] as ActionLoadsPeddingModel[];
            default:
                throw new UnexpectedError();
        }
    }
}
