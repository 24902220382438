import { Box, Text } from "alisson-application";
import { Container, EscolhaEnvio } from "./styles";
import { CountDownMemo } from "@/presentation/components/Countdown";
import { useModal } from "@/main/contexts/modal";
import { useState } from "react";
import imgFechar from "@/presentation/assets/icone/close.svg";
import imgValidate from "@/presentation/assets/icone/validae-login.svg";
import imgQuestao from "@/presentation/assets/icone/questao.svg";
import { Container as Button } from "@/presentation/components/Button";
import { useAuthentication } from "@/main/hooks/authentication";
import { FormWhats } from "./formWhats";

export function SmsValidation() {
    const { handleClose } = useModal();
    const [timer, setTimer] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState(true);
    const [value, setValue] = useState<string>("");
    const { smsValidate, sendSmsAgain, stateMe, sendWhatsapp } =
        useAuthentication();
    const phone = stateMe?.phone;

    async function valdiate() {
        setLoading(true);
        const response: any = await smsValidate(value);

        if (response?.valid === false) {
            setError(true);
            setTimer(true);
            setLoading(false);
            return;
        }

        if (response.status !== 200) {
            setError(true);
            setTimer(true);
            setLoading(false);
            return;
        }
    }

    function handleChange() {
        const d = document.getElementById("container-envio");
        if (d?.classList.contains("active")) {
            d?.classList.remove("active");
        } else {
            d?.classList.add("active");
        }
    }

    return (
        <Container>
            <button
                className="close"
                onClick={() => handleClose("smsValidation")}
            >
                <img src={imgFechar} alt="" />
            </button>
            <div className="text-img">
                <Box mb="18px">
                    <img src={imgValidate} alt="" />
                </Box>
                <Text mb="10px" as="h5" fontSize="mdl">
                    VALIDAÇÃO DE LOGIN
                </Text>
                <Text as="p" mb="24px">
                    Sua conta é protegida com autenticação de dois fatores.
                    Enviamos um WhatsApp. Por favor, digite o código abaixo.
                </Text>
                <div className="container-input">
                    <input
                        type="tel"
                        maxLength={4}
                        pattern="\d*"
                        onChange={(e) => {
                            setValue(e.target.value);
                            setError(false);
                        }}
                    />
                </div>

                {error && <Text as="span">Código inválido</Text>}

                {timer ? (
                    <CountDownMemo
                        setDisabled={setDisabled}
                        setTimer={setTimer}
                    />
                ) : null}

                <Box width="100%" mt="16px" mb="24px">
                    <Button
                        className="btn"
                        onClick={valdiate}
                        isLoading={loading}
                    >
                        CONTINUE
                    </Button>
                </Box>
            </div>

            <EscolhaEnvio>
                <div
                    className={`container-btn ${disabled ? "disabled" : ""} `}
                    onClick={handleChange}
                >
                    <button>
                        <div className="container-img">
                            <img src={imgQuestao} alt="" />
                        </div>
                        <div className="container-text">
                            <h3>Clique aqui</h3>
                            <p>se não receber seu código via WhatsApp</p>
                        </div>
                    </button>
                </div>
                <div className="container-envio" id="container-envio">
                    <div className="container-item">
                        <FormWhats
                            setDisabled={setDisabled}
                            setTimer={setTimer}
                            sendWhatsapp={sendWhatsapp}
                            fecha={handleChange}
                            phone={phone}
                        />
                    </div>
                </div>
            </EscolhaEnvio>
        </Container>
    );
}
