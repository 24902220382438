import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: 96px;
    .container-text-img {
        display: flex;
        align-items: center;
        margin-left: 38px;
        img {
            width: 92px;
            height: 60px;
            border-radius: 5px;
            object-fit: cover;
        }

        p {
            text-transform: unset;
        }
    }
`;
