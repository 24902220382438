import { ChangeEvent, useEffect, useState } from "react";
import { Box, Text } from "alisson-application";
import { FieldProps, Field, ErrorMessage } from "formik";
import { DateTime } from "@/presentation/components/DateTime";
import { Url } from "@/services/generateValidUrl/generateValidUrl";
import Select, { options } from "@/presentation/components/Select";
import imgCalendario from "@/presentation/assets/img/calendario.svg";
import { useNewAction } from "@/main/hooks/newAction";
import { When } from "@/presentation/components/when";
import { Loader } from "../Loader";

export function Left(props: FieldProps) {
    const { form } = props;
    const { setFieldValue, values } = form;
    const { contractor } = useNewAction();
    const validUrl = new Url();
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState<options[]>([]);
    useEffect(() => {
        async function fetch() {
            try {
                const response = await contractor.loadAll();
                setOptions(
                    response.contractors.map((item) => ({
                        label: item.name,
                        value: item.id,
                    }))
                );
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }

        if (loading) fetch();
    }, [loading]);

    return (
        <div className="esq">
            <div className="container-select">
                <Select
                    options={options}
                    label="Contratante"
                    disabled={loading}
                    onChange={(values) => {
                        setFieldValue("contractor_id", values.value);
                        setFieldValue("contractor", values.label);
                    }}
                />

                <When expr={loading}>
                    <Loader height={20} width={20} />
                </When>
            </div>
            <ErrorMessage
                name="contractor_id"
                data-testid="error"
                component="span"
            />

            <div className="container-input">
                <Field
                    type="text"
                    placeholder="Job"
                    name="job"
                    data-testid="job"
                />
                <ErrorMessage name="job" data-testid="error" component="span" />
            </div>

            <div className="container-input">
                <Field
                    type="text"
                    placeholder="Nome da ação"
                    name="name"
                    data-testid="name"
                />
                <ErrorMessage
                    name="name"
                    data-testid="error"
                    component="span"
                />
            </div>

            <Box className="container-input" mb="54px !important">
                <Field
                    component="textarea"
                    name="description"
                    placeholder="Descrição da ação"
                    data-testid="description"
                />

                <ErrorMessage
                    name="description"
                    data-testid="error"
                    component="span"
                />
            </Box>

            <div className="container-input chave">
                <Field
                    type="text"
                    placeholder="Chave da ação"
                    name="key"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                            "url_da_acao",
                            `https://api.yetzpromo.com.br/${validUrl.slug(
                                e.target.value
                            )}`
                        );
                        setFieldValue("key", e.target.value);
                    }}
                    value={values.key}
                    data-testid="key"
                />
                <ErrorMessage name="key" data-testid="error" component="span" />
            </div>

            <Box className="container-input link" mb="56px !important">
                <Field
                    type="text"
                    placeholder="URL da ação"
                    name="url_da_acao"
                    value={values.url_da_acao}
                    data-testid="url_da_acao"
                    disabled={true}
                />
                <ErrorMessage
                    name="url_da_acao"
                    data-testid="error"
                    component="span"
                />
            </Box>

            <div className="container-input dt_calendario">
                <Text as="h6">
                    <img src={imgCalendario} alt="" /> Início
                </Text>

                <DateTime
                    initialValue={null}
                    label="Data inicio"
                    onChange={(start_date) => {
                        setFieldValue("start_date", start_date);
                    }}
                />
            </div>

            <div className="container-input dt_calendario">
                <Text as="h6">
                    <img src={imgCalendario} alt="" /> Fim
                </Text>

                <DateTime
                    initialValue={null}
                    label="Data Fim"
                    onChange={(end_date) => {
                        setFieldValue("end_date", end_date);
                    }}
                />
            </div>
        </div>
    );
}
