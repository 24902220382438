import { Text } from "alisson-application";
import { v4 as uuidv4 } from "uuid";
import { Container as Button } from "@/presentation/components/Button";
import { useModal } from "@/main/contexts/modal";
import imgLogo from "@/presentation/assets/icone/logo-acao.png";
import { FieldProps } from "formik";
import { useDetailActionCustumize } from "../DetailContent/hooks/detailActionCustumize";
import { useState } from "react";
import { Url } from "@/services/generateValidUrl/generateValidUrl";

export function Thumbnail(props: FieldProps) {
    const { form } = props;
    const { values } = form;
    const [file, setFile] = useState<File | null>(null);
    const labelId = "file-" + uuidv4();
    const { handleChangeFile } = useDetailActionCustumize();
    const [error, setError] = useState<string | null>(null);

    const { setState, state } = useModal();

    const url = new Url();
    const hasImg = url.isValidURL(values.logo);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const currentFile = e.target.files?.[0];

        if (currentFile) {
            const img = new Image();
            img.src = URL.createObjectURL(currentFile);
            img.onload = () => {
                if (img.width === 1920 && img.height === 1080) {
                    setFile(currentFile);
                    setError(null);
                    handleChangeFile(currentFile, "thumbnail");
                } else {
                    setError(
                        "A imagem deve ter a resolução de 1920x1080 pixels."
                    );
                }
            };
        }
    }

    return (
        <div className="container-item">
            <Text as="h5" mb="16px" pl="16px">
                Capa do vídeo
            </Text>
            <div className="container-thumbnail">
                <div className="thumbnail">
                    <img
                        src={
                            file?.name
                                ? URL.createObjectURL(file)
                                : hasImg
                                ? values.logo
                                : imgLogo
                        }
                        alt=""
                    />
                </div>

                <div className="button-upload">
                    <label htmlFor={labelId} className="container-text">
                        <Button type="button">Upload</Button>
                    </label>

                    <input
                        type="file"
                        name={labelId}
                        id={labelId}
                        accept="image/*"
                        onChange={(event) => {
                            setState({
                                ...state,
                                alert: {
                                    active: true,
                                    onclick: () => handleChange(event),
                                    text: "A imagem será modificada, deseja continuar?",
                                },
                            });
                        }}
                    />
                    {error && (
                        <span style={{ color: "red", margin: "8px" }}>
                            {error}
                        </span>
                    )}
                    <span style={{ margin: "8px" }}>
                        Formato JPG com resolução <br />
                        1920x1080px
                    </span>
                </div>
            </div>
        </div>
    );
}
