import { ActionListVoucher } from "@/presentation/components/ActionListVoucher";
import { DataActionProductsListModel } from "@/domain/models/actionProductsListModel";

import { Pagination } from "@/presentation/components/ActionTable";

type ProductListProps = {
    products: any[];
    page: number;
    totalPages: number;
    onAddProducts: (productChoice: DataActionProductsListModel) => void;
    onPageChange: (page: number) => void;
};

function ProductList({
    products,
    page,
    totalPages,
    onAddProducts,
    onPageChange,
}: ProductListProps) {
    return (
        <div>
            {products.map((voucher) => (
                <ActionListVoucher
                    key={voucher.id}
                    {...voucher}
                    onChange={(productChoice) => {
                        onAddProducts(productChoice);
                    }}
                />
            ))}
            <Pagination
                total={totalPages}
                currentPage={onPageChange}
                page={page}
            />
        </div>
    );
}

export { ProductList };
