import { useState, RefObject, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ptBR } from "@mui/x-date-pickers";
import { ptBR as coreptBr } from "@mui/material/locale";
import ptBRLocale from "date-fns/locale/pt-BR";
import Box from "@mui/material/Box";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

export interface DatePickerProps {
    onChange?: (data: string) => void;
    onAccept?: (data: string) => void;
    initialValue: Date | null;
    clear?: RefObject<HTMLButtonElement>;
    label?: string;
}

export function DateTime(props: DatePickerProps) {
    const { onChange, onAccept, initialValue, label } = props;

    const [value, setValue] = useState<Date | null>(null);

    const theme = createTheme(
        {
            palette: {
                primary: { main: "#6F3E98" },
            },
        },
        ptBR, // x-date-pickers translations
        coreptBr // core translations
    );

    function convertDatePickerTimeToMySQLTime(str: any) {
        // const hours, minutes, seconds;
        const date = new Date(str);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const hours = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        const seconds = ("0" + date.getSeconds()).slice(-2);

        const mySQLDate = [day, month, date.getFullYear()].join("/");
        const mySQLTime = [hours, minutes, seconds].join(":");
        return [mySQLDate, mySQLTime].join(" ").toString();
    }

    useEffect(() => {
        if (initialValue) {
            setValue(new Date(initialValue));
        }
    }, [initialValue]);

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBRLocale}
            >
                <MobileDateTimePicker
                    toolbarTitle="SELECIONE DATA E HORA"
                    defaultCalendarMonth={new Date()}
                    ampm={false}
                    value={value}
                    onAccept={(newValue) => {
                        const date = newValue
                            ? // ? newValue.toLocaleString().replace(/,/g, "")
                              convertDatePickerTimeToMySQLTime(newValue)
                            : "";
                        if (onAccept) {
                            onAccept(date);
                        }
                    }}
                    onOpen={() => {
                        const date = new Date();
                        setValue(date);
                        if (onChange) {
                            // onChange(date.toLocaleString().replace(/,/g, ""));
                            onChange(convertDatePickerTimeToMySQLTime(date));
                        }
                    }}
                    onChange={(newValue) => {
                        const date = newValue
                            ? // ? newValue.toLocaleString().replace(/,/g, "")
                              convertDatePickerTimeToMySQLTime(newValue)
                            : "";

                        if (onChange) {
                            onChange(date);
                        }
                        if (newValue) {
                            setValue(newValue);
                        }
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box className="container-input calendario">
                            <input
                                ref={inputRef}
                                {...inputProps}
                                placeholder={label}
                                data-testid={label}
                            />
                            {InputProps?.endAdornment}
                        </Box>
                    )}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
