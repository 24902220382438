import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { Me } from "@/domain/models";
import { CurrentUser } from "@/domain/useCases";

export class RemoteMe implements CurrentUser {
    constructor(
        private readonly httpClient: HttpClient<Me>,
        private readonly url: string = "me"
    ) {}

    async user(token?: string): Promise<Me> {
        const response = await this.httpClient.request({
            url: this.url,
            method: "get",
            headers: token
                ? {
                      Authorization: `Bearer ${token}`,
                  }
                : {},
        });
        const remoteMe = response.body || ({} as Me);

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return remoteMe;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return {} as Me;
            default:
                throw new UnexpectedError();
        }
    }
}
