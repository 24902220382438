import { Text, Flex } from "alisson-application";
import { useNewAction } from "@/main/hooks/newAction";
import { useHistory } from "react-router-dom";
import { ComplementBlock } from "@/presentation/components/ComplementBlock";
import { Container as Button } from "@/presentation/components/Button";
import imgLink from "@/presentation/assets/icone/link.svg";
import imgRegulamento from "@/presentation/assets/icone/regulamento.svg";
import imgChave from "@/presentation/assets/icone/chave.svg";
import imgCalendario from "@/presentation/assets/img/calendario.svg";
import { Container } from "./styles";
import { useState } from "react";
import { toast } from "react-toastify";
export function FormNewActionReviewAndApproval() {
    const { data, action } = useNewAction();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    async function createAction() {
        setLoading(true);

        try {
            await action.create({
                ...data,
                internal_policy: "1",
            });
            toast.success("Ação criada com sucesso!");
            history.push("/acoes");
            setLoading(false);
        } catch (error: any) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    return (
        <Container>
            <div className="esq">
                <div className="descricao">
                    <ComplementBlock
                        border="none !important"
                        height="50px !important"
                        values={[
                            { label: "Contratante", value: data?.contractor },
                            { label: "job", value: data?.job },
                        ]}
                    />

                    <ComplementBlock
                        border="none !important"
                        height="50px !important"
                        values={[
                            {
                                label: "Nome",
                                value: data?.name,
                            },
                        ]}
                    />

                    <ComplementBlock
                        border="none !important"
                        values={[
                            {
                                label: "Descrição",
                                value: data?.description,
                            },
                        ]}
                    />
                </div>

                <div className="block-config">
                    <Flex justifyContent="space-between">
                        <div className="item">
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                mb="5px"
                            >
                                <img src={imgChave} alt="" />
                                Chave
                            </Text>

                            <Text color="secondarydark" as="p">
                                {data.key}
                            </Text>
                        </div>

                        <div className="item">
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                mb="5px"
                            >
                                <img src={imgLink} alt="" />
                                URL
                            </Text>

                            <Text color="secondarydark" as="p">
                                {data.key}
                            </Text>
                        </div>

                        <div className="item">
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                mb="5px"
                            >
                                <img src={imgRegulamento} alt="" />
                                REGULAMENTO
                            </Text>

                            <Text color="primary" as="p">
                                abrir
                            </Text>
                        </div>
                    </Flex>
                </div>

                <div className="show-date">
                    <Flex>
                        <div className="item">
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                mb="5px"
                            >
                                <img src={imgCalendario} alt="" /> &nbsp;
                                Início:
                                <Text color="secondarydark" as="p">
                                    {data.start_date}
                                </Text>
                            </Text>
                        </div>
                        <div className="item">
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                mb="5px"
                            >
                                <img src={imgCalendario} alt="" />
                                &nbsp;Fim:
                                <Text color="secondarydark" as="p">
                                    {data.end_date}
                                </Text>
                            </Text>
                        </div>
                    </Flex>
                </div>

                <div className="toogle">
                    <Flex>
                        <div className="item">
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                mb="5px"
                            >
                                Exibir texto LGPD:
                                <Text color="secondarydark" as="p">
                                    {data.use_lgpd ? "Sim" : "Não"}
                                </Text>
                            </Text>
                        </div>
                        <div className="item">
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                mb="5px"
                            >
                                Personalizado:
                                <Text color="secondarydark" as="p">
                                    {data.use_lgpd ? "Sim" : "Não"}
                                </Text>
                            </Text>
                        </div>
                    </Flex>
                </div>
            </div>

            <div className="dir">
                <div className="descricao">
                    <ComplementBlock
                        borderTop="unset !important"
                        values={[
                            {
                                label: "Tipo de login",
                                value: data?.login_type,
                            },
                            {
                                label: "Tipo de login",
                                value: data?.login_type_cpf_truncated
                                    ? "Sim"
                                    : "Não",
                            },
                        ]}
                    />
                </div>

                <div className="descricao">
                    <ComplementBlock
                        values={[
                            {
                                label: "Validador",
                                value: data.validator_type
                                    ? "Data de nascimento"
                                    : "Não",
                            },

                            {
                                label: "Gerar participantes",
                                value: data.generate_users_quantity
                                    ? "Sim"
                                    : "Não",
                            },
                        ]}
                    />
                </div>

                <div className="descricao">
                    <ComplementBlock
                        values={[
                            {
                                label: "Quantidade de participantes",
                                value: data?.generate_users,
                            },
                            {
                                label: "Campos personalizados",
                                value: data?.extra_fields?.join(", "),
                            },
                        ]}
                    />
                </div>

                <Button
                    data-testid="button"
                    type="submit"
                    mt="66px"
                    disabled={loading}
                    isLoading={loading}
                    spiner="BallTriangle"
                    spinerColor="primary"
                    onClick={createAction}
                >
                    Avançar
                </Button>
            </div>
        </Container>
    );
}
