import { Box } from "alisson-application";
import { useModal } from "@/main/contexts/modal";
import { Container as Button } from "@/presentation/components/Button";
import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";

interface ButtonResetUserProps {
    userId: number;
    actionId: number;
}

export function ButtonResetUser(props: ButtonResetUserProps) {
    const { resetUser, fetchData } = useDetailActionUser();

    const { setState, state } = useModal();

    return (
        <Box>
            <Button
                onClick={async () => {
                    setState({
                        ...state,
                        alert: {
                            active: true,
                            onclick: async () => {
                                await resetUser(props.actionId, props.userId);
                                await fetchData();
                            },
                        },
                    });
                }}
            >
                Resetar
            </Button>
        </Box>
    );
}
