import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 280px 1fr 1fr;
    align-items: center;
    padding: 0px 20px;
    align-items: center;
    width: 651px;
    height: 93px;
    border: 1px solid #5e5f5f;
    border-radius: 5px;
    margin-bottom: 10px;
    .container-img-item {
        display: flex;
        align-items: center;
        .container-img {
            img {
                width: 94px;
                height: 62px;
                object-fit: cover;
                border-radius: 5px;
            }
        }
    }

    .container-item {
        margin-left: 10px;
    }

    .container-qtd-and {
        display: flex;
        align-items: center;
    }

    .container-qtd-estoque {
    }

    .container-qtd {
        display: flex;
        align-items: center;
        height: 61px;
        margin-left: auto;
        p {
            background: ${(props) => props.theme.colors.tertiary};
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            height: 100%;
            width: 79px;

            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;

            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
        }

        input {
            width: 83px;
            height: 100%;
            padding: 20px;
            text-align: center;
            border: none;
            border: 1px solid ${(props) => props.theme.colors.tertiary};
            border-radius: 0px 5px 5px 0px;
        }
    }
`;
