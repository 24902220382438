import { Switch } from "react-router-dom";
import { routes } from "@/main/routes";
import RouteAdm from "@/presentation/components/Route/adm";
import RouteDefault from "@/presentation/components/Route/user";

export function SwitchRoutes() {
    return (
        <Switch>
            {routes.map((props, index) => {
                // eslint-disable-next-line react/prop-types
                const { isPrivate } = props;
                return isPrivate ? (
                    <RouteAdm {...props} key={index} />
                ) : (
                    <RouteDefault {...props} key={index} />
                );
            })}
        </Switch>
    );
}
