import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    min-height: 155px;
    padding: 0px 61px 0px 43px;
    border: 1px solid #5e5f5f;
    background: #f0f0f0;
    border-radius: 5px;
    margin-bottom: 10px;
    .container-detail-group {
        margin-top: 17px;
        .container-img {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            img {
                width: 30px;
                height: 27px;
                display: block;
                margin-right: 9px;
            }
        }
    }

    .container-vouchers {
        margin-top: 16px;
        .container-voucher {
            p {
                text-transform: unset;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0px;
                }
                img {
                    display: block;
                    margin-right: 11px;
                }
            }
        }
        .saiba-mais {
            width: 350px;
            margin-top: 20px;
            .container-saiba-mais {
                height: 0;
                overflow: hidden;
                transition: all 0.5s ease 0s;
                &.active {
                    height: auto;
                    transition: all 0.5s ease 0s;
                }
            }
            .link-saiba-mais {
                font-style: normal;
                font-size: 0.875rem;
                color: rgb(111, 62, 152);
                line-height: 100%;
                font-family: Lato;
                font-weight: 700;
                margin-bottom: 10px;
                cursor: pointer;
                img {
                    margin-left: 10px;
                    transform: rotateX(0deg);
                    transition: all 0.5s;
                }
                &.active {
                    img {
                        transform: rotateX(180deg);
                        transition: all 0.5s;
                    }
                }
            }
        }
    }

    .container-img-group {
        margin-top: 16px;
        display: flex;
        align-items: flex-center;
        justify-content: space-between;
        .group {
            display: flex;
            flex-direction: column;
            .container-img {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 11px;
                margin-bottom: 8px;
                img {
                    border-radius: 5px;
                    width: 114px;
                    height: 114px;
                    object-fit: cover;
                    object-fit: contain;
                }
            }
        }
        button {
            padding: 16px;
            cursor: pointer;
            margin: 8px;
            transition: background-color 0.3s, color 0.3s;
            margin-bottom: 36px;
            margin-top: 24px;
            border-radius: 20px;
        }
        button:hover {
            background-color: #ebe2f2;
            color: #ffffff;
            border-radius: 20px;
        }
    }
`;
