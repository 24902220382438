export class Money {
    public convertToBRL(value: string): string {
        const valorFloat = parseFloat(value);
        const valorBRL = valorFloat
            .toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
                minimumFractionDigits: 2,
            })
            .replace(/\s/g, " ");

        return valorBRL;
    }
}
