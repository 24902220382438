import * as Yup from "yup";

export interface FormNewActionDataAccessFormik {
    login_type: string;
    login_type_cpf_truncated: number;
    validator_type: string | number;
    extra_fields: string[];
    send_whatsapp: number;
    book_stock: string;
    generate_users_quantity: number;
    generate_users: number;
    validator_label: string;
    login_label: string;
    use_validator: string | number;
}

export const initialValues: FormNewActionDataAccessFormik = {
    login_type: "",
    login_type_cpf_truncated: 0,
    validator_type: " ",
    extra_fields: [""],
    send_whatsapp: 0,
    book_stock: "",
    generate_users_quantity: 0,
    generate_users: 0,
    validator_label: " ",
    login_label: "",
    use_validator: 0,
};

export const validation = Yup.object().shape({
    login_type: Yup.string().required("Campo obrigatório"),
    login_type_cpf_truncated: Yup.number(),
    validator_type: Yup.string(),
    extra_fields: Yup.array().of(Yup.string()),
    send_whatsapp: Yup.number(),
    book_stock: Yup.string(),
    generate_users_quantity: Yup.number(),
    generate_users: Yup.number(),
    use_validator: Yup.string().test(
        "obrigatorio-quando-ativo",
        "campo obrigatório",
        (val: any, props) => {
            if (props.parent.validator_type === "BIRTH_DATE") return true;
            if (props.parent.validator_type === "Personalizado") return true;
            if (val === 0) return true;
            if (val === 1) return false;
            if (props.parent.validator_type === undefined) return true;
            return true;
        }
    ),
    validator_label: Yup.string().when("validator_type", {
        is: (val: string) => val === "Personalizado",
        then: Yup.string().required("Campo obrigatório"),
        otherwise: Yup.string(),
    }),
    login_label: Yup.string().when("login_type", {
        is: (val: string) => (val ? true : false),
        then: Yup.string().required("Campo obrigatório"),
        otherwise: Yup.string(),
    }),
});
