import { SimpleChips } from "@/presentation/components/SimpleChips";
import imgBanner from "@/presentation/assets/img/banner-exp.png";
import { Container } from "./styles";

interface InfoCardActionProps {
    status: number;
    logo: string;
    book_stock: number;
    job: string;
    background: string;
    blocked: number | null;
}

export function InfoCardAction(props: InfoCardActionProps) {
    return (
        <Container background={props.background}>
            <div className="container-img">
                <SimpleChips variant={props.blocked ? "failure" : "success"}>
                    {props.blocked ? "Inativo" : "Ativo"}
                </SimpleChips>
                <img src={props.logo ? props.logo : imgBanner} alt="" />
            </div>

            <div className="container-chips">
                <SimpleChips variant="secondarydark">{props.job}</SimpleChips>
                <SimpleChips variant="warning">
                    {props.book_stock ? "Com estoque" : "Sob. Demanda"}
                </SimpleChips>
            </div>
        </Container>
    );
}
