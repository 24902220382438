import { Converter } from "@/data/composition";
import { UseConverterProvider } from "@/main/hooks/useConverter";
import { Conversor } from "@/presentation/components/Conversor";

export function PageConversor() {
    const converter = new Converter();
    return (
        <UseConverterProvider converter={converter}>
            <Conversor />
        </UseConverterProvider>
    );
}
