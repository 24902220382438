import { useEffect, useState } from "react";
import { Text, Flex } from "alisson-application";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";
import { useDetailAction } from "@/main/hooks/detailAction";
import { Container as Button } from "@/presentation/components/Button";
import { ActionItemQuantityVoucher } from "@/presentation/components/ActionItemQuantityVoucher";
import { useModal } from "@/main/contexts/modal";
import { toast } from "react-toastify";
import imgCard from "@/presentation/assets/icone/card-plus-roxo.svg";
import imgClose from "@/presentation/assets/icone/close.svg";
import { Container } from "./styles";

interface SendProducts {
    product_id: number;
    variation_id: number;
    quantity: number;
}

export function ActionChoiceQuantityVoucher() {
    const { handleClose } = useModal();
    const { stateHistory } = useDetailAction();
    const { productsChoice, addVoucher, setProductsChoice } =
        useDetailActionVoucher();
    const [sendProducts, setSendProducts] = useState<SendProducts[]>([]);
    const [loading, setLoading] = useState(false);

    function onchange(quantity: number, id: number) {
        const update = sendProducts.map((product) => {
            if (product.variation_id === id) {
                return {
                    ...product,
                    quantity: quantity,
                };
            }
            return product;
        });

        setSendProducts(update);
    }

    async function handleAdd() {
        setLoading(true);

        try {
            const response = await addVoucher({
                action_id: stateHistory.id,
                products: [...sendProducts],
            });

            toast.success(response.message);
            handleClose("selectVouchersQtd");
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    function handleCloseModal() {
        setProductsChoice([]);
        handleClose("selectVouchersQtd");
    }

    useEffect(() => {
        const update = productsChoice.map((product) => {
            return {
                product_id: product.produto_id,
                variation_id: product.id,
                quantity: 0,
            };
        });

        setSendProducts(update);
    }, [productsChoice]);

    return (
        <Container>
            <Flex alignItems="center" mb="26px">
                <img src={imgCard} alt="" />
                <Text ml="10px" as="h5">
                    SELECIONE A QUANTIDADE DE VOUCHERS
                </Text>
            </Flex>

            {productsChoice.map((productChoice) => (
                <ActionItemQuantityVoucher
                    key={productChoice.id}
                    {...productChoice}
                    onchange={onchange}
                />
            ))}

            <div className="container-btn">
                <Button
                    onClick={handleAdd}
                    isLoading={loading}
                    spinerColor="primary"
                    mb="8px"
                >
                    Avançar
                </Button>
                <button onClick={handleCloseModal}>Cancelar</button>
            </div>

            <div className="container-close">
                <button onClick={handleCloseModal}>
                    <img src={imgClose} alt="" />
                </button>
            </div>
        </Container>
    );
}
