import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { FormActionUserLoadModel } from "@/domain/models";
import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";
import { Container as Button } from "@/presentation/components/Button";
import { initialValues, validation } from "./config";
import { Container } from "./styles";

export function FormLoadUser() {
    const { file, onSubmitUserLoad } = useDetailActionUser();

    return (
        <Container>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={onSubmitUserLoad}
            >
                {(props: FormikProps<FormActionUserLoadModel>) => {
                    return (
                        <Form data-testid="form-login">
                            <div className="container-input">
                                <input
                                    type="text"
                                    disabled={true}
                                    value={
                                        file?.name
                                            ? file.name
                                            : "Arquivo Não Encontrado"
                                    }
                                />
                            </div>
                            <div className="container-input">
                                <Field
                                    type="text"
                                    placeholder="Identificador"
                                    name="identifier"
                                    data-testid="identifier"
                                />
                            </div>
                            <ErrorMessage
                                name="identifier"
                                data-testid="error"
                                component="span"
                            />

                            <Button
                                data-testid="button"
                                type="submit"
                                mb="30px"
                                disabled={!props.isValid}
                                isLoading={props.isSubmitting}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                Enviar
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
