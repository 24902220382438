import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ImgVoucher } from "@/presentation/components/StockList/imgVoucher";
import Table from "@/presentation/components/Table";
import { DetailVoucher as DetailVoucherModel } from "@/domain/models";
import { Data } from "./config";
import { Container } from "./styles";

interface DetailVoucherProps {
    vouchers: DetailVoucherModel[];
    currentRef: React.RefObject<HTMLDivElement>;
    book_stock?: number;
}

export function DetailVoucher(props: DetailVoucherProps) {
    const { vouchers, currentRef, book_stock } = props;

    const dados: Data[] = vouchers.map((item, key) => {
        return {
            id: key + 1,
            ...item,
        };
    });

    const columns: GridColumns = [
        {
            field: "product_name",
            headerName: "Nome",
            width: 400,
            renderCell: ({ row }: GridRenderCellParams) => {
                return <ImgVoucher img={row.img} title={row.product_name} />;
            },
        },
        {
            field: "qtd_total",
            headerName:
                book_stock === 1
                    ? "Vouchers disponíveis"
                    : "Vouchers resgatados",
            width: 150,
        },
        {
            field: "qtd_assigned",
            headerName: "Qtd. de resgates",
            width: 150,
        },
        {
            field: "qtd_saldo",
            headerName: "Qtd. disponíveis",
            width: 150,
            hide: dados.some((row) => row.disable_column_balance),
        },
        {
            field: "qtd_estornado",
            headerName: "Qtd. estornados",
            width: 150,
        },
    ];

    return (
        <Container className="content-table">
            <div className="container-table" ref={currentRef}>
                <Table
                    rows={dados}
                    columns={columns}
                    nameCSV="csvName"
                    header={false}
                />
            </div>
        </Container>
    );
}
