import { useState } from "react";
import { Flex, Text } from "alisson-application";
import imgDownload from "@/presentation/assets/icone/baixa-report.svg";
import { useDetailActionReport } from "@/presentation/components/DetailContent/hooks/detailActionReport";

interface BtnDowloadProps {
    reportId: string;
    viewed: number;
}

export function BtnDowload({ reportId, viewed: initialViewed }: BtnDowloadProps) {
    const { downloadReport } = useDetailActionReport();
    const [viewed, setViewed] = useState(initialViewed);

    const handleDownload = async () => {
        if (viewed === 0) {
            await downloadReport(reportId);
            setViewed(1);
        }
    };

    return (
        <button
            onClick={handleDownload}
            disabled={viewed === 1}
            style={{
                cursor: viewed === 1 ? 'not-allowed' : 'pointer',
            }}
        >
            <Flex>
                {viewed === 0 ? (
                    <>
                        <img src={imgDownload} alt="Baixar" />
                        <Text as="h6" ml="9px">Baixar</Text>
                    </>
                ) : (
                    <Text as="h6">Já baixado</Text>
                )}
            </Flex>
        </button>
    );
}
