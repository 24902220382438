import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { themeColors } from "@/presentation/styles/theme";
import { Theme } from "alisson-application";

interface SCThemeProviderProps {
    children: React.ReactNode | React.ReactNode[];
}
interface ThemeProviderProps {
    currentTheme: Theme;
    lightTheme: () => void;
    darkTheme: () => void;
}

const ThemeContext = React.createContext<ThemeProviderProps>(
    {} as ThemeProviderProps
);

const ThemeContextProvider = ({ children }: SCThemeProviderProps) => {
    const [currentTheme, setCurrentTheme] = useState<Theme>({} as Theme);
    function lightTheme() {
        setCurrentTheme(themeColors);
    }

    function darkTheme() {
        setCurrentTheme(themeColors);
    }

    useEffect(() => {
        setCurrentTheme(themeColors);
    }, []);

    return (
        <ThemeContext.Provider value={{ lightTheme, darkTheme, currentTheme }}>
            <SCThemeProvider theme={themeColors}>{children}</SCThemeProvider>
        </ThemeContext.Provider>
    );
};

export function useTheme() {
    const context = useContext(ThemeContext);
    return context;
}

export { ThemeContext, ThemeContextProvider };
