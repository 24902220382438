import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { AcessibleRoutes } from "@/domain/models";
import { Permissions } from "@/domain/useCases";

type ResponseBody = {
    [key: string]: {
		uri: string,
		name: string,
		method: string
    }
}

export class RemotePermissions implements Permissions {
    constructor(
        private readonly httpClient: HttpClient<ResponseBody>,
        private readonly url: string = "users/accessible-routes"
    ) {}

    async loadAcessibleRoutes(token: string): Promise<AcessibleRoutes[]> {
        const response = await this.httpClient.request({
            url: this.url,
            method: "get",
            headers: { Authorization: `Bearer ${token}`}
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    return this.mapResponseBodyToModel(response.body)
                } else  { 
                    throw new AccessDeniedError();
                }
            case (HttpStatusCode.unauthorized || HttpStatusCode.forbidden):
                throw new AccessDeniedError();
            default:
                throw new UnexpectedError();
        }
    }

    private mapResponseBodyToModel(body: ResponseBody): AcessibleRoutes[] {
        return Object.entries(body).map(([_, { uri, method }]) => ({ path: uri, methods: method.split(",")}))
    }
}
