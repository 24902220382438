import { FormActionUserLoadModel } from "@/domain/models";
import * as Yup from "yup";

export const initialValues: FormActionUserLoadModel = {
    identifier: "",
};

export const validation = Yup.object().shape({
    identifier: Yup.string().required("Campo obrigatório"),
});
