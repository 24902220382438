import styled from "styled-components";

export const Container = styled.div`
    width: 189px;
    height: 261px;
    border-radius: 5px;
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 10px 38px;

    &.active {
        .container-img {
            margin-bottom: 19px;
        }
    }
    .container-img {
        margin-bottom: 67px;
        width: 94px;
        height: 62px;
        margin-bottom: 67px;
        img {
            border-radius: 5px;
            width: 100%;
            object-fit: cover;
        }
    }

    .container-field {
        margin-bottom: 16px;
    }

    .container-text {
        text-align: center;
        p {
            text-transform: unset;
        }

        h5 {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
`;

export const ContainerDimmed = styled(Container)`

    background-color: #f0f0f0;
    opacity: 0.6;
    .container-img {
    background-color: #f0f0f0;
    opacity: 0.5;
    }
`;
