import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    img {
        display: block;
        margin-right: 14px;
    }
`;
