import { useState } from "react";
import { Text } from "alisson-application";
import { CheckBox } from "@/presentation/components/CheckBox";
import { Container } from "./styles";
import { DataActionProductsListModel } from "@/domain/models/actionProductsListModel";

interface ListVoucherProps extends DataActionProductsListModel {
    onChange: (e: DataActionProductsListModel) => void;
}

export function ActionListVoucher(props: ListVoucherProps) {
    const { onChange, ...rest } = props;
    const { foto_capa, nome, total_disponivel, valor_reais, id } = rest;
    const [active, setActive] = useState(false);

    return (
        <Container className={`container-voucher ${active ? "active" : ""} `}>
            <div className="container-check">
                <CheckBox
                    onChange={(e) => {
                        onChange(rest);
                        setActive(e.target.checked);
                    }}
                />
            </div>

            <div className="container-text-img">
                <img src={foto_capa} alt="" />

                <Text as="p" fontSize="xs" color="secondarydark" ml="16px">
                    {id}
                </Text>

                <Text as="p" fontSize="xs" color="secondarydark" ml="26px">
                    {nome}
                </Text>
            </div>

            <div className="container-qtd-and">
                <Text as="p" fontSize="xs" color="secondarydark">
                    {valor_reais}
                </Text>
                <Text as="p" fontSize="xs" color="secondarydark">
                    Estoque: {total_disponivel}
                </Text>
            </div>
        </Container>
    );
}
