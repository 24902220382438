import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { ActionApproveAndRepproveParams } from "@/domain/models";
import { ActionApproveAndRepprove } from "@/domain/useCases";

export class RemoteActionApproveAndRepprove
    implements ActionApproveAndRepprove
{
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlAproveAndReprova: string = "action/loads/approve"
    ) {}
    async approve(params: ActionApproveAndRepproveParams): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlAproveAndReprova,
            body: params,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
    async repprove(params: ActionApproveAndRepproveParams): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlAproveAndReprova,
            body: params,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
}
