import React, { useState, useEffect } from "react";
import { Text, Flex } from "alisson-application";
import { useModal } from "@/main/contexts/modal";
import { Container } from "./styles";
import sortingIcon from "@/presentation/assets/icone/sortingIcon.svg";
import defaultImageVoucher from "@/presentation/assets/img/defaultImageVoucher.png";
import imgClose from "@/presentation/assets/icone/close.svg";
import { SortableContainer, SortableElement, SortEnd, arrayMove } from "react-sortable-hoc";
import { Container as Button } from "@/presentation/components/Button";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";
import { MetricsProducts as APIMetricsProducts } from "@/domain/models/detailActionMetricsModel";
import { toast } from "react-toastify";

interface SortingVouchersProps {
    actionId: number;
}

interface Product {
    id: string;
    name: string;
    imageUrl?: string;
    order: number;
    deleted_at: string | null;
}

interface Group {
    id: string;
    name: string;
    imageUrl: string;
    order: number;
    isGroup: boolean;
    products: Product[];
}

const createProductImageMap = (products: Product[]): Record<string, string> => {
    return products.reduce((acc, product) => {
        acc[product.id] = product.imageUrl || defaultImageVoucher;
        return acc;
    }, {} as Record<string, string>);
};


const getProductsInGroups = (groups: Group[]): string[] => {
    return groups.reduce((acc: string[], group) => {
        return acc.concat(group.products.map(product => product.id));
    }, []);
};


const mapMetricsProductToProduct = (metricsProduct: APIMetricsProducts): Product => {
    return {
        id: metricsProduct.id.toString(),
        name: metricsProduct.original_name,
        imageUrl: metricsProduct.img_store || defaultImageVoucher,
        order: metricsProduct.order ?? 0,
        deleted_at: metricsProduct.deleted_at,
    };
};

const mapApiGroupToGroup = (group: any, productImageMap: Record<string, string>): Group => {
    const products = (group.products || [])
        .filter((product: any) => !product.deleted_at)
        .map((product: any) => ({
            id: product.product_configuration_id.toString(),
            name: product.name,
            imageUrl: productImageMap[product.product_configuration_id.toString()] || defaultImageVoucher,
            order: product.order,
            deleted_at: product.deleted_at,
        }))
        .sort((a: Product, b: Product) => a.order - b.order);

    return {
        id: group.id.toString(),
        name: group.name,
        imageUrl: group.image || defaultImageVoucher,
        order: group.order ?? 0,
        isGroup: true,
        products
    };
};


const SortableProductItem = SortableElement<{ product: Product, productIndex: number }>(({ product, productIndex }: { product: Product, productIndex: number }) => (
    <div className={`sortable-item ${product.deleted_at ? 'opacity' : ''}`} 
    style={{ 
        padding: '2px', 
        margin: '8px 0px 8px 8px', 
        background: '#f0f0f0', 
        borderRadius: '4px', 
        display: 'flex',
        border: '1px solid #ddd',
        alignItems: 'center', 
        zIndex: 999999999999,
    }}>
        {/* <Text as="p">&nbsp;&nbsp;&nbsp;{productIndex + 1}º&nbsp;</Text> */}
        <img style={{ 
            width: '50px', 
            marginRight: "10px",
        }} src={product.imageUrl || defaultImageVoucher} alt={product.name} className="item-image" />
        <Text as="p">{product.name}</Text>
    </div>
));


const SortableProductList = SortableContainer<{ products: Product[], onSortEnd: (params: SortEnd) => void }>(({ products, onSortEnd }: { products: Product[], onSortEnd: (params: SortEnd) => void }) => {
    return (
        <div >
            {products.map((product, index) => (
                <SortableProductItem key={product.id} index={index} product={product} productIndex={index} />
            ))}
        </div>
    );
});


const SortableItem = SortableElement<{ item: Product | Group, onProductSortEnd: (groupIndex: number, params: SortEnd) => void, groupIndex?: number }>(({ item, onProductSortEnd, groupIndex }: { item: Product | Group, onProductSortEnd: (groupIndex: number, params: SortEnd) => void, groupIndex?: number }) => (
    <div className={`sortable-item ${'isGroup' in item && item.isGroup ? 'group-item' : ''} ${'deleted_at' in item && item.deleted_at ? 'opacity' : ''}`} 
    style={{ 
         zIndex: 999999999999,
         padding: '2px 16px 2px 4px',
         margin: '5px 0',
         background: '#f0f0f0',
         color: 'isGroup' in item && item.isGroup ? '#fff' : '#000',
         border: '1px solid #ddd',
         borderRadius: '4px',
         cursor: 'grab',
         userSelect: 'none',
         display: 'flex',
         flexDirection: 'column',
         }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <Text as="p">&nbsp;&nbsp;&nbsp;{item.order}º&nbsp;</Text> */}
            <img style={{ 
                width: '50px', 
                marginRight: "10px",
            }} src={item.imageUrl || defaultImageVoucher} alt={item.name} className="item-image" />
            <Text as="p">{item.name}</Text>
            {'isGroup' in item && item.isGroup && <Text as="p" style={{ marginLeft: 'auto', fontWeight: 'bold' }}>Grupo&nbsp;&nbsp;</Text>}
            {'deleted_at' in item && item.deleted_at && (
                <Text as="p" style={{ marginLeft: '8px', fontStyle: 'italic', fontSize: '12px', textTransform: 'lowercase' }}>
                    (removido dia {item.deleted_at})
                </Text>
            )}
        </div>
        {'isGroup' in item && item.isGroup && (
            <SortableProductList 
                products={item.products} 
                onSortEnd={(params) => {
                    onProductSortEnd(groupIndex!, params);
                }} 
            />
        )}
    </div>
));

const SortableList = SortableContainer<{ items: (Product | Group)[], onProductSortEnd: (groupIndex: number, params: SortEnd) => void }>(({ items, onProductSortEnd }: { items: (Product | Group)[], onProductSortEnd: (groupIndex: number, params: SortEnd) => void }) => {
    return (
        <div>
            {items.map((item: Product | Group, index: number) => (
                <SortableItem key={item.id} index={index} item={item} groupIndex={index} onProductSortEnd={onProductSortEnd} />
            ))}
        </div>
    );
});

export function SortingVouchers({ actionId }: SortingVouchersProps) {
    const { dataMetrics, groups, reorderVouchers } = useDetailActionVoucher();
    const { handleClose } = useModal();
    const [items, setItems] = useState<(Product | Group)[]>([]);

    console.log("metricsProduct",dataMetrics);

    useEffect(() => {
        if (dataMetrics?.products || groups) {
            const productsWithOrder = (dataMetrics?.products || []).map(product => ({
                ...product,
                order: (product as APIMetricsProducts).order ?? 0
            })) as APIMetricsProducts[];
    
            const sortedProducts = productsWithOrder.map(mapMetricsProductToProduct);
    
            const productImageMap = createProductImageMap(sortedProducts);
    
            const sortedGroups = (groups || []).map(group => mapApiGroupToGroup(group, productImageMap));
    
            const productsInGroups = getProductsInGroups(sortedGroups);
    
            // Excluir produtos excluídos da visualização
            const filteredProducts = sortedProducts.filter(product => !productsInGroups.includes(product.id) && !product.deleted_at);
    
            const combinedItems = [...filteredProducts, ...sortedGroups];
    
            combinedItems.sort((a, b) => a.order - b.order);
    
            setItems(combinedItems);
        }
    }, [dataMetrics, groups]);
    
    
    
    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        setItems(arrayMove(items, oldIndex, newIndex));
        document.querySelectorAll('.sortable-item').forEach(item => item.classList.remove('dragging'));
    };

    const onProductSortEnd = (groupIndex: number, { oldIndex, newIndex }: SortEnd) => {
        const newItems = [...items];
        const group = newItems[groupIndex] as Group;
        group.products = arrayMove(group.products, oldIndex, newIndex);
        setItems(newItems);
    };

    const onSortStart = () => {
        document.querySelectorAll('.sortable-item').forEach(item => item.classList.add('grabbed'));
    };

    const onSortMove = () => {
        document.querySelectorAll('.sortable-item').forEach(item => item.classList.add('dragging'));
    };

    const handleSave = async () => {
        console.log('Posições dos itens:');
        items.forEach((item, index) => {
            console.log(`Posição ${index + 1}: ${item.name} (ID: ${item.id})`);
            if ('isGroup' in item && item.isGroup) {
                item.products.forEach((product, productIndex) => {
                    console.log(`  Posição ${productIndex + 1} no grupo: ${product.name} (ID: ${product.id})`);
                });
            }
        });
    
        let currentOrder = 1;
        const groupProductOrder: Record<string, { is_group: boolean, order: number }> = {};
    
        const orderedItems = items.reduce((acc, item) => {
            if ('isGroup' in item && item.isGroup) {
                acc.push({
                    id: item.id,
                    is_group: true,
                    order: currentOrder++
                });
                item.products.forEach((product, productIndex) => {
                    groupProductOrder[product.id] = { is_group: false, order: productIndex + 1 };
                });
            } else {
                acc.push({
                    id: item.id,
                    is_group: false,
                    order: currentOrder++
                });
            }
            return acc;
        }, [] as Array<{ id: string; is_group: boolean; order: number }>);
    
        const excludedProducts = (dataMetrics?.products || [])
            .filter(product => product.deleted_at)
            .map(mapMetricsProductToProduct)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(product => ({
                id: product.id,
                is_group: false,
                order: currentOrder++
            }));
    
        const jsonOutput = {
            action_id: actionId,
            products: orderedItems.concat(excludedProducts).reduce((acc, item) => {
                acc[item.id] = {
                    is_group: item.is_group,
                    order: item.order
                };
                return acc;
            }, { ...groupProductOrder } as Record<string, { is_group: boolean; order: number }>)
        };
    
        console.log(JSON.stringify(jsonOutput, null, 2));
    
        try {
            await reorderVouchers(jsonOutput);
        } catch (error) {
            toast.error("Erro ao reordenar vouchers.");
        }
    };
    
    return (
        <Container>
            <Flex alignItems="center" mb="26px">
                <img src={sortingIcon} alt="" />
                <Text ml="10px" as="h5">
                    REORDENAR VOUCHERS
                </Text>
            </Flex>

            <div className="scroll-container">
            <SortableList items={items} onSortEnd={onSortEnd} onSortStart={onSortStart} onSortMove={onSortMove} onProductSortEnd={onProductSortEnd} />
            </div>

            <div className="container-close">
                <button onClick={() => handleClose("sortingVouchers")}>
                    <img src={imgClose} alt="" />
                </button>
            </div>
            <div style={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                <div className="custom-button" style={{ width: "180px" }}>
                    <Button mt="20px" onClick={handleSave}>Salvar</Button>
                </div>
            </div>
        </Container>
    );
}
