import axios from "axios";
export const baseUrl = process.env.REACT_APP_BASE_HTTP;
export const api = axios.create();

/**
 * export function createAxiosInstance(): AxiosInstance {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      throw new ApiError(error);
    }
  );

  return axiosInstance;
}
 */
