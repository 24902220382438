import styled from "styled-components";

export const Container = styled.div`
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.secondarydark};
        height: 40px;
        display: flex;
        align-items: center;
    }

    .container-box {
        background-color: ${({ theme }) => theme.colors.secondary};
        height: 180px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        position: relative;
        button {
            position: absolute;
            bottom: 16px;
            right: 24px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
            img {
                margin-right: 8px;
            }
        }
        span {
            background-color: ${({ theme }) => theme.colors.white};
            height: 81px;
            display: flex;
            align-items: center;
            font-size: 14px;
            border-radius: 10px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: ${({ theme }) => theme.colors.secondarydark};
            overflow-x: auto;
            max-width: 90%;
            padding: 0px 16px;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar:horizontal {
                width: 5px;
            }

            &::-webkit-scrollbar:horizontal {
                height: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(89, 92, 118, 0.5);
            }

            &::-webkit-scrollbar-track {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
`;
