import { AntDesignStyledGridToolbar } from "./styles";

export function Toolbar() {
    const pathName = window.location.pathname.replace(/\//g, ".");

    return (
        <AntDesignStyledGridToolbar
            printOptions={{
                disableToolbarButton: true,
            }}
            csvOptions={{
                fileName: pathName,
                utf8WithBom: true,
            }}
        />
    );
}
