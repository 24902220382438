import { Text, Flex, Box } from "alisson-application";
import { ChangeEvent, useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Container as Button } from "@/presentation/components/Button";
import { useModal } from "@/main/contexts/modal";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";

import imgCard from "@/presentation/assets/icone/card-plus-roxo.svg";
import imgClose from "@/presentation/assets/icone/close.svg";
import { DataActionProductsListModel } from "@/domain/models/actionProductsListModel";
import { Loader } from "../Loader";
import { Container } from "./styles";

import { ProductList } from "./productList";
import { useProducts } from "./hooks/useProducts";

export function AddVoucher() {
    const { handleClose, setState, state } = useModal();
    const { setProductsChoice, productsChoice } = useDetailActionVoucher();

    const [search, setSearch] = useState("");

    function handleAddProducts(productChoice: DataActionProductsListModel) {
        const hasProduct = productsChoice.find(
            (product) => product.id === productChoice.id
        );
        if (hasProduct) {
            const update = productsChoice.filter(
                (product) => product.id !== productChoice.id
            );
            setProductsChoice(update);
        } else {
            setProductsChoice([...productsChoice, productChoice]);
        }
    }

    const { fetch, data, isLoading } = useProducts();

    useEffect(() => {
        fetch();
    }, []);

    function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        setSearch(value);
        fetch({ page: 1, search: value });
    }

    return (
        <Container>
            <Flex alignItems="center" mb="26px">
                <img src={imgCard} alt="" />
                <Text ml="10px" as="h5">
                    ADICIONAR VOUCHERS
                </Text>
            </Flex>

            <div className="container-input buscar">
                <DebounceInput
                    type="text"
                    placeholder="Nome ou ID do voucher"
                    debounceTimeout={1000}
                    value={search}
                    onChange={handleSearchChange}
                    disabled={isLoading}
                />
            </div>

            <div className="list-vouhcer">
                {isLoading ? (
                    <div className="loader">
                        <Loader />
                    </div>
                ) : (
                    <ProductList
                        products={data?.data ?? []}
                        page={data?.current_page ?? 1}
                        totalPages={data?.last_page ?? 1}
                        onPageChange={(page) => fetch({ page, search })}
                        onAddProducts={(productChoice) => {
                            handleAddProducts(productChoice);
                        }}
                    />
                )}

                <Box m="31px auto 0px" width="383px">
                    <Button
                        disabled={productsChoice.length === 0}
                        onClick={() => {
                            setState({
                                ...state,
                                selectVouchers: false,
                                selectVouchersQtd: true,
                            });
                        }}
                    >
                        Adicionar produtos
                    </Button>
                </Box>
            </div>

            <div className="container-close">
                <button onClick={() => handleClose("selectVouchers")}>
                    <img src={imgClose} alt="" />
                </button>
            </div>
        </Container>
    );
}
