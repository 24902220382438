import { useState } from "react";
import { Text } from "alisson-application";
import { Container as Button } from "@/presentation/components/Button";
import { useModal } from "@/main/contexts/modal";

import { Container } from "./styles";

interface AlertProps {
    handleAcepet: () => void;
}

export function Alert(props: AlertProps) {
    const { handleAcepet } = props;
    const { handleClose, state } = useModal();
    const [loading, setLoading] = useState<boolean>(false);
    return (
        <Container className="blob">
            <Text as="h1" color="failure" fontSize="xl">
                Atenção
            </Text>

            <Text as="p" fontSize="lg" color="secondarydark">
                {state.alert?.text? state.alert.text:<Text> Esta ação é irreversível, <br /> deseja continuar?</Text>} 
            </Text>

            <Button
                isLoading={loading}
                onClick={async () => {
                    setLoading(true);
                    try {
                        await handleAcepet();
                    } catch (error) {
                        console.log(error);
                    }
                    setLoading(false);
                    handleClose("alert");
                }}
            >
                Sim!
            </Button>
            <Button
                className="cancelar"
                color="secondarydark"
                onClick={() => {
                    handleClose("alert");
                }}
            >
                Cancelar
            </Button>
        </Container>
    );
}
