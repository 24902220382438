import { Container } from "./styles";
import { useNewAction, TabId } from "@/main/hooks/newAction";

export function TabsNewAction() {
    const { tabs, updateTab } = useNewAction();

    return (
        <Container>
            {tabs.map((tab) => {
                return (
                    <button
                        key={tab.id}
                        className={`${tab.active ? "active" : ""}`}
                        onClick={() => {
                            if (tab.viewer) {
                                updateTab(tab.title as TabId);
                            }
                        }}
                    >
                        {tab.title}
                    </button>
                );
            })}
        </Container>
    );
}
