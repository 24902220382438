import styled from "styled-components";

export const Container = styled.div`
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 190px;
    height: 172px;
    .container-acao {
        position: relative;
        /* h1 {
            &[contenteditable="false"] {
                cursor: not-allowed;
                opacity: 0.5;
            }
        } */

        input {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            color: ${(props) => props.theme.colors.primary};
            border: none;
            background-color: transparent;
            width: 190px;
            padding-right: 17px;
            transition: all 0.5s;
            &:disabled {
                /* cursor: not-allowed;
                opacity: 0.5; */
                transition: all 0.5s;
            }
        }

        .container-btn {
            position: absolute;
            top: 15px;
            right: 0px;
            div[data-testid="loading"] {
                top: 10px;
                left: -9px;
                width: 20px;
            }
        }
    }
`;
