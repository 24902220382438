import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    background-color: ${(props) => props.theme.colors.secondarybright};
    height: fit-content;
    width: 100%;

    button {
        cursor: pointer;
        pointer-events: all;
    }
    .container-tab {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 0px;
        pointer-events: none;
        opacity: 0;
        overflow: hidden;
        transition: opacity 0.3s ease;

        &.remove-padding {
            padding: 0px;
        }
        &.active {
            padding: 20px;
            position: static;
            height: 100%;
            pointer-events: all;
            opacity: 1;
            overflow: unset;
            transition: opacity 0.3s ease;
        }
    }

    .btn-exportar-relatorios {
        display: flex;
        justify-content: center;
        margin-bottom: -15px;
        width: 150px;
        height: 30px;
        align-items: center;
        padding: 20px 0;
    }
`;
