export const translateHeaderTable = {
    toolbarDensity: "TAMANHO",
    toolbarDensityLabel: "TAMANHO",
    toolbarDensityCompact: "PEQUENO",
    toolbarDensityStandard: "MÉDIO",
    toolbarDensityComfortable: "GRANDE",

    toolbarColumns: "COLUNAS",
    toolbarColumnsLabel: "COLUNAS",
    toolbarExportCSV: "EXPORTAR CSV",
    toolbarExportPrint: "IMPRIMIR",
    toolbarExport: "EXPORTAR",

    toolbarFilters: "FILTROS",
    toolbarFiltersLabel: "FILTROS",
    toolbarFiltersTooltipShow: "MOSTRAR",
    toolbarFiltersTooltipHide: "ESCONDER",

    filterPanelColumns: "COLUNAS",
    filterPanelInputPlaceholder: "FILTRO",
    columnsPanelHideAllButton: "ESCONDER TODAS",
    columnsPanelShowAllButton: "MOSTRAR TODAS",
    columnsPanelTextFieldLabel: "COLUNAS",

    filterPanelOperators: "OPERADORES",
    filterOperatorEquals: "IGUAL A",
    filterOperatorNot: "NÃO",
    filterOperatorContains: "CONTÉM",
    filterOperatorStartsWith: "COMEÇA COM",
    filterOperatorEndsWith: "TERMINA COM",
    filterOperatorIsEmpty: "ESTÁ VAZIO",
    filterOperatorIsNotEmpty: "Não está vazio",
    filterOperatorIsAnyOf: "É QUALQUER UM DE",

    filterPanelInputLabel: "VALOR",
    filterPanelOperatorAnd: "E",
    filterPanelOperatorOr: "OU",
    filterPanelAddFilter: "ADICIONAR FILTRO",

    columnMenuSortAsc: "CRESCENTE",
    columnMenuSortDesc: "DECRESCENTE",
    columnMenuFilter: "FILTRAR",
    columnMenuHideColumn: "ESCONDER COLUNA",
    columnMenuUnsort: "REMOVER ORDENAÇÃO",
    columnMenuShowColumns: "MOSTRAR COLUNAS",
    pinToLeft: "FIXAR À ESQUERDA",
    pinToRight: "FIXAR À DIREITA",
    footerTotalRows: "TOTAL DE LINHAS",

    columnMenuLabel: "COLUNAS",
    noRowsLabel: "NENHUM REGISTRO ENCONTRADO",
    columnsPanelTextFieldPlaceholder: "PESQUISAR",
    toolbarQuickFilterPlaceholder: "FILTRO RÁPIDO",
    columnsPanelDragIconLabel: "COLUNAS",
    filterPanelDeleteIconLabel: "FILTRO",
};
