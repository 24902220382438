import { Mixin } from "ts-mixer";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { RemoteFindAction } from "@/data/useCases/findAction/remoteFindAction";
import { RemoteActionProductsRevoke } from "@/data/useCases/actionProductsRevoke/remoteActionProductsRevoke";

const axios = new AxiosHttpClient();

export class Information extends Mixin(
    RemoteFindAction,
    RemoteActionProductsRevoke
) {
    constructor() {
        super(axios);
    }
}
