import React, { createContext, useContext, useState, useEffect } from "react";
import { RemoteListContractorsActions } from "@/data/useCases/listContractorsActions/remoteListContractorsActions";
import { ContractorActionsModel } from "@/domain/models";

interface ContractorsActionsContextType {
  contractorsActions: ContractorActionsModel[];
  loading: boolean;
  loadContractorsActions: () => Promise<void>;
}

const ContractorsActionsContext = createContext<ContractorsActionsContextType | undefined>(undefined);

export const useContractorsActionsContext = (): ContractorsActionsContextType => {
  const context = useContext(ContractorsActionsContext);
  if (!context) {
    throw new Error("useContractorsActionsContext deve ser usado dentro de um ContractorsActionsProvider");
  }
  return context;
};

interface ContractorsActionsProviderProps {
  listContractorsActions: RemoteListContractorsActions;
  children: React.ReactNode;
}

export const ContractorsActionsProvider: React.FC<ContractorsActionsProviderProps> = ({
  listContractorsActions,
  children,
}) => {
  const [contractorsActions, setContractorsActions] = useState<ContractorActionsModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const loadContractorsActions = async () => {
    setLoading(true);
    try {
      const data = await listContractorsActions.loadAll();
      setContractorsActions(data);
    } catch (error) {
      console.error("Erro ao carregar ações dos contratantes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadContractorsActions();
  }, []);

  return (
    <ContractorsActionsContext.Provider value={{ contractorsActions, loading, loadContractorsActions }}>
      {children}
    </ContractorsActionsContext.Provider>
  );
};
