import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { DetailActionTemplateModel } from "@/domain/models/detailActionTemplateModel";
import { DetailActionTemplate } from "@/domain/useCases/detailActionTemplate";

export class RemoteDetailActionTemplate implements DetailActionTemplate {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlUserTempla: string = "action/users/template"
    ) {}

    async file(params: DetailActionTemplateModel): Promise<Blob> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlUserTempla,
            body: params,
            responseType: "blob",
        });
        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    return response.body;
                }

                return {} as any;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
}
