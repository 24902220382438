import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import {
    DetailActionMetricsModel,
    DetailActionMetricsParams,
    DetailActionMetricsFormatted,
} from "@/domain/models";
import { DetailActionMetrics } from "@/domain/useCases";

export class RemoteDetailActionMetrics implements DetailActionMetrics {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlLoadMetrics: string = "action/products/metrics"
    ) {}

    async loadMetrics(
        params: DetailActionMetricsParams
    ): Promise<DetailActionMetricsFormatted> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlLoadMetrics,
            body: params,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return this.formatMetrics(response.body);

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }

    formatMetrics(
        data: DetailActionMetricsModel
    ): DetailActionMetricsFormatted {
        if (!data.default_info) return {} as DetailActionMetricsFormatted;

        const { default_info, products } = data;
        const {
            max_rewards,
            options_obtain_methods,
            options_template,
            rewarded_items,
        } = default_info;

        return {
            max_rewards,
            rewarded_items,
            options_obtain_methods,
            options_template,
            products,
        };
    }
}
