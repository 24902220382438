import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";

export class RemoteActionProductGroupAdd {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly baseUrl: string = "action/products/groups"
    ) { }

    async addProductToGroup(groupId: number, productId: number): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: `${this.baseUrl}/add`,
            body: {
                group_id: groupId,
                product_configuration_id: productId,
            },
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return;
            default:
                throw new UnexpectedError();
        }
    }
}
