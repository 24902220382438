import styled from "styled-components";

export const Container = styled.div`
    .container-img {
        height: 30px;
        width: 20px;
    }
    .conteudo {
        display: flex;
        grid-gap: 100px;
        .processo {
            p {
                margin-bottom: 20px;
            }
            button {
                margin-top: 20px;
            }
        }
    }
`;

export const ContainerModal = styled.div`
    padding: 32px;
    text-align: center;
    width: 450px;
    h1 {
        margin-bottom: 32px;
    }

    p {
        text-transform: unset;
    }
`;
