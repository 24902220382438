import { HttpStatusCode } from "@/data/protocols/http";

export class UnexpectedError extends Error {
    status: HttpStatusCode;

    constructor(
        status: HttpStatusCode = HttpStatusCode.serverError,
        message = "Algo de errado aconteceu. Tente novamente em breve"
    ) {
        super(message);
        this.name = "UnexpectedError";
        this.status = status;
    }
}
