/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useState } from "react";

interface UseModalProps {
    children: React.ReactNode | React.ReactNode[];
}

export interface UseStateModal {
    state: Modais;
    setState: (state: Modais) => void;
    handleClose(close: keyOf<Modais>): void;
    closeAllModais(): void;
    handleOpen(open: keyOf<Modais>): void;
}

export interface Modais {
    selectVouchersQtd: boolean;
    selectVouchers: boolean;
    alert: Alert;
    createGroup: boolean;
    editGroup: boolean;
    smsValidation: boolean;
    loadUsers: boolean;
    report: boolean;
    regulamentoDetalheAcao: boolean;
    personalizadoDetalheAcao: boolean;
    blockedUser: boolean;
    sortingVouchers: boolean;
}

export interface Alert {
    active: boolean;
    onclick: () => void;
    text?: string;
}


type keyOf<T> = keyof T;

const Context = createContext<UseStateModal>({} as UseStateModal);

export function UseModalProvider(props: UseModalProps) {
    const { children } = props;
    const [state, setState] = useState<Modais>({
        selectVouchers: false,
        selectVouchersQtd: false,
        alert: {
            active: false,
            onclick: () => {},
        },
        createGroup: false,
        editGroup: false,
        smsValidation: false,
        loadUsers: false,
        report: false,
        regulamentoDetalheAcao: false,
        personalizadoDetalheAcao: false,
        blockedUser: false,
        sortingVouchers: false,
    });

    function handleClose(close: keyOf<Modais>) {
        setState({
            ...state,
            [close]: false,
        });
    }

    function handleOpen(open: keyOf<Modais>) {
        setState({
            ...state,
            [open]: true,
        });
    }

    function closeAllModais() {
        const keys = Object.keys(state) as keyOf<Modais>[];
        const newState: Modais = {} as Modais;

        keys.forEach((item) => {
            if (item !== "alert") {
                newState[item] = false;
            }
        });

        setState({
            ...newState,
            alert: {
                active: false,
                onclick: () => {},
            },
        });
    }

    return (
        <Context.Provider
            value={{ state, setState, handleClose, handleOpen, closeAllModais }}
        >
            {children}
        </Context.Provider>
    );
}

export function useModal() {
    const context = useContext(Context);
    return context;
}
