import { useDetailAction } from "@/main/hooks/detailAction";
import { DetailVoucher } from "../DetailVoucher";
import { Container } from "./styles";
export function HeaderToggleDetailVoucher() {
    const { ref, vouchers, findActionData } = useDetailAction();

    return (
        <Container>
            <DetailVoucher
                currentRef={ref}
                vouchers={vouchers}
                book_stock={findActionData.book_stock}
            />
        </Container>
    );
}
