import imgAcao from "@/presentation/assets/icone/menu/acao.svg";
import imgChat from "@/presentation/assets/icone/chat.svg";
import imgConversor from "@/presentation/assets/icone/conversor.svg";
import EditUserIcon from "@/presentation/assets/icone/edit-user-icon.svg";


export const paths = [
    {
        icone: imgAcao,
        path: "/acoes",
        title: "Ação",
    },
    {
        icone: imgChat,
        path: "/preciso-de-ajuda",
        title: "Preciso de ajuda",
    },
    {
        icone: imgConversor,
        path: "/conversor-cpf",
        title: "Conversor de CPF",
    },
    {
        icone: EditUserIcon,
        path: "/usuarios-adm",
        title: "Usuários ADM",
    },
];
