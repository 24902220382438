import { Box } from "alisson-application";
import styled from "styled-components";

export const Container = styled(Box)`
    border-top: 1px solid #9d9d9d;
    border-bottom: 1px solid #9d9d9d;

    height: 81px;
    width: 100%;

    display: flex;
    align-items: center;

    & > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        .item {
            &.span {
                grid-column: span 2;
            }
        }
        p {
            text-transform: unset;
        }
    }
`;
