import { Flex, Text } from "alisson-application";
import { InfoCardAction } from "@/presentation/components/InfoCardAction";
import { ShowClienAndAction } from "@/presentation/components/ShowClienAndAction";
import { CardDetailActionInfo } from "@/presentation/components/CardDetailActionInfo";
import { useDetailAction } from "@/main/hooks/detailAction";
import { useDetailActionInformation } from "@/presentation/components/DetailContent/hooks/detailActionInformation";
import { ComplementBlock } from "../ComplementBlock";
import { DetalheUser } from "./detalheUser";

import { useModal } from "@/main/contexts/modal";

import { Container } from "./styles";

import imgKey from "@/presentation/assets/icone/chave.svg";
import imgCalendario from "@/presentation/assets/img/calendario.svg";
import imgRegulamento from "@/presentation/assets/icone/regulamento.svg";
import imgLink from "@/presentation/assets/icone/link.svg";
import { DateTime } from "@/presentation/components/DateTime";
import { useRef } from "react";
import { ValidationDate } from "@/services/date";

import { Modal } from "@/presentation/components/Modal";
import { ModalConteudo } from "@/presentation/components/ModalConteudo";

export function DetailActionInformation() {
    const { state, handleOpen, handleClose } = useModal();
    const { findActionData, stateHistory } = useDetailAction();
    const { handleChange } = useDetailActionInformation();

    const ref = useRef<HTMLDivElement | null>(null);

    const actionInfo = [
        {
            img: imgKey,
            title: "Chave",
            name: findActionData.key,
        },
        {
            img: imgLink,
            title: "URL",
            name: findActionData.key,
        },
        {
            img: imgRegulamento,
            title: "Regulamento",
            name: "",
            onClick: () => handleOpen("regulamentoDetalheAcao"),
        },
        {
            img: imgCalendario,
            title: "Início",
            name: findActionData.start_date,
            onClick: () => openDatePicker("Início"),
        },
        {
            img: imgCalendario,
            title: "Fim",
            name: findActionData.end_date,
            onClick: () => openDatePicker("Fim"),
        },
    ];

    function openDatePicker(target: string) {
        if (ref.current) {
            const item = ref.current?.querySelector(
                `input[placeholder="${target}"]`
            ) as HTMLInputElement;
            item.click();
        }
    }

    return (
        <Container>
            <Flex mb="44px">
                <InfoCardAction
                    book_stock={findActionData.book_stock}
                    job={findActionData.job}
                    logo={findActionData?.detailed?.logo}
                    status={0}
                    blocked={findActionData.blocked}
                    background={findActionData?.detailed?.background}
                />
                <ShowClienAndAction
                    contractorName={stateHistory.client}
                    name={findActionData.name}
                />

                <div className="container-descricao">
                    <ComplementBlock
                        border="none !important"
                        mb="19px"
                        width={395}
                        values={[
                            {
                                label: "Descrição",
                                value: findActionData.description,
                            },
                        ]}
                    />

                    <DetalheUser />
                </div>
            </Flex>

            <div className="containr-cards" ref={ref}>
                {actionInfo.map((item, key) => {
                    return <CardDetailActionInfo key={key} {...item} />;
                })}
                <div className="container-dateTime" hidden>
                    <DateTime
                        label="Início"
                        initialValue={null}
                        onAccept={async (date) => {
                            await handleChange(
                                "start_date",
                                new ValidationDate(date).database()
                            );
                        }}
                    />
                    <DateTime
                        label="Fim"
                        initialValue={null}
                        onAccept={async (date) => {
                            await handleChange(
                                "end_date",
                                new ValidationDate(date).database()
                            );
                        }}
                    />
                </div>
            </div>

            <div className="container-config">
                <div className="left">
                    <ComplementBlock
                        borderTop="none !important"
                        mb="19px"
                        values={[
                            {
                                label: "Tipo de login",
                                value: findActionData.login_type,
                            },
                            {
                                label: "CPF truncado",
                                value: findActionData.login_type_cpf_truncated
                                    ? "SIM"
                                    : "NÃO",
                            },
                        ]}
                    />
                    <ComplementBlock
                        borderTop="none !important"
                        mb="19px"
                        values={[
                            {
                                label: "Validador",
                                value: "Data de nascimento",
                            },
                            {
                                label: "Total de resgates permitidos",
                                value: findActionData.max_rewards + "",
                            },
                        ]}
                    />
                    <ComplementBlock
                        borderTop="none !important"
                        mb="19px"
                        values={[
                            // {
                            //     label: "Entregar vouchers em conjuntos",
                            //     value: "falta",
                            // },
                            {
                                label: "Reservar estoque",
                                value: findActionData.book_stock
                                    ? "SIM"
                                    : "NÃO",
                            },
                        ]}
                    />
                </div>

                <div className="dir">
                    <ComplementBlock
                        borderTop="none !important"
                        mb="19px"
                        values={[
                            {
                                label: "Gerar participantes",
                                value: findActionData.generate_users
                                    ? "SIM"
                                    : "NÃO",
                            },
                            {
                                label: "Campos personalizados",
                                value: findActionData?.extra_fields?.length
                                    ? "SIM"
                                    : "NÃO",
                            },
                        ]}
                    />

                    <Flex>
                        <div className="item">
                            <Text as="span" fontWeight="700" fontSize="xs">
                                Exibir texto LGPD:
                                <Text ml="8px" color="secondarydark" as="p">
                                    {findActionData.use_lgpd ? "SIM" : "NÃO"}
                                </Text>
                            </Text>
                        </div>
                        <div className="item">
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                onClick={() =>
                                    handleOpen("personalizadoDetalheAcao")
                                }
                            >
                                Personalizado
                            </Text>
                        </div>
                    </Flex>
                </div>
            </div>

            <Modal
                open={state.regulamentoDetalheAcao}
                handleClose={() => handleClose("regulamentoDetalheAcao")}
            >
                <ModalConteudo />
            </Modal>

            <Modal
                open={state.personalizadoDetalheAcao}
                handleClose={() => handleClose("personalizadoDetalheAcao")}
            >
                <ModalConteudo />
            </Modal>
        </Container>
    );
}
