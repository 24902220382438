import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

interface ISwitch extends SwitchProps {
    onChange?: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    testid?: string;
}

export const IOSSwitch = styled((props: ISwitch) => (
    <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        data-testid={props.testid}
        onChange={(e, checked) => {
            if (props.onChange) {
                props.onChange(e, checked);
            }
        }}
        {...props}
    />
))(({ theme }) => ({
    width: 44,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 3.5,
        margin: 2,
        transitionDuration: "300ms",
        "&:hover": {
            backgroundColor: "transparent",
        },
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& .MuiSwitch-thumb": {
                color: "#57B847",
                transition: theme.transitions.create(["color"], {
                    duration: 500,
                }),
            },
            "& + .MuiSwitch-track": {
                backgroundColor:
                    theme.palette.mode === "dark" ? "#ECECEC" : "#ECECEC",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 14,
        height: 14,
        color: "#C6C8CA",
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E1E2E3" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));
