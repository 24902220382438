import { Text } from "alisson-application";
import { useModal } from "@/main/contexts/modal";
import { useDetailAction } from "@/main/hooks/detailAction";
import imgClose from "@/presentation/assets/icone/close.svg";
import { Container } from "./styles";

export function ModalConteudo() {
    const { findActionData } = useDetailAction();
    const { state, handleClose } = useModal();

    const regulamento = state.regulamentoDetalheAcao;
    const personalizado = state.personalizadoDetalheAcao;

    return (
        <Container>
            {regulamento ? (
                <>
                    <div className="container-header">
                        <Text as="h1">Regulamento</Text>
                    </div>

                    <Text
                        color="secondarydark"
                        as="p"
                        style={{
                            textTransform: "unset",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: findActionData.internal_policy
                                ? findActionData.internal_policy
                                : "",
                        }}
                    ></Text>

                    <button
                        className="btn-close"
                        onClick={() => handleClose("regulamentoDetalheAcao")}
                    >
                        <img src={imgClose} alt="close" />
                    </button>
                </>
            ) : null}

            {personalizado ? (
                <>
                    <div className="container-header">
                        <Text as="h1">Texto LGPD personalizado</Text>
                    </div>

                    <Text
                        color="secondarydark"
                        as="p"
                        style={{
                            textTransform: "unset",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: findActionData.lgpd_text
                                ? findActionData.lgpd_text
                                : "",
                        }}
                    ></Text>

                    <button
                        className="btn-close"
                        onClick={() => handleClose("personalizadoDetalheAcao")}
                    >
                        <img src={imgClose} alt="close" />
                    </button>
                </>
            ) : null}
        </Container>
    );
}
