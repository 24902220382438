import styled from "styled-components";

export const Container = styled.div`
    padding: 34px 24px 47px 24px;
    background-color: ${(props) => props.theme.colors.white};
    width: 375px;
    height: auto;
    .container-sucess {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 166px;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.mdl};
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 34px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.primaryDark};
            margin-bottom: 105px;
        }
    }
`;
