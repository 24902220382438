import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { ActionReportsNewModel } from "@/domain/models";
import { ActionReportsNew } from "@/domain/useCases/actionReportsNew";

export class RemoteActionReportsNew implements ActionReportsNew {
    constructor(
        readonly httpGetClient: HttpClient,
        readonly urlNewReport: string = "action/reports/new"
    ) {}

    async NewReport(id: number): Promise<ActionReportsNewModel> {
        const response = await this.httpGetClient.request({
            url: `${this.urlNewReport}/${id}`,
            method: "get",
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    return response.body;
                }
                return {} as ActionReportsNewModel;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return {} as ActionReportsNewModel;
            default:
                throw new UnexpectedError();
        }
    }
}
