import { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { ModalInterceptor } from "./modal";
export function Interceptor() {
    const axios = new AxiosHttpClient();
    const [status, setStatus] = useState<null | number>(null);
    const [path, setPath] = useState<string | null>(null);
    const [mesage, setMesage] = useState<string | null>(null);

    useEffect(() => {
        axios.axiosInstance.interceptors.response.use(
            async (response: AxiosResponse) => {
                return response;
            },
            async (error: AxiosError<{ message: string }>) => {
                if (
                    error.response?.config.url?.split("/").includes("validate")
                ) {
                    return;
                }

                const url = error.response?.config.url;

                const path = url?.replace(
                    /^https?:\/\/(api\.yetzpromo\.com\.br\/api\/|localhost\/api\/)/,
                    ""
                );

                setMesage(error.response?.data?.message || "");
                setPath(path || "");
                setStatus(error.response?.status || 0);
                return error;
            }
        );
    }, []);

    console.log("status", status);
    console.log("path", path);


    return status && status !== 403 ? (
        <ModalInterceptor
            status={status? status : 0}
            setStatus={setStatus}
            path={path}
            mesage={mesage}
        />
    ) : (
        <></>
    );
}
