import Table from "@/presentation/components/ActionTable";
import { useHistoryAction } from "@/main/hooks/historyAction";
import { GridColumns } from "@mui/x-data-grid-pro";
import { StyledTable } from "./styles";
import { useEffect, useState } from "react";
import { ActionLoadAllModel } from "@/domain/models";
import { ValidationDate } from "@/services/date";
import imgDownload from "@/presentation/assets/icone/download-roxo.svg";
import { Loader } from "../Loader";

export function TableLoad() {
    const { action, stateHistory } = useHistoryAction();
    const [dados, setDados] = useState<ActionLoadAllModel[]>([]);
    const [loading, setLoading] = useState(true);
    const width = 1080 / 4.6;

    const data = dados.map((data) => {
        const date = new ValidationDate(data.date);
        return {
            id: data.id,
            identifier: data.identifier,
            dataHora: date.userDateTime().replace(/-/g, "|"),
            criadoPor: data.user,
            apr: data.approved_user,
            link: data.file,
        };
    });

    const columns: GridColumns = [
        {
            field: "identifier",
            headerName: "Identificador",
            width,
        },
        {
            field: "dataHora",
            headerName: "Data / Hora",
            width,
        },
        {
            field: "criadoPor",
            headerName: "Criado por",
            width,
        },
        {
            field: "apr",
            headerName: "Aprovado por",
            width,
        },
        {
            field: "link",
            headerName: "Download",
            width: 150,
            renderCell: (params) => {
                return (
                    <a
                        href={params.value as string}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={imgDownload} alt="" />
                    </a>
                );
            },
        },
    ];

    useEffect(() => {
        async function fetch() {
            try {
                const response = await action.loadAll(stateHistory.id);
                setDados(response);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }

        if (loading) fetch();
    }, [loading]);

    if (loading)
        return (
            <StyledTable>
                <Loader />
            </StyledTable>
        );

    return (
        <StyledTable>
            <Table
                rows={data}
                columns={columns}
                nameCSV="csvName"
                header={false}
            />
        </StyledTable>
    );
}
