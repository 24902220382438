import { HistoryActionLoad } from "@/presentation/components/HistoryActionLoad";
import { UseActionProvider } from "@/main/hooks/historyAction";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { RemoteActionLoadAll } from "@/data/useCases/historyActionLoad/historyActionLoad";

export function PageHistoryActionLoad() {
    const axios = new AxiosHttpClient();
    const action = new RemoteActionLoadAll(axios);

    return (
        <UseActionProvider action={action}>
            <HistoryActionLoad />
        </UseActionProvider>
    );
}
