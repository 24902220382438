import { useState } from "react";
import { Container as Button } from "@/presentation/components/Button";
import { Box } from "alisson-application";
import { IOSSwitch } from "@/presentation/components/Switch";
import InputMask from "react-input-mask";
import {
  Container,
  Backdrop,
  Popup,
  PopupHeader,
  PopupBody,
  PopupFooter,
  Input,
  Select,
  AddButton,
  Divider,
  SwitchWrapper,
  SwitchLabel,
  SearchInputWrapper,
  SearchWrapper,
  SearchIcon,
  ActionItemStyled,
  PopupBodyMessage,
  AddButtonMessage,
  PopupMessage,
  BackdropMessage,
} from "./styles";

import AddUserIcon from "@/presentation/assets/icone/add-user-icon.svg";
import AddUserADMPopUpIcon from "@/presentation/assets/icone/add-user-adm-pop-up-icon.svg";
import SearchIconImage from "@/presentation/assets/icone/search-icon.svg";
import { useContractorsActionsContext } from "@/main/hooks/userContractorActions";
import { useCreateADMUserContext } from "@/main/hooks/useCreateADMUserContext";

interface ActionItem {
  uniqueId: string;
  name: string;
  cnpj: string;
  actionName: string;
}

export function SubHeaderEditUsersADM() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMessagePopupOpen, setIsMessagePopupOpen] = useState(false);
  const [messagePopupContent, setMessagePopupContent] = useState("");

  const [isAllowAllActionsChecked, setIsAllowAllActionsChecked] = useState(true);
  const [isSelectActionsChecked, setIsSelectActionsChecked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState<ActionItem[]>([]);

  const profileMap: Record<string, number> = {
    "MASTER": 1,
    "CLIENT": 2,
    "TEST - CLIENT": 3,
    "FAC": 4,
    "COMERCIAL": 5,
    "OPERACIONAL": 6,
    "ATENDIMENTO (EXTERNO)": 7,
  };
  const [profile, setProfile] = useState("MASTER");

  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [notify, setNotify] = useState("1");

  const [errors, setErrors] = useState<{
    name?: string;
    cpf?: string;
    mobile?: string;
    password?: string;
    email?: string;
    selectedItems?: string;
  }>({});

  const { contractorsActions } = useContractorsActionsContext();
  const { createUser } = useCreateADMUserContext();

  const expandedList: ActionItem[] = contractorsActions.flatMap((item) =>
    item.actions.map((action: { actionId: number; actionName: string }) => ({
      uniqueId: `${item.id}-${action.actionId}`,
      name: item.name,
      cnpj: item.document || "",
      actionName: action.actionName,
    }))
  );

  const filteredList = expandedList.filter(
    (entry) =>
      (entry.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.cnpj.includes(searchTerm)) &&
      !selectedItems.some((selected) => selected.uniqueId === entry.uniqueId)
  );

  const handleSelectItem = (item: ActionItem) => {
    if (!selectedItems.some((i) => i.uniqueId === item.uniqueId)) {
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  const handleRemoveItem = (uniqueId: string) => {
    setSelectedItems((prev) => prev.filter((item) => item.uniqueId !== uniqueId));
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleAllowAllActionsChange = () => {
    setIsAllowAllActionsChecked((prev) => {
      const newState = !prev;
      if (!newState) {
        setIsSelectActionsChecked(true);
      }
      return newState;
    });
  };

  const handleSelectActionsChange = () => {
    setIsSelectActionsChecked((prev) => {
      const newState = !prev;
      if (!newState) {
        setIsAllowAllActionsChecked(true);
      }
      return newState;
    });
  };

  function validateName(value: string) {
    return value.trim().length > 0;
  }

  function validateMobile(value: string) {
    const phoneDigits = value.replace(/[^\d]/g, "");
    return phoneDigits.length >= 10;
  }

  function validateCPF(cpfValue: string): boolean {
    const cleaned = cpfValue.replace(/[^\d]/g, "");
    if (cleaned.length !== 11 || /^(\d)\1+$/.test(cleaned)) {
      return false;
    }
    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cleaned.substring(i - 1, i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cleaned.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cleaned.substring(i - 1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cleaned.substring(10, 11))) return false;

    return true;
  }

  function validatePassword(pass: string) {
    return pass.trim().length > 0;
  }

  function validateEmail(emailValue: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(emailValue.trim());
  }

  const sanitizeCPF = (cpf: string): string => {
    return cpf.replace(/\D/g, "");
  };

  const handleAddUser = async () => {
    const newErrors: {
      name?: string;
      cpf?: string;
      mobile?: string;
      password?: string;
      email?: string;
      selectedItems?: string;
    } = {};

    let hasError = false;

    if (!validateName(name)) {
      newErrors.name = "O campo Nome é obrigatório.";
      hasError = true;
    }
    if (!cpf.trim()) {
      newErrors.cpf = "O campo CPF é obrigatório.";
      hasError = true;
    } else if (!validateCPF(cpf)) {
      newErrors.cpf = "CPF inválido. Verifique e tente novamente.";
      hasError = true;
    }
    if (!validateMobile(mobile)) {
      newErrors.mobile = "Por favor, insira um telefone válido.";
      hasError = true;
    }
    if (!validatePassword(password)) {
      newErrors.password = "O campo Senha é obrigatório.";
      hasError = true;
    }

    if (!validateEmail(email)) {
      newErrors.email = "Por favor, insira um e-mail válido.";
      hasError = true;
    }

    if (isSelectActionsChecked && selectedItems.length === 0) {
      newErrors.selectedItems = "Selecione pelo menos uma ação.";
      hasError = true;
    }

    setErrors(newErrors);
    if (hasError) {
      console.debug("Erros encontrados, encerrando função handleAddUser");
      return;
    }


    try {

      const sanitizedCPF = sanitizeCPF(cpf);

      const actionsPayload = selectedItems.map((item) => {
        const splitted = item.uniqueId.split("-");
        const actionId = splitted.length === 2 ? parseInt(splitted[1]) : 0;
        return { action_id: actionId };
      });

      const payload = {
        name,
        username: sanitizedCPF,
        document: sanitizedCPF,
        email,
        mobile,
        access_polices_id: String(profileMap[profile]),
        password,
        password_confirmation: password,
        notify,
        actions: actionsPayload,
      };

      console.debug("Tentando criar usuário com payload:", payload);

      await createUser(payload);

      setMessagePopupContent("Usuário criado com sucesso!");
      setIsMessagePopupOpen(true);
    } catch (error) {
      setMessagePopupContent("Erro ao criar usuário, tente novamente mais tarde.");
      setIsMessagePopupOpen(true);
    }
  };


  const handleMessagePopupClose = () => {
    setIsMessagePopupOpen(false);
    setIsPopupOpen(false);

    setName("");
    setCpf("");
    setMobile("");
    setPassword("");
    setEmail("");
    setNotify("1");
    setProfile("MASTER");
    setSelectedItems([]);
    setSearchTerm("");
    setIsAllowAllActionsChecked(true);
    setIsSelectActionsChecked(false);
    setErrors({});
  };

  return (
    <Container>
      <Box ml="auto" width={200}>
        <Button onClick={togglePopup}>
          <img
            src={AddUserIcon}
            alt="Ícone de adicionar usuário"
            style={{ width: "16px", height: "16px", marginRight: "8px" }}
          />
          Adicionar Usuário
        </Button>
      </Box>

      {isMessagePopupOpen && (
        <BackdropMessage>
          <PopupMessage>
            <PopupBodyMessage>
              <p>{messagePopupContent}</p>
            </PopupBodyMessage>
            <PopupFooter>
              <AddButtonMessage onClick={handleMessagePopupClose}>OK</AddButtonMessage>
            </PopupFooter>
          </PopupMessage>
        </BackdropMessage>
      )}

      {isPopupOpen && (
        <>
          <Backdrop onClick={togglePopup} />
          <Popup>
            <PopupHeader>
              <div>
                <img
                  src={AddUserADMPopUpIcon}
                  alt="Ícone de adicionar usuário"
                  style={{ width: "20px", height: "20px", marginRight: "8px" }}
                />
                <h2> ADICIONAR USUÁRIO </h2>
              </div>
            </PopupHeader>
            <Divider />

            <PopupBody>
              <Input
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ borderColor: errors.name ? "red" : "#ced4da" }}
              />
              {errors.name && (
                <span style={{ color: "red", fontSize: "12px" }}>{errors.name}</span>
              )}

              <InputMask
                mask="999.999.999-99"
                value={cpf}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCpf(e.target.value)}
              >
                {(inputProps: React.InputHTMLAttributes<HTMLInputElement>) => (
                  <Input
                    {...inputProps}
                    autoComplete="off"
                    placeholder="CPF"
                    style={{
                      borderColor: errors.cpf ? "red" : "#ced4da",
                      marginTop: "12px",
                    }}
                  />
                )}
              </InputMask>
              {errors.cpf && (
                <span style={{ color: "red", fontSize: "12px" }}>{errors.cpf}</span>
              )}

              <InputMask
                mask="(99) 99999-9999"
                value={mobile}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setMobile(e.target.value)
                }
              >
                {(inputProps: React.InputHTMLAttributes<HTMLInputElement>) => (
                  <Input
                    {...inputProps}
                    autoComplete="off"
                    placeholder="Telefone"
                    style={{
                      borderColor: errors.mobile ? "red" : "#ced4da",
                      marginTop: "12px",
                    }}
                  />
                )}
              </InputMask>
              {errors.mobile && (
                <span style={{ color: "red", fontSize: "12px" }}>{errors.mobile}</span>
              )}

              <Input
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  borderColor: errors.email ? "red" : "#ced4da",
                  marginTop: "12px",
                }}
              />
              {errors.email && (
                <span style={{ color: "red", fontSize: "12px" }}>{errors.email}</span>
              )}

              <Input
                placeholder="Senha"
                type="password"
                value={password}
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  borderColor: errors.password ? "red" : "#ced4da",
                  marginTop: "12px",
                }}
              />
              {errors.password && (
                <span style={{ color: "red", fontSize: "12px" }}>{errors.password}</span>
              )}

              <Select
                value={profile}
                onChange={(e) => setProfile(e.target.value)}
                style={{ marginTop: "12px" }}
              >
                {Object.keys(profileMap).map((profileName) => (
                  <option key={profileName} value={profileName}>
                    {profileName}
                  </option>
                ))}
              </Select>

              <Select
                value={notify}
                onChange={(e) => setNotify(e.target.value)}
                style={{ marginTop: "12px" }}
              >
                <option value="1">Notificar</option>
                <option value="0">Não Notificar</option>
              </Select>

              <SwitchWrapper>
                <IOSSwitch
                  checked={isAllowAllActionsChecked}
                  onChange={handleAllowAllActionsChange}
                />
                <SwitchLabel>Permitir usuário visualizar todas as ações</SwitchLabel>
              </SwitchWrapper>

              {!isAllowAllActionsChecked && (
                <>
                  <SwitchWrapper>
                    <IOSSwitch
                      checked={isSelectActionsChecked}
                      onChange={handleSelectActionsChange}
                    />
                    <SwitchLabel>
                      Selecionar ações que o usuário pode visualizar
                    </SwitchLabel>
                  </SwitchWrapper>

                  {isSelectActionsChecked && (
                    <>
                      <SearchInputWrapper>
                        <SearchWrapper>
                          <SearchIcon src={SearchIconImage} alt="Ícone de busca" />
                          <Input
                            placeholder="Buscar por Cliente"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                        </SearchWrapper>

                        <div
                          style={{
                            width: "100%",
                            background: "#FFF",
                            maxHeight: "100px",
                            overflowY: "auto",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {filteredList.map((item) => (
                            <ActionItemStyled
                              key={item.uniqueId}
                              onClick={() => handleSelectItem(item)}
                            >
                              {item.actionName}
                            </ActionItemStyled>
                          ))}
                        </div>
                      </SearchInputWrapper>

                      {errors.selectedItems && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors.selectedItems}
                        </span>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          marginTop: "8px",
                        }}
                      >
                        {selectedItems.map((item) => (
                          <div
                            key={item.uniqueId}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              background: "#F5F5F5",
                              borderRadius: "10px",
                              padding: "5px 10px",
                              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
                              fontFamily: "Lato",
                              fontSize: "14px",
                              color: "#5E5F5F",
                            }}
                          >
                            <span>{item.actionName}</span>
                            <button
                              onClick={() => handleRemoveItem(item.uniqueId)}
                              style={{
                                background: "transparent",
                                border: "none",
                                color: "#5E5F5F",
                                cursor: "pointer",
                                marginLeft: "8px",
                                fontSize: "16px",
                              }}
                            >
                              &times;
                            </button>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </PopupBody>

            <PopupFooter>
              <AddButton onClick={handleAddUser}>Adicionar</AddButton>
            </PopupFooter>
          </Popup>
        </>
      )}
    </Container>
  );
}
