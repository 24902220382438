import { Button } from "alisson-application";
import styled from "styled-components";

export const SimpleChips = styled(Button)`
    height: 24px;
    border-radius: 4px;
    width: fit-content;
    padding: 7px 9px;

    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;

    color: ${(props) => props.theme.colors.white};
    cursor: auto;
    &:hover {
        opacity: 1 !important;
    }
`;
