import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    .container-forms {
        width: 100%;

        .container-input {
            &:last-child {
                margin-bottom: 0px;
            }
        }

        .container-login {
            width: 100%;

            form {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .primeiraConfig {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .container-header {
        margin-bottom: 36px;
        h1 {
            text-transform: unset;
        }
    }

    .container-text {
        opacity: 1;
        transition: all 0.5s;
        &:hover {
            opacity: 0.5;
            transition: all 0.5s;
        }
        button {
            pointer-events: none;
        }
    }

    .container-color {
        margin-bottom: 27px;

        .color {
            display: flex;
            align-items: center;

            .container-input {
                margin-bottom: 0px;
            }

            input[type="text"] {
                width: 148px;
            }

            input[type="color"] {
                -webkit-appearance: none;
                padding: 0;
                border: none;
                background-color: transparent;
                width: 31px;
                height: 31px;
                border-radius: 5px;
                margin-right: 11px;

                &::-webkit-color-swatch {
                    border: none;
                    border-radius: 5px;
                    padding: 0;
                }
                ::-webkit-color-swatch-wrapper {
                    border: none;
                    border-radius: 5px;
                    padding: 0;
                }
            }
        }
    }

    .container-logo {
        width: 269px;

        .container-img {
            min-height: 100px;
            margin-bottom: 10px;
            padding: 0px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            position: relative;

            img {
                display: block;
                height: 100%;
                width: 100%;
            }

            .removeImagem {
                position: absolute;
                width: 16px;
                height: 16px;
                top: 10px;
                right: 10px;
                cursor: pointer;
            }
        }

        h5 {
            margin-bottom: 24px;
        }

        button {
            margin-bottom: 5px;
        }

        span {
            font-family: "Lato";
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            color: ${(props) => props.theme.colors.secondarydark};
        }
    }

    .container-form {
        width: 302px;

        .container-input {
            margin-bottom: 20px;
        }
    }

    .manutencao-input {
        margin-top: 20px;
    }

    .btn-salvar {
        width: 302px;
        margin: 20px auto;
    }

    .container-itens {
        display: grid;
        grid-template-columns: 1fr 1fr 354px;
        grid-gap: 74px;
    }

    .container-actions-form {
        position: absolute;
        top: 32px;
        right: 32px;
        display: flex;
        align-items: center;
    }
`;

export const StylesDetailCustomForm = styled.div`
    width: 100%;
    transition: all.5s;

    &.disabled {
        filter: grayscale(1);
        transition: all.5s;
        position: relative;

        .container-itens {
            pointer-events: none;
        }

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            cursor: not-allowed;
        }
    }

    &.container-info {
        border-bottom: 1px solid rgb(157, 157, 157);
        margin-bottom: 29px;
        padding-bottom: 32px;
    }

    .container-itens {
        display: grid;
        grid-template-columns: 1fr 1fr 354px;
        grid-gap: 74px;
    }

    .container-input {
        margin-bottom: 16px;

        &.header-loagin {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 418px;
        }

        &.text-LGPD {
            transition: all.5s;
            position: relative;

            &.disabled {
                cursor: not-allowed;

                .container-draf {
                    filter: grayscale(1);
                    transition: all 0.5s;
                    pointer-events: none;
                    position: relative;
                    z-index: 10;

                    &::after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        z-index: 999;
                    }
                }
            }

            .container-draf {
                width: 101%;
                height: 100%;
                border-radius: 10px;
                transition: all 0.5s;
                z-index: 20;
            }
        }

        .btn-salvar {
            margin-top: 10px;
            width: 390px;
        }
    }

    .container-thumbnail {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        position: relative;
        left: 16px;

        .thumbnail {
            width: 186px;
            height: 105px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
        }

        .button-upload {
            width: 147px;
            padding-top: 5px;

            span {
                font-family: "Lato";
                font-style: italic;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;
                color: ${(props) => props.theme.colors.secondarydark};
            }
        }
    }
`;
