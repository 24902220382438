import styled from "styled-components";

export const Container = styled.div`
    padding-left: 17px;
`;

export const StyledTable = styled.div`
    width: 1080px;
    min-height: 200px;
    position: relative;
`;
