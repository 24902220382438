import styled from "styled-components";

export const Container = styled.div`
    height: 96px;
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 5px;
    padding-right: 32px;
    background-color: ${(props) => props.theme.colors.tertiary};
    transition: all 0.5s;
    margin-bottom: 10px;
    padding-left: 20px;
    &.active {
        border: 1px solid ${(props) => props.theme.colors.primary};
        background-color: ${(props) => props.theme.colors.white};
        transition: all 0.5s;
    }
    p {
        text-transform: unset;
    }
    .container-text-img {
        display: flex;
        align-items: center;
        margin-left: 12px;
        img {
            width: 94px;
            height: 62px;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    .container-qtd-and {
        width: 236px;
        margin-left: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
        justify-content: space-between;
    }
`;
