import * as Yup from "yup";

export interface IValues {
    value: string;
}

export const initialValues: IValues = {
    value: "",
};

export const validationLogin = Yup.object().shape({
    value: Yup.string().required("Campo obrigatório"),
});
