import styled from "styled-components";

export const Container = styled.div`
    .container-header {
        position: relative;
    }

    .container-cards {
        transition: all 0.5s;
        &.active {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;
        }

        &.filter {
            padding-top: 92px;
            transition: all 0.5s;
        }
    }
`;
