import React, { useState, useRef, forwardRef, useEffect } from "react";
import {
    StyledPeriodContainer,
    StyledPeriodFieldGroup,
    StyledPeriodField,
    StyledPeriodDivider,
    StyledDatePicker,
    StyledTimePicker,
    CalendarIconStyle,
    ClockIconStyle
} from "./DetailCustomPeriodStyles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { TextField } from "@mui/material";
import ArrowRightIcon from "@/presentation/assets/icone/arrow-right-icon.svg";
import CalendarIcon from "@/presentation/assets/icone/calendar-icon.svg";
import ClockIcon from "@/presentation/assets/icone/clock-icon.svg";


const CustomDateInput = forwardRef<HTMLInputElement, any>((props, ref) => (
    <input {...props} ref={ref} />
));
CustomDateInput.displayName = "CustomDateInput";

type DetailCustomPeriodProps = {
    blocked_text_start_date?: string | null;
    blocked_text_end_date?: string | null;
    onChangeStartDate: (value: string) => void;
    onChangeEndDate: (value: string) => void;
    title_message?: string;
};

function parseDateString(dateString?: string | null): Date | null {
    if (!dateString || dateString.trim() === "") return null;
    const [datePart, timePart] = dateString.split(" ");
    if (!datePart || !timePart) return null;
    const [year, month, day] = datePart.split("-").map(Number);
    const [hour, minute, second] = timePart.split(":").map(Number);
    if (!year || !month || !day || hour === undefined || minute === undefined || second === undefined) {
        return null;
    }
    return new Date(year, month - 1, day, hour, minute, second);
}

const isToday = (date: Date) => {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const DetailCustomPeriod: React.FC<DetailCustomPeriodProps> = ({
    blocked_text_start_date,
    blocked_text_end_date,
    onChangeStartDate,
    onChangeEndDate,
    title_message = ""
}) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [endTime, setEndTime] = useState<Date | null>(null);
    const [error, setError] = useState<string | null>(null);

    const [startTimeOpen, setStartTimeOpen] = useState(false);
    const [endTimeOpen, setEndTimeOpen] = useState(false);
    const [startDateOpen, setStartDateOpen] = useState(false);
    const [endDateOpen, setEndDateOpen] = useState(false);

    const startDateRef = useRef<HTMLInputElement>(null);
    const endDateRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const parsedStart = parseDateString(blocked_text_start_date);
        const parsedEnd = parseDateString(blocked_text_end_date);

        const defaultDate = new Date();

        const newStartDate = parsedStart ? new Date(parsedStart.getFullYear(), parsedStart.getMonth(), parsedStart.getDate()) : defaultDate;
        const newEndDate = parsedEnd ? new Date(parsedEnd.getFullYear(), parsedEnd.getMonth(), parsedEnd.getDate()) : defaultDate;

        setStartDate(newStartDate);
        setEndDate(newEndDate);

        const newStartTime = parsedStart ? new Date(1970, 0, 1, parsedStart.getHours(), parsedStart.getMinutes(), parsedStart.getSeconds()) : new Date(1970, 0, 1, defaultDate.getHours(), defaultDate.getMinutes(), defaultDate.getSeconds());
        const newEndTime = parsedEnd ? new Date(1970, 0, 1, parsedEnd.getHours(), parsedEnd.getMinutes(), parsedEnd.getSeconds()) : new Date(1970, 0, 1, defaultDate.getHours(), defaultDate.getMinutes(), defaultDate.getSeconds());

        setStartTime(newStartTime);
        setEndTime(newEndTime);

        updateDateTime(newStartDate, newStartTime, onChangeStartDate);
        updateDateTime(newEndDate, newEndTime, onChangeEndDate);

    }, [blocked_text_start_date, blocked_text_end_date]);

    const handleStartDateChange = (date: Date | null) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (date && date < currentDate) {
            setStartDate(currentDate);
            updateDateTime(currentDate, startTime, onChangeStartDate);
            return;
        }
        setError(null);
        setStartDate(date);
        updateDateTime(date, startTime, onChangeStartDate);
    };

    const handleEndDateChange = (date: Date | null) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        
        if (date) {
            const endDateOnly = new Date(date);
            endDateOnly.setHours(0, 0, 0, 0);
    
            if (endDateOnly < currentDate) {
                setEndDate(currentDate);
                updateDateTime(currentDate, endTime, onChangeEndDate);
                return;
            }
    
            if (startDate) {
                const startDateOnly = new Date(startDate);
                startDateOnly.setHours(0, 0, 0, 0);
    
                if (endDateOnly < startDateOnly) {
                    setEndDate(startDateOnly);
                    updateDateTime(startDateOnly, endTime, onChangeEndDate);
                    return;
                }
            }
        }
    
        setError(null);
        setEndDate(date);
        updateDateTime(date, endTime, onChangeEndDate);
    };
    
    const handleStartTimeChange = (time: Date | null) => {
        if (time === null) {
            setError(null);
            setStartTime(null);
            updateDateTime(startDate, null, onChangeStartDate);
            return;
        }
    
        const now = new Date();
        const combinedTime = new Date(startDate || now);
        combinedTime.setHours(time?.getHours() || 0, time?.getMinutes() || 0);
    
        if (startDate && isToday(startDate)) {
            if (combinedTime < now) {
                const newTime = new Date(1970, 0, 1, now.getHours(), now.getMinutes(), now.getSeconds());
                setStartTime(newTime);
                updateDateTime(startDate, newTime, onChangeStartDate);
                return;
            }
        }
    
        setError(null);
        setStartTime(time);
        updateDateTime(startDate, time, onChangeStartDate);
    };
    
    const handleEndTimeChange = (time: Date | null) => {
        if (time === null) {
            setError(null);
            setEndTime(null);
            updateDateTime(endDate, null, onChangeEndDate);
            return;
        }
    
        const now = new Date();
        const combinedTime = new Date(endDate || now);
        combinedTime.setHours(time?.getHours() || 0, time?.getMinutes() || 0);
    
        if (endDate && isToday(endDate)) {
            if (combinedTime < now) {
                const newTime = new Date(1970, 0, 1, now.getHours(), now.getMinutes(), now.getSeconds());
                setEndTime(newTime);
                updateDateTime(endDate, newTime, onChangeEndDate);
                return;
            }
        }
    
        setError(null);
        setEndTime(time);
        updateDateTime(endDate, time, onChangeEndDate);
    };

    const handleStartTimeBlur = () => {
        const now = new Date();
        const combinedTime = new Date(startDate || now);
        combinedTime.setHours(startTime?.getHours() || 0, startTime?.getMinutes() || 0);
    
        if (startDate && isToday(startDate) && combinedTime < now) {
            const newTime = new Date(1970, 0, 1, now.getHours(), now.getMinutes(), now.getSeconds());
            setStartTime(newTime);
            updateDateTime(startDate, newTime, onChangeStartDate);
        }
    };
    
    const handleEndTimeBlur = () => {
        const now = new Date();
        const combinedTime = new Date(endDate || now);
        combinedTime.setHours(endTime?.getHours() || 0, endTime?.getMinutes() || 0);
    
        if (endDate && isToday(endDate) && combinedTime < now) {
            const newTime = new Date(1970, 0, 1, now.getHours(), now.getMinutes(), now.getSeconds());
            setEndTime(newTime);
            updateDateTime(endDate, newTime, onChangeEndDate);
        }
    };
    

    const applyDateMask = (value: string): string => {
        const numericValue = value.replace(/\D/g, '');

        const limitedValue = numericValue.substring(0, 8);

        const day = limitedValue.substring(0, 2);
        const month = limitedValue.substring(2, 4);
        const year = limitedValue.substring(4, 8);

        let maskedValue = day;
        if (month) maskedValue += `/${month}`;
        if (year) maskedValue += `/${year}`;

        return maskedValue;
    };

    const handleManualStartDateChange = (event: any) => {
        const inputValue = event?.target?.value || '';
        const maskedValue = applyDateMask(inputValue);
        event.target.value = maskedValue;
    
        if (maskedValue.length === 10) {
            const parsedDate = parseInputDate(maskedValue);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
    
            if (parsedDate && parsedDate < currentDate) {
                setError("A data de início não pode ser inferior à data atual.");
                setStartDate(currentDate);
                event.target.value = formatDate(currentDate);
                updateDateTime(currentDate, startTime, onChangeStartDate);
            } else if (parsedDate) {
                setStartDate(parsedDate);
                updateDateTime(parsedDate, startTime, onChangeStartDate);
            } 
        }
    };
    
    const handleManualEndDateChange = (event: any) => {
        const inputValue = event?.target?.value || '';
        const maskedValue = applyDateMask(inputValue);
        event.target.value = maskedValue;
    
        if (maskedValue.length === 10) {
            const parsedDate = parseInputDate(maskedValue);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
    
            if (parsedDate && parsedDate < currentDate) {
                setError("A data de fim não pode ser inferior à data atual.");
                setEndDate(currentDate);
                event.target.value = formatDate(currentDate);
                handleEndDateChange(currentDate);
            } else if (parsedDate && startDate && parsedDate < startDate) {
                setError("A data de fim não pode ser inferior à data de início.");
                setEndDate(startDate);
                event.target.value = formatDate(startDate);
                handleEndDateChange(startDate);
            } else if (parsedDate) {
                setEndDate(parsedDate);
                handleEndDateChange(parsedDate);
            }
        }
    };
    
    const handleStartDateBlur = () => {
        if (!startDate) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            setStartDate(currentDate);
            updateDateTime(currentDate, startTime, onChangeStartDate);
            if (startDateRef.current) {
                startDateRef.current.value = formatDate(currentDate);
            }
        } else if (startDate) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (startDate < currentDate) {
                setError("A data de início não pode ser inferior à data atual.");
                setStartDate(currentDate);
                updateDateTime(currentDate, startTime, onChangeStartDate);
                if (startDateRef.current) {
                    startDateRef.current.value = formatDate(currentDate);
                }
            }
        }
    };
    
    const handleEndDateBlur = () => {
        if (!endDate) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            setEndDate(currentDate);
            updateDateTime(currentDate, endTime, onChangeEndDate);
            if (endDateRef.current) {
                endDateRef.current.value = formatDate(currentDate);
            }
        } else {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            if (endDate < currentDate) {
                setError("A data de fim não pode ser inferior à data atual.");
                setEndDate(currentDate);
                updateDateTime(currentDate, endTime, onChangeEndDate);
                if (endDateRef.current) {
                    endDateRef.current.value = formatDate(currentDate);
                }
            }
        }
    };
    
    
    const parseInputDate = (input: string): Date | null => {
        const [day, month, year] = input.split("/").map(Number);
        if (!day || !month || !year) return null;
        const parsedDate = new Date(year, month - 1, day);
        if (isNaN(parsedDate.getTime())) return null;
        return parsedDate;
    };

    const formatDate = (date: Date): string => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };


    const updateDateTime = (date: Date | null, time: Date | null, callback: (value: string) => void) => {
        if (!date || !time) {
            callback("");
            return;
        }
    
        const combined = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes(),
            0
        );
    
        const formatted = `${combined.getFullYear()}-${String(combined.getMonth() + 1).padStart(2, "0")}-${String(combined.getDate()).padStart(2, "0")} ${String(combined.getHours()).padStart(2, "0")}:${String(combined.getMinutes()).padStart(2, "0")}:${String(combined.getSeconds()).padStart(2, "0")}`;
        callback(formatted);
    };
    

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(null);
            }, 4000);
    
            return () => clearTimeout(timer);
        }
    }, [error]);
    


    return (
        <StyledPeriodContainer>
            <h3 dangerouslySetInnerHTML={{ __html: title_message }} />
            {error && <p style={{ color: 'red', marginBottom: '16px'  }}>{error}</p>}

            <StyledPeriodFieldGroup>

                <StyledPeriodField>
                    <span>Início</span>
                    <StyledDatePicker>
                        <CalendarIconStyle
                            onClick={() => {
                                setStartDateOpen(true);
                                startDateRef.current?.focus();
                            }}
                        >
                            <img src={CalendarIcon} alt="Calendar Icon" />
                        </CalendarIconStyle>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => handleStartDateChange(date)}
                            onChangeRaw={handleManualStartDateChange}
                            onBlur={handleStartDateBlur}
                            minDate={new Date()}
                            onClickOutside={() => setStartDateOpen(false)}
                            open={startDateOpen}
                            dateFormat="dd/MM/yyyy"
                            locale={ptBR}
                            customInput={
                                <CustomDateInput
                                    ref={startDateRef}
                                    onFocus={() => setStartDateOpen(true)}
                                />
                            }
                        />
                    </StyledDatePicker>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <StyledTimePicker>
                            <ClockIconStyle onClick={() => setStartTimeOpen(true)}>
                                <img src={ClockIcon} alt="Clock Icon" />
                            </ClockIconStyle>
                            <TimePicker
                                value={startTime}
                                onChange={handleStartTimeChange}
                                open={startTimeOpen}
                                onOpen={() => setStartTimeOpen(true)}
                                onClose={() => setStartTimeOpen(false)}
                                minTime={startDate && isToday(startDate) ? new Date() : new Date(1970, 0, 1, 0, 0)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onBlur={handleStartTimeBlur}
                                        InputProps={{ ...params.InputProps, endAdornment: null }}
                                    />
                                )}
                            />
                        </StyledTimePicker>
                    </LocalizationProvider>
                </StyledPeriodField>

                <StyledPeriodDivider>
                    <img src={ArrowRightIcon} alt="Arrow Right Icon" />
                </StyledPeriodDivider>

                <StyledPeriodField>
                    <span>Fim</span>
                    <StyledDatePicker>
                        <CalendarIconStyle
                            onClick={() => {
                                setEndDateOpen(true);
                                endDateRef.current?.focus();
                            }}
                        >
                            <img src={CalendarIcon} alt="Calendar Icon" />
                        </CalendarIconStyle>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => handleEndDateChange(date)}
                            onChangeRaw={handleManualEndDateChange}
                            onBlur={handleEndDateBlur}
                            minDate={new Date()}
                            onClickOutside={() => setEndDateOpen(false)}
                            open={endDateOpen}
                            dateFormat="dd/MM/yyyy"
                            locale={ptBR}
                            customInput={
                                <CustomDateInput
                                    ref={endDateRef}
                                    onFocus={() => setEndDateOpen(true)}
                                />
                            }
                        />
                    </StyledDatePicker>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                        <StyledTimePicker>
                            <ClockIconStyle onClick={() => setEndTimeOpen(true)}>
                                <img src={ClockIcon} alt="Clock Icon" />
                            </ClockIconStyle>
                            <TimePicker
                                value={endTime}
                                onChange={handleEndTimeChange}
                                open={endTimeOpen}
                                onOpen={() => setEndTimeOpen(true)}
                                onClose={() => setEndTimeOpen(false)}
                                minTime={endDate && isToday(endDate) ? new Date() : new Date(1970, 0, 1, 0, 0)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onBlur={handleEndTimeBlur}
                                        InputProps={{ ...params.InputProps, endAdornment: null }}
                                    />
                                )}
                            />
                        </StyledTimePicker>
                    </LocalizationProvider>
                </StyledPeriodField>

            </StyledPeriodFieldGroup>
        </StyledPeriodContainer>
    );

};
