import styled from "styled-components";

export const Container = styled.div`
    width: 384px;
    span {
        color: ${(props) => props.theme.colors.input};
        display: block;
        text-align: center;
    }
`;
