import { Box } from "alisson-application";
import { Container as Button } from "@/presentation/components/Button";
import { DrafEditor } from "@/presentation/components/Editor";
import { useModal } from "@/main/contexts/modal";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";

import { FormFiles } from "./file";
import { FormList } from "./list";
import { validationLogin, values, Values } from "./config";
import { Text } from "alisson-application";

import imgFechar from "@/presentation/assets/icone/close.svg";

import { Container } from "./styles";

export function FormAddGroup() {
    const { handleClose } = useModal();
    const { handleCreateGroup } = useDetailActionVoucher();

    const regex =
        /(?:|[^"]*[;\s])(background-color\s*:[^";]*)|(?:|[^"]*[;\s])(background\s*:[^";]*)/g;
    return (
        <Container>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={values}
                validationSchema={validationLogin}
                onSubmit={async (values, actions) =>
                    await handleCreateGroup(values, actions)
                }
            >
                {(props: FormikProps<Values>) => {
                    const { setFieldValue } = props;
                    return (
                        <Form>
                            <div className="container-input">
                                <Text as="h5" mb="10px">
                                    Nome do grupo
                                </Text>
                                <Field
                                    type="text"
                                    placeholder="Nome do grupo"
                                    name="name"
                                />
                                <ErrorMessage name="name" component="span" />
                            </div>

                            <div className="container-input">
                                <Text as="h5" mb="10px">
                                    Legenda
                                </Text>
                                <Field
                                    type="text"
                                    placeholder="Legenda"
                                    name="name_description"
                                />
                            </div>

                            <Field component={FormFiles} />
                            <Field component={FormList} />
                            <ErrorMessage
                                name="product_configuration_ids"
                                component="span"
                            />

                            <div className="container-input saiba-mais">
                                <DrafEditor
                                    title="Saiba mais"
                                    testid="know_more_text"
                                    onChange={(values) => {
                                        const html = values
                                            .trim()
                                            .replace(regex, "");

                                        if (
                                            html.replace(/<.*?>/g, "").trim()
                                                .length === 0
                                        ) {
                                            setFieldValue("know_more_text", "");
                                        } else {
                                            setFieldValue(
                                                "know_more_text",
                                                html
                                            );
                                        }
                                    }}
                                />
                            </div>

                            <button
                                onClick={() => handleClose("createGroup")}
                                className="btn-close"
                            >
                                <img src={imgFechar} alt="" />
                            </button>

                            <Box m="32px auto" width={383}>
                                <Button
                                    disabled={!props.isValid}
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                >
                                    Criar grupo
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
