import { Text, Flex } from "alisson-application";
import { FormActionCustomVideo } from "./form";
import { useDetailActionCustumize } from "@/presentation/components/DetailContent/hooks/detailActionCustumize";
import imgResgate from "@/presentation/assets/icone/resgate.svg";
import imgOlhoRoxo from "@/presentation/assets/icone/olho-roxo.svg";
import imgSetaEsqRoxo from "@/presentation/assets/icone/seta-esq-roxo.svg";
import imgSave from "@/presentation/assets/icone/save.svg";
import { Container } from "./styles";
export function ActionCustomVideo() {
    const { updateTab } = useDetailActionCustumize();
    return (
        <Container>
            <div className="container-header">
                <Flex alignItems="center" mb="19px">
                    <img src={imgResgate} alt="" />
                    <Text as="h1" ml="21px">
                        Tela de video
                    </Text>
                </Flex>

                <Flex>
                    <img src={imgOlhoRoxo} alt="" />
                    <Text as="h5" ml="21px">
                        Ver modelo de página
                    </Text>
                </Flex>
            </div>

            <div className="container-form">
                <FormActionCustomVideo />
            </div>

            <div className="container-actions-form">
                <button onClick={() => updateTab("personalização")}>
                    <Flex alignItems="center" mr="20px">
                        <img src={imgSetaEsqRoxo} alt="" />
                        <Text as="h5" ml="6px">
                            Voltar
                        </Text>
                    </Flex>
                </button>

                <button>
                    <Flex alignItems="center">
                        <img src={imgSave} alt="" />
                        <Text as="h5" ml="4px">
                            Ver modelo de página
                        </Text>
                    </Flex>
                </button>
            </div>
        </Container>
    );
}
