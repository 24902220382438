import { useState } from "react";
import { StorageLocation } from "@/infra/cache/storageLocation";
import { Text } from "alisson-application";
import { Modal } from "@/presentation/components/Modal";
import { Container } from "./styles";
import { When } from "../when";

interface ModalInterceptorProps {
    status: number;
    setStatus: (status: number | null) => void;
    path: string | null;
    mesage: string | null;
}

export function ModalInterceptor(props: ModalInterceptorProps) {
    const { status, setStatus, path, mesage } = props;
    const storage = new StorageLocation();
    const [isOpen, setIsOpen] = useState(true);

    function handelClose() {
        if (status === 401) {
            storage.clear();
            return;
        }

        setIsOpen(false);
        setStatus(null);
    }

    return (
        <Modal open={isOpen} handleClose={handelClose}>
            <Container>
                <Text as="h1" color="failure">
                    Erro
                </Text>

                <When expr={path === "login"}>
                    <Text as="p" color="secondarydark">
                        Dados incorretos ou senha inválida
                    </Text>
                </When>

                <When expr={path !== "login"}>
                    <>
                        <Text as="p" color="secondarydark">
                            {mesage ? (
                                mesage
                            ) : (
                                <Text as="p" color="secondarydark" mb="16px">
                                    {status === 401
                                        ? "Sessão expirada, faça login novamente."
                                        : "Ocorreu um erro inesperado, tente novamente mais tarde."}
                                </Text>
                            )}
                        </Text>
                    </>
                </When>
            </Container>
        </Modal>
    );
}
