import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusReport, Status } from "@/presentation/components/StatusReport";
import { useDetailActionReport } from "@/presentation/components/DetailContent/hooks/detailActionReport";
import Table from "@/presentation/components/ActionTable";
import { BtnDowload } from "./btnDowload";
import { Container } from "./styles";
import { Loader } from "@/presentation/components/Loader";

export function DetailActionReport() {
    const { reports, loading } = useDetailActionReport();
    const width = 1080 / 4.76;

    const dados = reports.map((report) => {
        return {
            id: report.id,
            data: report.date,
            nome: report.name,
            status: report.status,
            acesso: report.link,
            viewed: report.viewed,
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "Id",
            width,
        },
        {
            field: "data",
            headerName: "Data",
            width,
        },
        {
            field: "nome",
            headerName: "Nome",
            width,
        },
        {
            field: "status",
            headerName: "Status",
            renderCell: ({ row }: GridRenderCellParams) => {
                let status: Status;

                switch (row.status) {
                    case 1:
                        status = "Concluído";
                        break;
                    case 9:
                        status = "Erro";
                        break;
                    case 0:
                        status = "Processando";
                        break;
                    default:
                        status = "Erro";
                        break;
                }

                return <StatusReport status={status} />;
            },
            width,
        },
        {
            field: "acesso",
            headerName: "Acesso",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <BtnDowload reportId={row.id} viewed={row.viewed} />
                );
            },
            width,
        },
    ];

    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    return (
        <Container>
            <Table
                rows={dados}
                columns={columns}
                nameCSV="csvName"
                header={false}
            />
        </Container>
    );
}
