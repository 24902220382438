import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 20px;
    .container-cards-dashboard {
        background: #FFFFFF;
        border-radius: 8px;
        width: 430px;
        height: 283px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 101px;
    }

    p {
        font-weight: 900;
        font-size: 24px;
        line-height: 38px;
        color: ${props => props.theme.colors.primary};
    }
;`