import { ActionProductsGroupParams } from "@/domain/models";
import * as Yup from "yup";

export type Values = Omit<ActionProductsGroupParams, "action_id">;

export const values: Values = {
    name: "",
    img: null,
    product_configuration_ids: [],
    name_description: "",
};

export const validationLogin = Yup.object().shape({
    name: Yup.string()
        .min(4, "Nome deve ter no mínimo de 4 caracteres")
        .required("Campo obrigatório"),
    product_configuration_ids: Yup.array()
        .of(Yup.number())
        .required("O ID é obrigatório"),
    // img: Yup.string().required("Campo obrigatório"),
    img: Yup.mixed()
        .test(
            "fileType",
            "Somente são permitidas imagens JPEG, PNG ou GIF",
            (value) => {
                if (!value) {
                    return true; // permite campos vazios ou nulos
                }

                const validExtensions = ["jpg", "jpeg", "png", "gif"];
                const fileExtension = value.name
                    .split(".")
                    .pop()
                    ?.toLowerCase();
                return (
                    fileExtension !== undefined &&
                    validExtensions.includes(fileExtension)
                );
            }
        )
        .required("Campo obrigatório"),
});
