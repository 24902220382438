import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { ActionProductsGroupUpdateParams } from "@/domain/models";

import { ActionProductsGroupUpdate } from "@/domain/useCases";
export type keyOfActionProductsCustomize<T> = keyof T;

export class RemoteActionProductsGroupUpdate
    implements ActionProductsGroupUpdate
{
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlUpdateGroup: string = "action/products/groups/edit"
    ) {}

    async updateGroup(params: ActionProductsGroupUpdateParams): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlUpdateGroup,
            body: this.formatUpdateGroup(params),
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }

    formatUpdateGroup(params: ActionProductsGroupUpdateParams): FormData {
        const formData = new FormData();
        const removeKeys = ["img", "product_configuration_ids"];
        const keys = Object.keys(
            params
        ) as keyOfActionProductsCustomize<ActionProductsGroupUpdateParams>[];

        keys.forEach((key) => {
            if (!removeKeys.includes(key)) {
                formData.append(key, params[key] as string);
            }
        });

        formData.append(
            `product_configuration_ids`,
            JSON.stringify(params.product_configuration_ids)
        );

        if (params.img) {
            formData.append("img", params.img);
        }

        return formData;
    }
}
