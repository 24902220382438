import React, { useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { usePermissions } from "../../../contexts/permissions";

import NewHeader from "../../../../presentation/components/NewHeader";
import styles from "./validateAuthenticator.module.css";
import Shield from "../../../../presentation/assets/icone/login/shield.svg";

const ValidateAuthenticator = () => {
  const { hasPermission } = usePermissions();
  const history = useHistory();
  const tokenJSON = localStorage.getItem('access_token');
  const token = tokenJSON ? JSON.parse(tokenJSON).access_token : null;
  const apiUrl = process.env.REACT_APP_BASE_HTTP;
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setHasError(false);
    const code = Array.from(inputRef.current).map(input => input.value).join('');
    console.log('handleSubmit Pressionado code:', code);
    console.log('API URL:', apiUrl);
    
    try {
      const response = await fetch(`${apiUrl}google2fa/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ code })
      });
      
      const data = await response.json();
      setIsLoading(false);
      
      if (response.ok) {
        console.log("Código validado com sucesso!", data);
        setHasError(false);
        setIsLoading(false);
        if (hasPermission("api/action/create", "POST")) {
            history.push("/dashboard");
        } else {
            history.push("/acoes");
        }
      } else {
        setHasError(true);
        setIsLoading(false);
        throw new Error(data.message || 'Erro ao validar o código');
      }
    } catch (error) {
      console.error("Falha ao validar código:", error);
    }
  };

  const inputRef = useRef([]);

  const handlePaste = (event) => {
    let paste = event.clipboardData.getData('text');
    paste = paste.slice(0, inputRef.current.length);

    event.preventDefault();

    paste.split('').forEach((char, index) => {
      const input = inputRef.current[index];
      if (input) {
        input.value = char;
        if (inputRef.current[index + 1]) {
          inputRef.current[index + 1].focus();
        }
      }
    });
  };

  const handleInputChange = (index) => (event) => {
    if (event.target.value) {
      if (inputRef.current[index + 1]) {
        inputRef.current[index + 1].focus();
      }
    } else if (event.target.value === '') {
      if (inputRef.current[index - 1]) {
        inputRef.current[index - 1].focus();
      }
    }
  };

  const handleKeyDown = (index) => (event) => {
    if (event.key === 'Backspace' && !event.target.value && index > 0) {
      inputRef.current[index - 1].focus();
    }
  };

  return (
    <div className={styles.generalContainer}>
      <div className={styles.insiderContainer}>
        <NewHeader />
        <div className={styles.shieldContainer}>
          <img src={Shield} alt="Shield" />
        </div>
        <p className={styles.title}>Verificação <br /> de segurança</p>
        <p className={styles.subtitle}>Para concluir o acesso, abra seu
          <span className={styles.highlightedText}> App Google <br /> Authenticator</span> e digite o código de 6 digitos exibido.</p>
        <p className={styles.label}>Código de Verificação</p>
        {hasError && <p className={styles.errorText}>Verifique seu código e tente novamente</p>}
        <div className={styles.codeInputs}>
          {Array.from({ length: 6 }).map((_, index) => (
            <input
              key={index}
              ref={(el) => (inputRef.current[index] = el)}
              className={`${styles.inputSegmentado} ${hasError ? styles.errorInput : ''}`}
              type="text"
              maxLength="1"
              onChange={handleInputChange(index)}
              onKeyDown={handleKeyDown(index)}
              onFocus={(e) => {
                e.target.select();
                setHasError(false);
              }}
              onPaste={handlePaste}
            />
          ))}
        </div>

        <button onClick={handleSubmit} className={styles.button}>
          {isLoading ? "Carregando..." : "Validar Acesso"}
        </button>
      </div>
    </div>

  );
};

export default ValidateAuthenticator;