import { Flex, Text } from "alisson-application";
import { useDetailAction } from "@/main/hooks/detailAction";
import imgUser from "@/presentation/assets/icone/user.svg";
// import imgLinkVoucher from "@/presentation/assets/icone/link-voucher.svg";

export function DetalheUser() {
    const { findActionData } = useDetailAction();

    return (
        <>
            {/* <Text as="p" color="failure">
                Falta retorna
            </Text>
            <Flex className="info-arq" mb="17px">
                <Text as="span" color="secondarydark" fontSize="xs">
                    Arquivo de usuários
                </Text>
                <Text as="h5" ml="9px">
                    lista_de_usuarios.xls
                </Text>
            </Flex> */}

            <Flex>
                <Flex className="info-arq" alignItems="center" mr="28px">
                    <Text
                        as="span"
                        color="secondarydark"
                        fontSize="xs"
                        mr="7px"
                    >
                        Total de usuários
                    </Text>

                    <img src={imgUser} alt="" />

                    <Text as="h5" ml="9px" fontSize="md">
                        {findActionData?.detailed?.quantity_of_users} usuários
                    </Text>
                </Flex>
            </Flex>
        </>
    );
}
