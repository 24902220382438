import styled from "styled-components";

export const Container = styled.div`
    overflow: hidden;
    height: 0px;
    margin-top: 0px;
    transition: all 0.5s;
    &.active {
        margin-top: 60px;
        transition: all 0.5s;
    }
    .container-table {
        width: 920px;
        margin-left: 197px;
        position: relative;
        .MuiBox-root {
            padding-top: 0px;
        }
        .MuiDataGrid-footerContainer {
            .MuiDataGrid-rowCount {
                display: none;
            }
        }
    }
`;
