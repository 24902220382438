import styled from "styled-components";

export const StyledPeriodContainer = styled.div`
margin-top: 20px;
margin-left: 40px;
    background-color: #f3f3f3;
    border-radius: 12px;
    padding: 20px;
    max-width: 655px;
    height: 334px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h3 {
        color: #6d1d92;
        font-size: 16px;
        margin-bottom: 30px;

        font-family: Lato;
        font-weight: 700;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }
`;


export const StyledPeriodFieldGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

export const StyledPeriodField = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
        font-size: 14px;
        color: #6d1d92;
        margin-bottom: 10px;

        font-family: Lato;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        
        align-self: flex-start;
        
        text-align: left; 
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
`;


export const StyledPeriodDivider = styled.div`
    margin-top: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6d1d92;
    font-size: 24px;
`;

export const StyledDatePicker = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #5E5F5F;
    border-radius: 25px;
    background-color: white;
    height: 40px;
    width: 183px;

    .calendar-icon {
        margin-left: 16px;
        color: #6F3E98;
    }

    input {
        border: none !important;
        outline: none;
        font-size: 14px;
        width: 100%;
        background-color: transparent;
    }
`;

export const StyledTimePicker = styled.div`
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #5E5F5F;
    border-radius: 25px;
    background-color: white;
    height: 40px;
    width: 183px;

    .MuiOutlinedInput-root {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        font-size: 14px;
        background-color: transparent;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .MuiSvgIcon-root {
        color: #6F3E98;
        margin-right: 20px;
    }

    input {
        margin-left: 15px;
        border: none !important;
        
    }

`;
export const CalendarIconStyle = styled.div`
    margin-left: 18px;
    cursor: pointer;
`;
export const ClockIconStyle = styled.div`
    margin-left: 17px;
    margin-top: 3px;
    cursor: pointer;
`;
