import { useState } from "react";
import { Text, Box } from "alisson-application";
import { v4 as uuidv4 } from "uuid";
import { FieldProps } from "formik";

import imgLogo from "@/presentation/assets/icone/logo-vazia.jpg";
import close from "@/presentation/assets/icone/close-branco.svg";
import { useModal } from "@/main/contexts/modal";
import { Container as Button } from "@/presentation/components/Button";
import { useDetailActionCustumize } from "../DetailContent/hooks/detailActionCustumize";
import { Url } from "@/services/generateValidUrl/generateValidUrl";

export function Logo2(props: FieldProps) {
    const { form } = props;
    const { values } = form;
    const [file, setFile] = useState<File | null>(null);
    const labelId = "file-" + uuidv4();
    const { handleChangeFile, deleteLogo } = useDetailActionCustumize();

    const url = new Url();
    const hasImg = url.isValidURL(values.secondary_logo);

    const { setState, state } = useModal();

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const currentFile = e.target.files?.[0];

        if (currentFile) {
            const img = new Image();
            img.src = URL.createObjectURL(currentFile);
            img.onload = () => {
                setFile(currentFile);
                handleChangeFile(currentFile, "secondary_logo");
            };
        }
    }

    function removeImagem() {
        deleteLogo("secondary_logo");
    }

    return (
        <div className="container-logo">
            <Text as="h5">Logo 2</Text>
            <Box className="container-img" background={`${values.background}`}>
                <img
                    src={
                        file?.name
                            ? URL.createObjectURL(file)
                            : hasImg
                            ? values.secondary_logo
                            : imgLogo
                    }
                    alt=""
                />
                <img
                    className="removeImagem"
                    src={close}
                    alt=""
                    title="Excluir imagem"
                    onClick={async () => {
                        setState({
                            ...state,
                            alert: {
                                active: true,
                                onclick: () => {
                                    removeImagem();
                                },
                                text: "A imagem será excluída, deseja continuar?",
                            },
                        });
                    }}
                />
            </Box>

            <label htmlFor={labelId} className="container-text">
                <Button>Upload</Button>
            </label>

            <input
                type="file"
                name={labelId}
                id={labelId}
                accept="image/*"
                onChange={(event) => {
                    setState({
                        ...state,
                        alert: {
                            active: true,
                            onclick: () => handleChange(event),
                            text: "A imagem será modificada, deseja continuar?",
                        },
                    });
                }}
            />
            <span>
                O logo deve estar nos formatos PNG ou SVG, com as proporções de
                160x90px.
            </span>
        </div>
    );
}
