import styled from "styled-components";

export const Container = styled.div`
    padding: 32px;
    text-align: center;
    width: 450px;
    h1 {
        margin-bottom: 32px;
    }

    p {
        text-transform: unset;
    }
`;
