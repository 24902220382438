import Page from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
    GridColumns,
    GridRowsProp,
    GridRowParams,
    GridRowClassNameParams,
    useGridApiRef,
    GridState,
} from "@mui/x-data-grid-pro";
import { useDemoData } from "@mui/x-data-grid-generator";
import { translateHeaderTable } from "./config";
import { Toolbar } from "./toolbar";
import {
    AntDesignStyledDataGridPro,
    StyledBox,
    PaginationStyles,
} from "./styles";

interface TableProProps {
    columns: GridColumns;
    rows: GridRowsProp;
    onRowClick?: (value: GridRowParams) => void;
    handleState?: (value: GridState) => void;
    nameCSV: string;
    checkboxSelection?: boolean;
    header?: boolean;
}

export default function TablePro(props: TableProProps) {
    const { columns, rows, onRowClick, header = true } = props;
    const apiRef = useGridApiRef();
    const { loading } = useDemoData({
        dataSet: "Commodity",
        rowLength: 100,
        editable: false,
    });

    const components = header
        ? {
              Toolbar,
          }
        : {};

    return (
        <StyledBox>
            <AntDesignStyledDataGridPro
                checkboxSelection={props.checkboxSelection}
                className={onRowClick ? "clickable" : ""}
                columns={columns}
                rows={rows}
                rowHeight={72}
                components={components}
                getRowClassName={(params: GridRowClassNameParams) => {
                    return params.row.tipo || "";
                }}
                localeText={{
                    ...translateHeaderTable,
                }}
                loading={loading}
                autoHeight={true}
                disableSelectionOnClick
                rowThreshold={0}
                apiRef={apiRef}
                onStateChange={
                    props.handleState ? props.handleState : undefined
                }
                filterMode="client"
                onRowClick={(values: GridRowParams, event: any) => {
                    const hasButton = (
                        event.target as Element
                    ).tagName.includes("BUTTON");

                    if (onRowClick) {
                        if (!hasButton) {
                            onRowClick(values);
                        }
                    }
                }}
            />
        </StyledBox>
    );
}

interface PaginationProps {
    total: number;
    currentPage: (page: number) => void;
}

export function Pagination(pros: PaginationProps) {
    const { total, currentPage } = pros;

    return (
        <PaginationStyles>
            <Stack spacing={2}>
                <Page
                    count={total}
                    onChange={(event, page) => currentPage(page)}
                />
            </Stack>
        </PaginationStyles>
    );
}
