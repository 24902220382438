import { HttpStatusCode } from "@/data/protocols/http";

export class AccessDeniedError extends Error {
    status: HttpStatusCode;
    constructor() {
        super("Acesso negado!");
        this.name = "AccessDeniedError";
        this.status = HttpStatusCode.unauthorized;
    }
}
