import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

export class RemoteActionProductSoftDelete {
    constructor(protected readonly httpClient: AxiosHttpClient) {}

    async softDeleteProduct(params: { product_id: number }): Promise<void> {
        const response = await this.httpClient.request({
            url: `action/products/${params.product_id}`,
            method: 'delete',
        });

        if (response.statusCode !== 200) {
            throw new Error('Erro ao deletar o produto');
        }
    }
}
