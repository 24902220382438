import React, { createContext, useContext, useState, useEffect } from "react";
import { UserADMModel } from "@/domain/models";
import { ListUsers } from "@/domain/useCases/listADMUser";

interface UserContextType {
    users: UserADMModel[];
    loading: boolean;
    loadUsers: (page?: number) => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext deve ser usado dentro de um UserProvider");
    }
    return context;
};

interface UserProviderProps {
    listUsers: ListUsers;
    children: React.ReactNode;
}

export const UseUserProvider: React.FC<UserProviderProps> = ({ listUsers, children }) => {
    const [users, setUsers] = useState<UserADMModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const loadUsers = async (page?: number) => {
        setLoading(true);
        try {
            const data = await listUsers.loadAll(page);
            setUsers(data);
        } catch (error) {
            console.error("Erro ao carregar usuários:", error);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                console.log("Carregando usuários na montagem do componente.");
                await loadUsers();
                console.log("Usuários carregados com sucesso.");
            } catch (error) {
                console.error("Erro ao carregar usuários:", error);
            }
        };
    
        fetchUsers();
    }, []);
    

    return (
        <UserContext.Provider value={{ users, loading, loadUsers }}>
            {children}
        </UserContext.Provider>
    );
};
