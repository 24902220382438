import { Header } from "@/presentation/components/Header";
import { ButtonGoback } from "@/presentation/components/ButtonGoback";
import { HeaderDetailAction } from "@/presentation/components/HeaderDetailAction";
import { TabDetailActions } from "@/presentation/components/TabDetailActions";
import { DetailContent } from "@/presentation/components/DetailContent";
import { Modal } from "@/presentation/components/Modal";
import { useModal } from "@/main/contexts/modal";

import imgIconeAction from "@/presentation/assets/icone/detalhe-acao.svg";

import { useDetailAction } from "@/main/hooks/detailAction";

import { Container } from "./styles";
import { Loader } from "../Loader";
import { ModalReport } from "@/presentation/components/ModalReport";
import { HeaderToggleDetailVoucher } from "../HeaderToggleDetailVoucher";

export function DetailAction() {
    const { loading, findActionData } = useDetailAction();
    const { state, handleClose } = useModal();
    if (loading) {
        return <Loader />;
    }

    return (
        <Container>
            <Header
                icone={imgIconeAction}
                title="DETALHE DA AÇÃO"
                subTitle={findActionData.name}
            >
                <ButtonGoback />
            </Header>

            <HeaderDetailAction />

            <HeaderToggleDetailVoucher />

            <TabDetailActions />

            <DetailContent />

            <Modal
                open={state?.report}
                handleClose={() => handleClose("report")}
            >
                <ModalReport />
            </Modal>
        </Container>
    );
}
