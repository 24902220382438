import React, { ChangeEvent, createContext, useContext, useState } from "react";
import { useDetailAction } from "@/main/hooks/detailAction";
import { Custumize } from "@/data/useCases/custumize/custumize";
import { ActionCustumizeParams } from "@/domain/models";
import { toast } from "react-toastify";

interface UseDetailActionCustumizeProps {
    children: React.ReactNode | React.ReactNode[];
}

export interface Tabs {
    id: number;
    title: string;
    className: string;
    active: boolean;
    viewer: boolean;
    permission: boolean;
}

export type TabId =
    | "Tela de login"
    | "Tela de video"
    | "Tela escolha do prêmio"
    | "Tela resgate"
    | "personalização";

export interface UseStateDetailActionCustumize {
    tabs: Tabs[];
    setTabs: (tabs: Tabs[]) => void;
    updateTab(id: TabId): void;

    custumize: Custumize;
    handleChange(event: ChangeEvent<HTMLInputElement>): Promise<void>;
    handleChangeEditor(name: any, value: any): Promise<void>;
    handleChangeFile(
        file: File,
        name: keyOfActionProductsCustomize<ActionCustumizeParams>
    ): Promise<void>;

    deleteLogo(name: any): Promise<void>;

    updateTelaLogin(values: any): Promise<void>;
    updateTelaVideo(values: any): Promise<void>;
    updatePremioResgate(values: any): Promise<void>;

    loading: boolean;
}

const itens = [
    {
        id: 1,
        title: "personalização",
        className: "",
        active: true,
        viewer: true,
        permission: true,
    },
    {
        id: 2,
        title: "Tela de login",
        className: "acesso_e_resgate",
        active: false,
        viewer: false,
        permission: false,
    },
    {
        id: 3,
        title: "Tela escolha do prêmio",
        className: "revisao_e_aprovacao",
        active: false,
        viewer: false,
        permission: false,
    },
    {
        id: 4,
        title: "Tela resgate",
        className: "revisao_e_aprovacao",
        active: false,
        viewer: false,
        permission: false,
    },
    {
        id: 5,
        title: "Tela de video",
        className: "revisao_e_aprovacao",
        active: false,
        viewer: false,
        permission: false,
    },
];

const Context = createContext<UseStateDetailActionCustumize>(
    {} as UseStateDetailActionCustumize
);

export type keyOfActionProductsCustomize<T> = keyof T;
export function UseDetailActionCustumizeProvider(
    props: UseDetailActionCustumizeProps
) {
    const { children } = props;
    const [tabs, setTabs] = useState<Tabs[]>(itens);
    const { custumize, stateHistory, setFindActionData } = useDetailAction();
    const [loading, setLoading] = useState(false);

    function updateTab(id: TabId) {
        const update = tabs.map((tab) => {
            if (tab.title === id) {
                return {
                    ...tab,
                    active: true,
                    viewer: true,
                    permission: true,
                };
            }

            return {
                ...tab,
                active: false,
                viewer: tab.viewer,
                permission: false,
            };
        });

        setTabs(update);
    }

    async function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        let value: string | number = event.target.value;
        const name = event.target.getAttribute(
            "name"
        ) as keyOfActionProductsCustomize<ActionCustumizeParams>;

        const regex = /^-?\d+$/;
        if (regex.test(value)) {
            value = parseInt(value);
        }

        try {
            target.parentElement?.setAttribute("data-loading", "true");
            setLoading(true);
            const response = await custumize.actionCustumize({
                [name]: value,
                id: stateHistory.id,
            });

            setFindActionData(response);
            toast.success("Atualizado com sucesso");
            target.parentElement?.removeAttribute("data-loading");
            setLoading(false);
        } catch (error) {
            console.log(error);
            target.parentElement?.removeAttribute("data-loading");
            setLoading(false);
        }
    }

    async function updateTelaLogin(values: any) {
        try {
            setLoading(true);

            const response = await custumize.actionCustumize({
                id: stateHistory.id,
                background: values.background,
                button_color: values.button_color,
                login_title: values.login_title,
                login_subtitle: values.login_subtitle,
                use_lgpd: values.use_lgpd,
                lgpd_text: values.lgpd_text,
                job: values.job,
                internal_policy: values.internal_policy,
                blocked_text: values.blocked_text,
                personalized_blocked_text: values.personalized_blocked_text,
                blocked_text_start_date: values.blocked_text_start_date,
                blocked_text_end_date: values.blocked_text_end_date,
                personalized_login_error_message: values.personalized_login_error_message,
                login_message_start_date: values.login_message_start_date,
                login_message_end_date: values.login_message_end_date,
            });

            setFindActionData(response);
            toast.success("Atualizado com sucesso");
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function updateTelaVideo(values: any) {
        try {
            setLoading(true);

            const response = await custumize.actionCustumize({
                id: stateHistory.id,
                use_video: values.use_video,
                video_required: values.video_required,
                video_title: values.video_title,
                video_subtitle: values.video_subtitle,
                video_link: values.video_link,
                video_button_to_rewards: values.video_button_to_rewards,
            });

            setFindActionData(response);
            toast.success("Atualizado com sucesso");
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function updatePremioResgate(values: any) {
        try {
            setLoading(true);

            const response = await custumize.actionCustumize({
                ...values,
                id: stateHistory.id,
            });

            setFindActionData(response);
            toast.success("Atualizado com sucesso");
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function handleChangeEditor(name: any, value: any) {
        const regex = /^-?\d+$/;
        if (regex.test(value)) {
            value = parseInt(value);
        }

        const d = document.getElementById(name);

        try {
            d?.parentElement?.parentElement?.setAttribute(
                "data-loading",
                "true"
            );
            setLoading(true);
            const response = await custumize.actionCustumize({
                [name]: value,
                id: stateHistory.id,
            });
            setFindActionData(response);
            toast.success("Atualizado com sucesso");
            d?.parentElement?.parentElement?.removeAttribute("data-loading");
            setLoading(false);
        } catch (error) {
            console.log(error);
            d?.parentElement?.parentElement?.removeAttribute("data-loading");
            setLoading(false);
        }
    }

    async function handleChangeFile(
        file: File,
        name: keyOfActionProductsCustomize<ActionCustumizeParams>
    ) {
        try {
            setLoading(true);
            const response = await custumize.actionCustumize({
                [name]: file,
                id: stateHistory.id,
            });

            setFindActionData(response);
            toast.success("Atualizado com sucesso");
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    async function deleteLogo(name: string) {
        try {
            setLoading(true);
            const response = await custumize.actionCustumize({
                unset: name,
                id: stateHistory.id,
            });

            setFindActionData(response);
            toast.success("Atualizado com sucesso");
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <Context.Provider
            value={{
                tabs,
                setTabs,
                updateTab,
                custumize,
                handleChange,
                handleChangeEditor,
                handleChangeFile,
                deleteLogo,
                updateTelaLogin,
                updateTelaVideo,
                updatePremioResgate,

                loading,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useDetailActionCustumize() {
    const context = useContext(Context);
    return context;
}
