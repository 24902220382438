import styled from "styled-components";

export const Container = styled.div`
    width: 592px;
    height: 294px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .container-header {
        margin-bottom: 37px;
        h1 {
            display: flex;
            align-items: center;
            img {
                display: block;
                margin-right: 12px;
            }
        }
    }

    button {
        &.btn-close {
            position: absolute;
            top: 30px;
            right: 30px;
        }
    }
`;
