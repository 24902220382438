import { CheckboxProps } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface CheckBoxProps extends CheckboxProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
}

export function CheckBox(props: CheckBoxProps) {
    const { onChange, checked, ...resto } = props;

    const theme = createTheme({
        palette: {
            primary: { main: "#6F3E98" },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Checkbox
                onChange={onChange}
                checked={checked}
                inputProps={{ "aria-label": "controlled" }}
                {...resto}
            />
        </ThemeProvider>
    );
}
