import { Mixin } from "ts-mixer";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { RemoteActionReportsNew } from "../actionReportsNew/remoteActionReportsNew";
import { RemoteActionReportsDownload } from "../actionReportsDownload/remoteActionReportsDownload";
import { RemoteDownloadReport } from "../actionDownloadReport/remoteDownloadReport";

const axios = new AxiosHttpClient();

export class Report extends Mixin(
    RemoteActionReportsNew,
    RemoteActionReportsDownload,
    RemoteDownloadReport
) {
    constructor() {
        super(axios);
    }
}
