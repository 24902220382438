import { Container as Button } from "@/presentation/components/Button";
import { Box } from "alisson-application";
import { Container } from "./styles";
export function SubHeaderNeedHelp() {
    return (
        <Container>
            <div className="container-search">
                <div className="container-input buscar">
                    <input
                        type="text"
                        placeholder="Busque por título ou descrição"
                    />
                </div>

                <Button>Buscar</Button>
            </div>

            <Box ml="auto" width={170}>
                <Button>Novo</Button>
            </Box>
        </Container>
    );
}
