import { Action } from "@/presentation/components/MyActions";
import { UseActionProvider } from "@/main/hooks/action";
import { RemoteListActions } from "@/data/useCases/listAction/remoteListAction";
import { RemoteDetailVoucher } from "@/data/useCases/detailVoucher/remoteDetailVoucher";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

export function PageAction() {
    const axios = new AxiosHttpClient();
    const action = new RemoteListActions(axios);
    const detailVoucher = new RemoteDetailVoucher(axios);

    return (
        <UseActionProvider action={action} detailVoucher={detailVoucher}>
            <Action />
        </UseActionProvider>
    );
}
