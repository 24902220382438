import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { ActionProductsAddModel } from "@/domain/models";
import {
    ActionProductsAdd,
    ActionProductsAddModelResponse,
} from "@/domain/useCases";

export class RemoteActionProductsAdd implements ActionProductsAdd {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlProductAdd: string = "action/products/add"
    ) {}

    async productAdd(
        params: ActionProductsAddModel
    ): Promise<ActionProductsAddModelResponse> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlProductAdd,
            body: params,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
}
