import { Header } from "@/presentation/components/Header";
import { ButtonGoback } from "@/presentation/components/ButtonGoback";
import { SubHeaderEditUsersADM } from "@/presentation/components/SubHeaderEditUsersADM";
import { TableEditUsersADM } from "@/presentation/components/TableEditUsersADM";

import { Container } from "./styles";
import GeneralUsersADMIcon from "@/presentation/assets/icone/general-users-adm-icon.svg";

export function EditUsersADM() {
    return (
        <Container>
            <Header icone={GeneralUsersADMIcon} title="USUÁRIOS ADM">
                <ButtonGoback />
            </Header>

            <SubHeaderEditUsersADM />

            <TableEditUsersADM />
        </Container>
    );
}
