import styled from "styled-components";

export const Container = styled.div`
    background: #f4f4f4;
    border-radius: 5px;
    padding: 57px 52px 57px 42px;
    display: flex;
    justify-content: space-between;
    width: 1112px;
    .dir {
        width: 436px;
        .descricao {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 81px;
            width: 100%;
            &:last-child {
                border-bottom: unset;
            }
            & > div {
                border-bottom: unset;
            }
        }
    }

    .descricao {
        width: 436px;
    }

    .block-config {
        width: 436px;
        p[color="primary"] {
            text-transform: unset !important;
        }

        .item {
            span {
                position: relative;
                padding-left: 25px;
                img {
                    position: absolute;
                    left: 0px;
                }
            }

            p {
                padding-left: 25px;
            }
        }
    }

    .block-config {
        border-top: 1px solid #9d9d9d;
        border-bottom: 1px solid #9d9d9d;
        padding: 20px 0px;
        span {
            display: flex;
            align-items: center;
            img {
                display: block;
                margin-right: 6px;
            }
        }
    }

    .toogle {
        width: 436px;
        padding-top: 20px;
        .item {
            &:last-child {
                margin-left: 21px;
            }
            span {
                display: flex;
                align-items: center;
                p {
                    margin-left: 8px;
                }
            }
        }
    }

    .show-date {
        width: 436px;
        height: 58px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #9d9d9d;
        .item {
            &:last-child {
                margin-left: 21px;
            }
            span {
                display: flex;
                align-items: center;
                p {
                    margin-left: 8px;
                }
            }
        }
    }
`;
