import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { actionUserResetParams } from "@/domain/models";
import { ActionUserReset } from "@/domain/useCases/actionUserReset";

export class RemoteActionUserReset implements ActionUserReset {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlUserReset: string = "action/users/reset"
    ) {}

    async reset(params: actionUserResetParams): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlUserReset,
            body: params,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
}
