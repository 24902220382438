import styled from "styled-components";

export const Container = styled.div`
    .container-input {
        &.dt_calendario {
            display: grid;
            align-items: center;
            grid-template-columns: 85px 1fr;
            .container-input {
                margin-bottom: 0px;
                width: 100%;
            }
        }
        h6 {
            display: flex;
            align-items: center;
            margin-right: 13px;
            img {
                display: block;
                margin-right: 8px;
                margin-bottom: -3px;
            }
        }
    }

    form {
        display: flex;
    }

    .esq {
        width: 324px;
        .container-select {
            display: flex;
            align-items: center;
            position: relative;
            div[data-testid="loading"] {
                top: 40%;
                right: -50px;
                left: unset;
            }
        }
    }

    .dir {
        margin-left: 137px;
        width: 384px;
    }
`;
