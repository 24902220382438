import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    margin-bottom: 12px;
    width: 100%;
    img {
        width: 38px;
        height: 32px;
    }
    .container-img{
        margin-top: 5px;
    }
    .card-voucher {
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        height: 162px;
        width: 100%;
        background-color: #f0f0f0;
        padding-top: 18px;
        border-radius: 5px;
        &.disabled {
            input {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
        input {
            font-style: normal;
            font-weight: 800;
            font-size: 35px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: ${(props) => props.theme.colors.primary};
            width: 41px;
            background-color: transparent;
            border: none;
            text-align: center;
        }

        h5,
        p {
            text-transform: unset;
        }

        p {
            margin-bottom: 8px;
        }

        button {
            display: flex;
            align-items: center;
            img {
                width: 15px;
                display: block;
                margin-right: 5px;
            }
        }
    }

    .container-action-voucher {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: flex-start;
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        background-color: #f0f0f0;
        padding-top: 27px;
        border-radius: 5px;
        .container-button {
            width: 260px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .container-button button:disabled {
            background-color: #E8E8E8 !important;
        }

        .container-button {
            position: relative;
        }
 
        .button-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            cursor: not-allowed;
        }

        .container-qtd-resgate {
            display: flex;
            align-items: center;
            margin-top: 0px;
            .container-input {
                width: 70px;
                margin-bottom: 0px;
                margin-left: 8px;
                display: flex;
                align-items: center;
                &:before {
                    right: 15px;
                }
                input {
                    height: 30px;
                    padding-left: 19px;
                    border: none;
                    background-color: #f0f0f0;
                }
            }
        }
    }
`;
