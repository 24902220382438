import styled from "styled-components";

export const Container = styled.div`
    position: relative;

    .container-header {
        margin-bottom: 56px;
        h1 {
            text-transform: unset;
        }
    }

    .container-form {
        form {
            /* width: 302px; */
            .container-input {
                margin-bottom: 22px;
            }
            /* button {
                display: none;
            } */
        }
    }

    .container-actions-form {
        display: flex;
        justify-content: space-between;
        width: 232px;
        position: absolute;
        top: 0px;
        right: 0px;
    }
`;

export const StylesDetailCustomForm = styled.div`
    width: 100%;
    transition: all.5s;
    &.disabled {
        filter: grayscale(1);
        transition: all.5s;
        position: relative;
        .container-itens {
            pointer-events: none;
        }

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            cursor: not-allowed;
        }
    }

    &.container-info {
        border-bottom: 1px solid rgb(157, 157, 157);
        margin-bottom: 29px;
        padding-bottom: 32px;
    }

    .container-itens {
        display: grid;
        grid-template-columns: 1fr 1fr 354px;
        grid-gap: 74px;
    }

    .container-input {
        margin-bottom: 16px;
        &.header-loagin {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 418px;
        }
        &.text-LGPD {
            transition: all.5s;
            position: relative;

            &.disabled {
                cursor: not-allowed;
                .container-draf {
                    filter: grayscale(1);
                    transition: all 0.5s;
                    pointer-events: none;
                    position: relative;

                    z-index: 10;
                    &::after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        z-index: 999;
                    }
                }
            }

            .container-draf {
                width: 101%;
                height: 100%;
                border-radius: 10px;
                transition: all 0.5s;
                z-index: 20;
            }
        }
        .btn-salvar {
            margin-top: 10px;
            width: 390px;
        }
    }

    .container-thumbnail {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        position: relative;
        left: 16px;
        .thumbnail {
            width: 186px;
            height: 105px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
            }
        }

        .button-upload {
            width: 147px;
            padding-top: 5px;
            span {
                font-family: "Lato";
                font-style: italic;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                display: flex;
                align-items: center;
                text-align: center;
                color: ${(props) => props.theme.colors.secondarydark};
            }
            button {
                pointer-events: none;
            }
        }
    }

    .btn-salvar-final {
        width: 302px;
        margin: 20px auto;
    }
`;
