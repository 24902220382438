import { Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Me } from "@/domain/models";
import { useApi } from "@/main/contexts/apiContext";
import { CardsMinhasAcoes } from "@/presentation/components/CardsMinhasAcoes";
import iconeNovasAcoes from "@/presentation/assets/icone/icon-minha-acao.svg";
import iconeMinhasAcoes from "@/presentation/assets/icone/icon-nova-acao.svg";
import iconSair from "@/presentation/assets/icone/icon-sair.svg";
import imgLogo from "@/presentation/assets/img/logo-branca.svg";

import { Container } from "./styles";
import { usePermissions } from "@/main/contexts/permissions";

export function Dashboard() {
    const { getStorage, clearStorage } = useApi();
    const user = getStorage<Me>("me");
    const Nome = user?.name;
    const history = useHistory();

    const { hasPermission } = usePermissions();

    return (
        <>
            <Container>
                <div className="container-global-dashboard container">
                    <div className="container-topo-logo">
                        <div className="container-topo">
                            <Box className="container-logo">
                                <img src={imgLogo} alt="" />
                            </Box>
                        </div>

                        <div className="container-topo-sair">
                            <p>Olá, {Nome}</p>
                            <button
                                className="container-sair"
                                onClick={() => clearStorage()}
                            >
                                <img src={iconSair} alt="" />
                                <p>Sair</p>
                            </button>
                        </div>
                    </div>

                    <div className="container-titulo">
                        <p>Como deseja prosseguir</p>
                    </div>

                    <div className="container-cards">
                        {hasPermission("api/action/allPaginated", "GET") ? (
                            <button
                                data-testid="minhas-acoes"
                                onClick={() => history.push("/acoes")}
                            >
                                <CardsMinhasAcoes
                                    path="/"
                                    src={iconeNovasAcoes}
                                    title="Minhas ações"
                                />
                            </button>
                        ) : null}

                        {hasPermission("api/action/create", "POST") ? (
                            <button
                                data-testid="minhas-acoes"
                                onClick={() => history.push("/nova/acao")}
                            >
                                <CardsMinhasAcoes
                                    path="/"
                                    src={iconeMinhasAcoes}
                                    title="Nova ação"
                                />
                            </button>
                        ) : null}
                    </div>
                </div>
            </Container>
        </>
    );
}
