import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { login, validationLogin } from "./config";
import { Container } from "./styles";
import { Text } from "alisson-application";
import { Container as Button } from "@/presentation/components/Button";
import { useAuthentication } from "@/main/hooks/authentication";
import { AuthenticationParams } from "@/domain/useCases";
import { FormikHelpers } from 'formik';

function formatarCPF(cpf: string): string {
    return cpf
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
}

function clearCPF(cpf: string): string {
    return cpf.replace(/\D/g, '');
}

export function FormLogin() {
    const { onSubmit } = useAuthentication();

    const handleSubmit = (values: AuthenticationParams, formikHelpers: FormikHelpers<AuthenticationParams>) => {
        const adjustedValues = {
            ...values,
            cpf: clearCPF(values.cpf),
        };
        onSubmit(adjustedValues, formikHelpers);
    };

    return (
        <Container>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={login}
                validationSchema={validationLogin}
                onSubmit={handleSubmit}
            >
                {(props: FormikProps<AuthenticationParams>) => {
                    return (
                        <Form data-testid="form-login">
                            <div className="container-input user">
                                <Field
                                    type="text"
                                    placeholder="Digite seu CPF"
                                    name="cpf"
                                    data-testid="cpf"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        let valorLimpo = e.target.value.replace(/\D/g, '');
                                        valorLimpo = valorLimpo.slice(0, 11);
                                        const valorFormatado = formatarCPF(valorLimpo);                                        
                                        props.setFieldValue('cpf', valorFormatado);
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                name="cpf"
                                data-testid="error"
                                component="span"
                            />

                            <div className="container-input cadeado">
                                <Field
                                    type="password"
                                    placeholder="Digite sua senha"
                                    name="password"
                                    data-testid="password"
                                />
                            </div>
                            <ErrorMessage
                                name="password"
                                data-testid="error"
                                component="span"
                            />

                            <Button
                                data-testid="button"
                                type="submit"
                                mb="30px"
                                disabled={!props.isValid}
                                isLoading={props.isSubmitting}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                Entrar
                            </Button>
                        </Form>
                    );
                }}
            </Formik>

            {/* <Text as="span">Esqueci minha senha</Text> */}
        </Container>
    );
}
