import { Box, Flex, Text } from "alisson-application";
import { ErrorMessage, FieldProps } from "formik";

import { Container as Button } from "@/presentation/components/Button";

import { DrafEditor } from "@/presentation/components/Editor";
import { IOSSwitch } from "@/presentation/components/Switch";

export function Right(props: FieldProps) {
    const { form } = props;
    const { setFieldValue, setValues, values } = form;

    const regex =
        /(?:|[^"]*[;\s])(background-color\s*:[^";]*)|(?:|[^"]*[;\s])(background\s*:[^";]*)/g;
    const hasTextLGPD = form.values.use_lgpd === "1";

    return (
        <div className="dir">
            <Box mb="20px">
                <DrafEditor
                    title="Regulamento da ação"
                    testid="regulamento"
                    onChange={(values) => {
                        const html = values.trim().replace(regex, "");

                        if (html.replace(/<.*?>/g, "").trim().length === 0) {
                            setFieldValue("regulamento", "");
                            return;
                        }
                        setFieldValue("regulamento", html);
                    }}
                />
            </Box>

            <Flex alignItems="center" mb="20px">
                <IOSSwitch
                    testid="use_lgpd"
                    onChange={(e, checked) => {
                        setValues({
                            ...values,
                            use_lgpd: checked ? "1" : "0",
                            lgpd_text: checked ? values.lgpd_text : "",
                        });
                    }}
                />
                <Text as="p" fontSize="xs" color="secondarydark" ml="9px">
                    Texto LGPD
                </Text>
            </Flex>

            <div
                className={`container-input text-LGPD ${
                    hasTextLGPD ? "" : "disabled"
                }`}
            >
                <div className="container-draf">
                    <DrafEditor
                        title="Texto LGPD"
                        testid="regulamento"
                        values={
                            !hasTextLGPD
                                ? `
                    <div data-offset-key="bmieb-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr" style="text-align: center;">
                        <span data-offset-key="bmieb-0-0">
                            <span data-text="true">Para mais informações sobre como seus dados</span>
                        </span>
                        <span data-offset-key="bmieb-0-1">
                            <span data-text="true">são tratados CLIQUE AQUI.</span>
                        </span>
                    </div>    

                    `
                                : "<p></p>"
                        }
                        onChange={(values) => {
                            const html = values.trim().replace(regex, "");

                            if (
                                html.replace(/<.*?>/g, "").trim().length === 0
                            ) {
                                setFieldValue("lgpd_text", "");
                                return;
                            }
                            setFieldValue(
                                "lgpd_text",
                                values.trim().replace(regex, "")
                            );
                        }}
                    />
                </div>
                <ErrorMessage
                    name="lgpd_text"
                    data-testid="error"
                    component="span"
                />
            </div>

            <Button
                data-testid="button"
                type="submit"
                disabled={!form.isValid}
                isLoading={false}
                spiner="BallTriangle"
                spinerColor="primary"
            >
                Avançar
            </Button>
        </div>
    );
}
