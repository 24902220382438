import { DetailAction } from "@/presentation/components/DetailAction";
import { UseDetailActionProvider } from "@/main/hooks/detailAction";

import { Voucher } from "@/data/useCases/voucher/voucher";
import { Custumize } from "@/data/useCases/custumize/custumize";
import { Information } from "@/data/useCases/information/information";
import { User } from "@/data/useCases/user/user";
import { Report } from "@/data/useCases/report/report";

export function PageDetailAction() {
    const information = new Information();
    const user = new User();
    const voucher = new Voucher();
    const custumize = new Custumize();
    const report = new Report();

    return (
        <UseDetailActionProvider
            information={information}
            user={user}
            voucher={voucher}
            custumize={custumize}
            report={report}
        >
            <DetailAction />
        </UseDetailActionProvider>
    );
}
