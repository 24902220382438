import { useEffect, useState } from "react";
import { Text, Flex } from "alisson-application";
import { useDetailAction } from "@/main/hooks/detailAction";
import { useDetailActionCustumize } from "@/presentation/components/DetailContent/hooks/detailActionCustumize";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useModal } from "@/main/contexts/modal";
import imgUser from "@/presentation/assets/icone/user-action.svg";
import imgOlhoRoxo from "@/presentation/assets/icone/olho-roxo.svg";
import { validationLogin, initialValues, IValues } from "./config";
import { Logo } from "./logo";
import { Logo2 } from "./logo2";
import { LogoAdm } from "./logoAdm";
import { CharCounter } from "@/presentation/components/CharCounter";
import { Container as Button } from "@/presentation/components/Button";
import { IOSSwitch } from "@/presentation/components/Switch";
import { DrafEditor } from "@/presentation/components/Editor";
import { DetailCustomPeriod } from "@/presentation/components/DetailCustomPeriod/DetailCustomPeriod";

export function DetailCustomConfig() {
    const [values, setValues] = useState(initialValues);
    const { findActionData } = useDetailAction();
    const { handleChange, updateTelaLogin, loading } =
        useDetailActionCustumize();

    const use_Lgpd = parseInt(findActionData.use_lgpd + "");

    const { setState, state } = useModal();

    const regex =
        /(?:|[^"]*[;\s])(background-color\s*:[^";]*)|(?:|[^"]*[;\s])(background\s*:[^";]*)/g;
    const [isMaintenanceActive, setIsMaintenanceActive] = useState(true);
    const [isErrorLoginActive, setIsErrorLoginActive] = useState(true);

    const [manuallyDisabledMaintenance, setManuallyDisabledMaintenance] = useState(false);
    const [manuallyDisabledErrorLogin, setManuallyDisabledErrorLogin] = useState(false);

    const [initialMaintenanceData, setInitialMaintenanceData] = useState({
        personalized_blocked_text: "",
        blocked_text_start_date: "",
        blocked_text_end_date: "",
    });

    const [initialErrorLoginData, setInitialErrorLoginData] = useState({
        personalized_login_error_message: "",
        login_message_start_date: "",
        login_message_end_date: "",
    });

    useEffect(() => {
        setValues({
            primary_logo: findActionData?.detailed?.primary_logo,
            secondary_logo: findActionData?.detailed?.secondary_logo,
            background: findActionData.background
                ? findActionData.background
                : "",
            button_color: findActionData.button_color
                ? findActionData.button_color
                : "#f17e21",
            login_title: findActionData.login_title
                ? findActionData.login_title
                : "o lugar certo para resgatar prêmios",
            login_subtitle: findActionData.login_subtitle
                ? findActionData.login_subtitle
                : "PENSADOS PARA VOCÊ!!",
            use_lgpd: use_Lgpd ? use_Lgpd : 0,
            lgpd_text: findActionData.lgpd_text ? findActionData.lgpd_text : "",
            job: findActionData.job ? findActionData.job : "",
            internal_policy: findActionData.internal_policy
                ? findActionData.internal_policy
                : "",
            logo: findActionData?.detailed?.logo
                ? findActionData?.detailed?.logo
                : "",
            blocked_text: findActionData.blocked_text
                ? findActionData.blocked_text
                : "",
            personalized_blocked_text: findActionData.personalized_blocked_text
                ? findActionData.personalized_blocked_text
                : "",
            blocked_text_start_date: findActionData.blocked_text_start_date
                ? findActionData.blocked_text_start_date
                : "",
            blocked_text_end_date: findActionData.blocked_text_end_date
                ? findActionData.blocked_text_end_date
                : "",
            personalized_login_error_message: findActionData.personalized_login_error_message
                ? findActionData.personalized_login_error_message
                : "",
            login_message_start_date: findActionData.login_message_start_date
                ? findActionData.login_message_start_date
                : "",
            login_message_end_date: findActionData.login_message_end_date
                ? findActionData.login_message_end_date
                : "",
        });

        setInitialMaintenanceData({
            personalized_blocked_text: findActionData.personalized_blocked_text || "",
            blocked_text_start_date: findActionData.blocked_text_start_date || "",
            blocked_text_end_date: findActionData.blocked_text_end_date || "",
        });

        setInitialErrorLoginData({
            personalized_login_error_message: findActionData.personalized_login_error_message || "",
            login_message_start_date: findActionData.login_message_start_date || "",
            login_message_end_date: findActionData.login_message_end_date || "",
        });
    }, []);

    useEffect(() => {
        const savedStateMaintenance = localStorage.getItem("manutencao_desativado");
        setIsMaintenanceActive(savedStateMaintenance !== "true");

        const savedStateErrorLogin = localStorage.getItem("erro_login_desativado");
        setIsErrorLoginActive(savedStateErrorLogin !== "true");

        const checkToggleStatus = (startDateStr: string, endDateStr: string) => {
            const currentDate = new Date();
            const startDate = parseDate(startDateStr);
            const endDate = parseDate(endDateStr);

            if (!startDate || !endDate) return false;
            return currentDate >= startDate && currentDate <= endDate;
        };

        setIsMaintenanceActive(checkToggleStatus(values.blocked_text_start_date ?? "", values.blocked_text_end_date ?? ""));
        setIsErrorLoginActive(checkToggleStatus(values.login_message_start_date ?? "", values.login_message_end_date ?? ""));
    }, [values]);



    useEffect(() => {
        const savedStateErrorLogin = localStorage.getItem("erro_login_desativado");
        setIsErrorLoginActive(savedStateErrorLogin !== "true");

        const checkErrorLoginStatus = () => {
            const currentDate = new Date();

            const startDate = parseDate(values.login_message_start_date ?? '');
            const endDate = parseDate(values.login_message_end_date ?? '');

            if (!startDate || !endDate) {
                setIsErrorLoginActive(false);
                return;
            }

            const isWithinInterval = currentDate >= startDate && currentDate <= endDate;
            if (isWithinInterval) {
                setIsErrorLoginActive(true);
            }
        };

        checkErrorLoginStatus();
    }, []);


    const parseDate = (dateString: string): Date | null => {
        if (!dateString) {
            return null;
        }
        const [datePart, timePart] = dateString.split(" ");
        const [year, month, day] = datePart.split("-").map(Number);
        const [hour, minute, second] = timePart ? timePart.split(":").map(Number) : [0, 0, 0];

        if (!year || !month || !day) {
            return null;
        }

        const parsedDate = new Date(year, month - 1, day, hour, minute, second);
        return parsedDate;
    };


    return (
        <div className="container-login">
            <div className="container-header">
                <Flex alignItems="center" mb="19px">
                    <img src={imgUser} alt="" />
                    <Text as="h1" ml="21px">
                        Tela de login
                    </Text>
                </Flex>

                <Flex>
                    <img src={imgOlhoRoxo} alt="" />
                    <Text as="h5" ml="21px">
                        Ver modelo de página
                    </Text>
                </Flex>
            </div>

            <Formik
                validateOnMount
                enableReinitialize
                initialValues={values}
                validationSchema={validationLogin}
                onSubmit={async (values) =>
                    setState({
                        ...state,
                        alert: {
                            active: true,
                            onclick: async () => {
                                const finalValues = { ...values };
                            
                                if (!isMaintenanceActive) {
                                    if (manuallyDisabledMaintenance) {
                                        finalValues.blocked_text_start_date = "";
                                        finalValues.blocked_text_end_date = "";
                                    } else {
                                        finalValues.personalized_blocked_text = initialMaintenanceData.personalized_blocked_text;
                                        finalValues.blocked_text_start_date = initialMaintenanceData.blocked_text_start_date;
                                        finalValues.blocked_text_end_date = initialMaintenanceData.blocked_text_end_date;
                                    }
                                }
                            
                                if (!isErrorLoginActive) {
                                    if (manuallyDisabledErrorLogin) {
                                        finalValues.login_message_start_date = "";
                                        finalValues.login_message_end_date = "";
                                    } else {
                                        finalValues.personalized_login_error_message = initialErrorLoginData.personalized_login_error_message;
                                        finalValues.login_message_start_date = initialErrorLoginData.login_message_start_date;
                                        finalValues.login_message_end_date = initialErrorLoginData.login_message_end_date;
                                    }
                                }
                            
                                await updateTelaLogin(finalValues);
                            },
                            

                            text: "Você deseja realmente editar esses campos?",
                        },
                    })
                }
            >
                {(props: FormikProps<IValues>) => {
                    const {
                        values,
                        setFieldValue,
                        handleChange: handleChangeFormik,
                    } = props;

                    const hasTextLGPD = values.use_lgpd === 1;

                    return (
                        <Form>
                            <div className="primeiraConfig">
                                <div className="container-form">
                                    <div className="container-color">
                                        <Text as="h5" mb="22px">
                                            Cor de fundo hexadecimal
                                        </Text>

                                        <div className="color">
                                            <input
                                                type="color"
                                                value={values.background}
                                                name="background"
                                                onChange={(e) => {
                                                    handleChangeFormik(e);
                                                }}
                                                disabled={loading}
                                            />

                                            <div className="container-input">
                                                <input
                                                    type="text"
                                                    value={values.background}
                                                    onChange={(values) => {
                                                        setFieldValue(
                                                            "background",
                                                            values.target.value
                                                        );
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="background"
                                                    data-testid="error"
                                                    component="span"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-color">
                                        <Text as="h5" mb="22px">
                                            Cor do botão hexadecimal
                                        </Text>

                                        <div className="color">
                                            <input
                                                type="color"
                                                value={values.button_color}
                                                name="button_color"
                                                onChange={(e) => {
                                                    handleChangeFormik(e);
                                                }}
                                                disabled={loading}
                                            />

                                            <div className="container-input">
                                                <input
                                                    type="text"
                                                    value={values.button_color}
                                                    onChange={(values) => {
                                                        setFieldValue(
                                                            "button_color",
                                                            values.target.value
                                                        );
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="button_color"
                                                    data-testid="error"
                                                    component="span"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Text as="h5" pl="10px" mb="10px">
                                        Texto 01
                                    </Text>
                                    <div className="container-input">
                                        <input
                                            type="text"
                                            value={values.login_title}
                                            name="login_title"
                                            disabled={loading}
                                            maxLength={35}
                                            onChange={(e) => {
                                                handleChangeFormik(e);
                                            }}
                                        />

                                        <CharCounter
                                            total={35}
                                            value={values.login_title}
                                        />
                                        <ErrorMessage
                                            name="login_title"
                                            data-testid="error"
                                            component="span"
                                        />
                                    </div>

                                    <Text as="h5" pl="10px" mb="10px">
                                        Texto 02
                                    </Text>
                                    <div className="container-input">
                                        <input
                                            type="text"
                                            value={values.login_subtitle}
                                            name="login_subtitle"
                                            disabled={loading}
                                            maxLength={20}
                                            onChange={(e) => {
                                                handleChangeFormik(e);
                                            }}
                                        />
                                        <CharCounter
                                            total={20}
                                            value={values.login_subtitle}
                                        />

                                        <ErrorMessage
                                            name="login_subtitle"
                                            data-testid="error"
                                            component="span"
                                        />
                                    </div>
                                </div>

                                <Field component={Logo} />

                                <Field component={Logo2} />
                            </div>

                            <div className="container-itens">
                                <div className="container-item">
                                    <div className="container-input">
                                        <Text as="h5" mb="10px" pl="16px">
                                            Job
                                        </Text>

                                        <input
                                            type="text"
                                            placeholder="Job"
                                            value={values.job}
                                            name="job"
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                                <Field component={LogoAdm} />
                            </div>
                            <div className="container-input">
                                <Flex alignItems="center">
                                    <IOSSwitch
                                        name="use_lgpd"
                                        value={values.use_lgpd ? 0 : 1}
                                        checked={values.use_lgpd ? true : false}
                                        onChange={async (event) => {
                                            await handleChange(event);
                                        }}
                                    />
                                    <Text
                                        as="span"
                                        color="secondarydark"
                                        ml="10px"
                                    >
                                        Texto LGPD
                                    </Text>
                                </Flex>
                            </div>
                            <div className="container-itens">
                                <div
                                    className={`container-input text-LGPD ${hasTextLGPD ? "" : "disabled"
                                        }`}
                                >
                                    <div className="container-draf">
                                        <DrafEditor
                                            title="Texto LGPD"
                                            testid="lgpd_text"
                                            values={
                                                findActionData.lgpd_text
                                                    ? findActionData.lgpd_text
                                                    : !hasTextLGPD
                                                        ? `
                    <div data-offset-key="bmieb-0-0" class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr" style="text-align: center;">
                        <span data-offset-key="bmieb-0-0">
                            <span data-text="true">Para mais informações sobre como seus dados</span>
                        </span>
                        <span data-offset-key="bmieb-0-1">
                            <span data-text="true">são tratados CLIQUE AQUI.</span>
                        </span>
                    </div>    
                    `
                                                        : "<p></p>"
                                            }
                                            onChange={(values) => {
                                                const html = values
                                                    .trim()
                                                    .replace(regex, "");

                                                if (
                                                    html
                                                        .replace(/<.*?>/g, "")
                                                        .trim().length === 0
                                                ) {
                                                    setFieldValue(
                                                        "lgpd_text",
                                                        ""
                                                    );
                                                    return;
                                                }
                                                setFieldValue(
                                                    "lgpd_text",
                                                    values
                                                        .trim()
                                                        .replace(regex, "")
                                                );
                                            }}
                                        />
                                        <CharCounter
                                            total={200}
                                            value={values.lgpd_text}
                                        />
                                    </div>
                                </div>
                                <div className="container-input">
                                    <DrafEditor
                                        title="Regulamento da ação"
                                        testid="internal_policy"
                                        values={
                                            findActionData.internal_policy
                                                ? findActionData.internal_policy
                                                : ""
                                        }
                                        onChange={(values) => {
                                            const html = values
                                                .trim()
                                                .replace(regex, "");

                                            if (
                                                html
                                                    .replace(/<.*?>/g, "")
                                                    .trim().length === 0
                                            ) {
                                                setFieldValue(
                                                    "internal_policy",
                                                    ""
                                                );
                                            } else {
                                                setFieldValue(
                                                    "internal_policy",
                                                    html
                                                );
                                            }
                                        }}
                                    />
                                    <CharCounter
                                        total={200}
                                        value={values.internal_policy}
                                    />
                                </div>
                            </div>

                            <br /> <br />
                            <hr />
                            <br /> <br />

                            <div className="container-input">
                                <Flex alignItems="center">
                                    <IOSSwitch
                                        checked={isMaintenanceActive}
                                        onChange={() => {
                                            const newState = !isMaintenanceActive;
                                            setIsMaintenanceActive(newState);

                                            if (!newState) {
                                                setManuallyDisabledMaintenance(true);
                                            } else {
                                                setManuallyDisabledMaintenance(false);
                                            }

                                            localStorage.setItem("manutencao_desativado", (!newState).toString());
                                        }}
                                    />
                                    <Text as="span" color="secondarydark" ml="10px">
                                        Página de Manutenção
                                    </Text>
                                </Flex>
                            </div>

                            {isMaintenanceActive && (
                                <div style={{ display: "flex" }}>
                                    <div className="container-input manutencao-input" style={{ marginRight: "10px" }}>
                                        <DrafEditor
                                            style={{ minHeight: "334px" }}
                                            title="Mensagem da Tela de Manutenção"
                                            testid="personalized_blocked_text"
                                            values={
                                                findActionData.personalized_blocked_text ? findActionData.personalized_blocked_text : ""
                                            }
                                            onChange={(values) => {
                                                const html = values.trim().replace(regex, "");

                                                if (html.replace(/<.*?>/g, "").trim().length === 0) {
                                                    setFieldValue("personalized_blocked_text", "");
                                                } else {
                                                    setFieldValue("personalized_blocked_text", html);
                                                }
                                            }}
                                        />
                                        <CharCounter total={250} value={values.personalized_blocked_text} />
                                    </div>

                                    <div className="container-input" style={{ width: "655px" }}>
                                        <DetailCustomPeriod
                                            title_message="Selecione o período em que a tela de manutenção<br/>deve permanecer ativa:"
                                            blocked_text_start_date={values.blocked_text_start_date}
                                            blocked_text_end_date={values.blocked_text_end_date}
                                            onChangeStartDate={(newValue) => setFieldValue("blocked_text_start_date", newValue)}
                                            onChangeEndDate={(newValue) => setFieldValue("blocked_text_end_date", newValue)}
                                        />
                                    </div>
                                </div>
                            )}

                            <br /> <br />
                            <hr />
                            <br /> <br />

                            <div className="container-input">
                                <Flex alignItems="center">
                                    <IOSSwitch
                                        checked={isErrorLoginActive}
                                        onChange={() => {
                                            const newState = !isErrorLoginActive;
                                            setIsErrorLoginActive(newState);

                                            if (!newState) {
                                                setManuallyDisabledErrorLogin(true);
                                            } else {
                                                setManuallyDisabledErrorLogin(false);
                                            }

                                            localStorage.setItem("erro_login_desativado", (!newState).toString());
                                        }}
                                    />
                                    <Text as="span" color="secondarydark" ml="10px">
                                        Erro de Login
                                    </Text>
                                </Flex>
                            </div>

                            {isErrorLoginActive && (
                                <div style={{ display: "flex" }}>
                                    <div className="container-input manutencao-input" style={{ marginRight: "10px" }}>
                                        <DrafEditor
                                            style={{ minHeight: "334px" }}
                                            title="Mensagem de Erro de Login"
                                            testid="personalized_login_error_message"
                                            values={
                                                findActionData.personalized_login_error_message ? findActionData.personalized_login_error_message : ""
                                            }
                                            onChange={(values) => {
                                                const html = values.trim().replace(regex, "");

                                                if (html.replace(/<.*?>/g, "").trim().length === 0) {
                                                    setFieldValue("personalized_login_error_message", "");
                                                } else {
                                                    setFieldValue("personalized_login_error_message", html);
                                                }
                                            }}
                                        />
                                        <CharCounter total={250} value={values.personalized_login_error_message} />
                                    </div>

                                    <div className="container-input" style={{ width: "655px" }}>
                                        <DetailCustomPeriod
                                            title_message="Selecione o período em que a mensagem de erro de login <br/>deve permanecer ativa:"
                                            blocked_text_start_date={values.login_message_start_date}
                                            blocked_text_end_date={values.login_message_end_date}
                                            onChangeStartDate={(newValue) => setFieldValue("login_message_start_date", newValue)}
                                            onChangeEndDate={(newValue) => setFieldValue("login_message_end_date", newValue)}
                                        />
                                    </div>
                                </div>
                            )}

                            <Button className="btn-salvar" disabled={loading}>
                                Salvar
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
