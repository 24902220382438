import { Flex, Text } from "alisson-application";
import { Header } from "@/presentation/components/Header";
import { useHistory } from "react-router-dom";

import imgSetaEsqRoxo from "@/presentation/assets/icone/seta-esq-roxo.svg";
import imgConversor from "@/presentation/assets/icone/conversor-header.svg";

export function HeaderConversor() {
    const history = useHistory();
    return (
        <div className="container-header">
            <Header icone={imgConversor} title="CONVERSOR">
                <button onClick={() => history.goBack()}>
                    <Flex alignItems="center" mr="20px">
                        <img src={imgSetaEsqRoxo} alt="" />
                        <Text as="h5" ml="6px">
                            Voltar
                        </Text>
                    </Flex>
                </button>
            </Header>
        </div>
    );
}
