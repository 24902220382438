import styled from "styled-components";

interface ContainerProps {
    icone: string;
}

export const Container = styled.div<ContainerProps>`
    height: 100px;
    position: fixed;
    top: 0px;
    left: 137px;
    width: calc(100vw - 137px);
    padding-left: 27px;
    padding-right: 122px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #efefef;
    background-color: white;
    z-index: 99;
    .container-esq {
        display: flex;
        align-items: center;
    }
    .container-img {
        width: 32px;
        height: 32px;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: ${(props) => props.theme.colors.primary};
            -webkit-mask: url(${(props) => props.icone}) no-repeat;
            -webkit-mask-size: 100%;
            background-size: cover;
            width: 100%;
            height: 100%;

            z-index: 20;
        }
    }
`;
