import { Login } from "@/presentation/components/Login";

import { UseAuthenticationProvider } from "@/main/hooks/authentication";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { AccountModel } from "@/domain/models";
import { RemoteAuthentication } from "@/data/useCases/authentication/remoteAuthentication";
import { RemoteSmsValidate } from "@/data/useCases/smsValidate/remoteSmsValidate";
import { LocalSaveAccessToken } from "@/data/useCases/saveAccessToken/localSaveAccessToken";
import { RemoteMe } from "@/data/useCases/me/me";
import { StorageLocation } from "@/infra/cache/storageLocation";
import { RemoteSmsSend } from "@/data/useCases/smsSend/remoteSmsSend";

export function PageLogin() {
    const axios = new AxiosHttpClient<AccountModel>();
    const storage = new StorageLocation();
    const authentication = new RemoteAuthentication(axios);
    const saveAccessToken = new LocalSaveAccessToken(storage);
    const sms = new RemoteSmsValidate(axios);
    const me = new RemoteMe(axios);
    const send = new RemoteSmsSend(axios);
    return (
        <UseAuthenticationProvider
            authentication={authentication}
            saveAccessToken={saveAccessToken}
            me={me}
            sms={sms}
            send={send}
        >
            <Login />
        </UseAuthenticationProvider>
    );
}
