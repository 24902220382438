import { SetStorage, GetStorage, ClearStorage } from "@/data/protocols/cache";

export class StorageLocation implements SetStorage, GetStorage, ClearStorage {
    async set(key: string, value: object | undefined): Promise<void> {
        if (value) {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            localStorage.removeItem(key);
        }
    }

    get(key: string): any {
        const token = localStorage.getItem(key);
        if (token) {
            return JSON.parse(token);
        }
    }

    clear() {
        localStorage.clear();
        window.location.reload();
        return null;
    }
}
