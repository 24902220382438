import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { ActionLoadAllModel } from "@/domain/models";
import { UnexpectedError } from "@/domain/errors";
import { ActionLoadAll } from "@/domain/useCases";

export class RemoteActionLoadAll implements ActionLoadAll {
    constructor(
        private readonly httpGetClient: HttpClient,
        private readonly url: string = "action/loads/all"
    ) {}

    async loadAll(id: number): Promise<ActionLoadAllModel[]> {
        const response = await this.httpGetClient.request({
            url: `${this.url}/${id}`,
            method: "get",
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [] as ActionLoadAllModel[];
            default:
                throw new UnexpectedError();
        }
    }
}
