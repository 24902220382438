import { TotalVoucherRescued } from "@/presentation/components/TotalVoucherRescued";
import { VoucherGroup } from "@/presentation/components/VoucherGroup";
import { CardActionVoucher } from "@/presentation/components/CardActionVoucher";
import { AddVoucher } from "@/presentation/components/AddVoucher";
import { SortingVouchers } from "@/presentation/components/SortingVouchers";
import { ActionChoiceQuantityVoucher } from "@/presentation/components/ActionChoiceQuantityVoucher";
import { Modal } from "@/presentation/components/Modal";
import { useModal } from "@/main/contexts/modal";
import { useDetailAction } from "@/main/hooks/detailAction";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";
import { AddGroup } from "@/presentation/components/AddGroup";
import { EditGroup } from "@/presentation/components/EditGroup";
import { Container } from "./styles";
import { Loader } from "../Loader";

import { ActionProductsGroupListModel } from "@/domain/models";


export function DetailActionVoucher() {
    const { handleClose, state } = useModal();
    const { stateHistory } = useDetailAction();
    const { loading, dataMetrics, groups, setGroup } = useDetailActionVoucher();

    console.log("groups", groups);
    console.log("dataMetrics", dataMetrics);

    if (loading) {
        return (
            <Container>
                <div className="loading">
                    <Loader />
                </div>
            </Container>
        );
    }

    const sortedGroups = groups.slice().sort((a, b) => a.order - b.order);
    console.log("sortedGroups", sortedGroups);

    const productConfigIdsInGroups = sortedGroups.flatMap(group =>
        group.products.map(product => product.product_configuration_id)
    );
    console.log("productConfigIdsInGroups", productConfigIdsInGroups);

    const combinedItems = [
        ...sortedGroups.map(group => ({ ...group, type: 'group' as const })),
        ...(dataMetrics?.products?.map(product => ({
            ...product,
            type: 'product' as const,
            deleted_at: product.deleted_at,
            id: product.id,
            order: product.order
        })) || [])
    ];
    console.log("combinedItems before sorting", combinedItems);

    const sortedCombinedItems = combinedItems.sort((a, b) => {
        if (a.type === 'product' && b.type === 'product') {
            if (!a.deleted_at && b.deleted_at) return -1;
            if (a.deleted_at && !b.deleted_at) return 1;
        }

        const aInGroup = a.type === 'product' && productConfigIdsInGroups.includes(a.id);
        const bInGroup = b.type === 'product' && productConfigIdsInGroups.includes(b.id);

        if (!aInGroup && !bInGroup) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
        }

        if (a.type === 'group' && !bInGroup) return a.order - b.order;
        if (b.type === 'group' && !aInGroup) return a.order - b.order;

        if (!aInGroup && bInGroup) return -1;
        if (aInGroup && !bInGroup) return 1;

        if (aInGroup && bInGroup) {
            const groupA = sortedGroups.find(group => group.products.some(product => product.product_configuration_id === a.id));
            const groupB = sortedGroups.find(group => group.products.some(product => product.product_configuration_id === b.id));
            if (groupA && groupB) {
                const productA = groupA.products.find(product => product.product_configuration_id === a.id);
                const productB = groupB.products.find(product => product.product_configuration_id === b.id);
                if (productA && productB) {
                    return productA.order - productB.order;
                }
            }
        }

        return a.order - b.order;
    });

    
    console.log("combinedItems after sorting", sortedCombinedItems);
    
    

    console.log("sortedCombinedItems after sorting", sortedCombinedItems);

    return (
        <Container>
            <TotalVoucherRescued
                rewardedItems={dataMetrics.rewarded_items}
                maxRewards={dataMetrics.max_rewards}
                hasGroups={groups.length > 0}
            />

            {sortedCombinedItems.map((item) => {
                if (item.type === 'group') {
                    return <VoucherGroup key={item.id} {...item} />;
                }
                return (
                    <CardActionVoucher
                        key={item.variation_id}
                        {...item}
                        optionsObtainMmethods={dataMetrics.options_obtain_methods}
                        optionsTemplate={dataMetrics.options_template}
                        actionId={stateHistory.id}
                    />
                );
            })}

            <Modal
                open={state.createGroup}
                handleClose={() => handleClose("createGroup")}
            >
                {state.createGroup && <AddGroup />}
            </Modal>

            <Modal
                open={state.editGroup}
                handleClose={() => {
                    handleClose("editGroup");
                    setGroup({} as ActionProductsGroupListModel);
                }}
            >
                {state.editGroup && <EditGroup />}
            </Modal>

            <Modal
                open={state.selectVouchers}
                handleClose={() => handleClose("selectVouchers")}
            >
                {state.selectVouchers && <AddVoucher />}
            </Modal>

            <Modal
                open={state.selectVouchersQtd}
                handleClose={() => handleClose("selectVouchersQtd")}
            >
                <ActionChoiceQuantityVoucher />
            </Modal>

            <Modal
                open={state.sortingVouchers}
                handleClose={() => handleClose("sortingVouchers")}
            >
                <SortingVouchers actionId={stateHistory.id} />
            </Modal>
        </Container>
    );
}
