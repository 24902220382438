import React, { createContext, useContext } from "react";
import { StorageLocation } from "@/infra/cache/storageLocation";

interface UseApiProps {
    children: React.ReactNode | React.ReactNode[];
    storageLocation: StorageLocation;
}

export interface UseStateApi {
    getStorage<T>(key: string): T;
    setStorage(key: string, value: object): void;
    clearStorage(): void;
}

const Context = createContext<UseStateApi>({} as UseStateApi);

export function UseApiProvider(props: UseApiProps) {
    const { children, storageLocation } = props;

    function getStorage<T>(key: string): T {
        return storageLocation.get(key);
    }

    async function setStorage(key: string, value: object) {
        return await storageLocation.set(key, value);
    }

    function clearStorage() {
        storageLocation.clear();
        window.location.reload();
    }

    return (
        <Context.Provider value={{ getStorage, setStorage, clearStorage }}>
            {children}
        </Context.Provider>
    );
}

export function useApi() {
    const context = useContext(Context);
    return context;
}
