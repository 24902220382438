import { RemoteActionProductsAdd } from "@/data/useCases/actionProductsAdd/RemoteActionProductsAdd";
import { RemoteActionProductsCustomize } from "@/data/useCases/actionProductsCustomize/remoteActionProductsCustomize";
import { RemoteActionProductsGroup } from "@/data/useCases/actionProductsGroup/remoteActionProductsGroup";
import { RemoteActionProductsGroupCreate } from "@/data/useCases/actionProductsGroupCreate/remoteActionProductsGroupCreate";
import { RemoteActionProductsGroupUpdate } from "@/data/useCases/remoteActionProductsGroupUpdate/remoteActionProductsGroupUpdate";
import { RemoteDetailActionMetrics } from "@/data/useCases/detailActionMetrics/remotedetailActionMetrics";
import { RemoteActionProductsList } from "@/data/useCases/remotActionProductsList/remotActionProductsList";
import { RemoteActionProductsGroupDelete } from "@/data/useCases/remoteActionProductsGroupDelete/remoteActionProductsGroupDelete";
import { RemoteDetailVoucher } from "@/data/useCases/detailVoucher/remoteDetailVoucher";
import { Mixin } from "ts-mixer";

export class CombinedActionProductsMixins extends Mixin(
    RemoteActionProductsAdd,
    RemoteActionProductsCustomize,
    RemoteActionProductsGroup,
    RemoteActionProductsGroupCreate,
    RemoteActionProductsGroupUpdate,
    RemoteDetailActionMetrics,
    RemoteActionProductsList,
    RemoteActionProductsGroupDelete,
    RemoteDetailVoucher,
) {}
