import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { ActionProductsGroupParams } from "@/domain/models";

import { ActionProductsGroupCreate } from "@/domain/useCases";
export type keyOfActionProductsCustomize<T> = keyof T;

export class RemoteActionProductsGroupCreate
    implements ActionProductsGroupCreate
{
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlCreateGroup: string = "action/products/groups/create"
    ) {}

    async createGroup(params: ActionProductsGroupParams): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlCreateGroup,
            body: this.formatCreateGroup(params),
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }

    formatCreateGroup(params: ActionProductsGroupParams): FormData {
        const formData = new FormData();
        const removeKeys = ["img", "product_configuration_ids"];
        const keys = Object.keys(
            params
        ) as keyOfActionProductsCustomize<ActionProductsGroupParams>[];

        keys.forEach((key) => {
            if (!removeKeys.includes(key)) {
                formData.append(key, params[key] as string);
            }
        });

        formData.append(
            `product_configuration_ids`,
            JSON.stringify(params.product_configuration_ids)
        );

        if (params.img) {
            formData.append("img", params.img);
        }

        return formData;
    }
}
