import { Container } from "./styles";
import { Text } from "alisson-application";

export function ContentText() {
    return (
        <Container>
            <Text as="small">
                Utilize o campo abaixo para digitar um login (CPF sem pontuação
                ou qualquer outro dado), em seguida clique em
                &quot;Converter&ldquo; para gerar o valor criptografado em
                AES-256 representado em Hexadecimal:
            </Text>
        </Container>
    );
}
