import styled from "styled-components";

export const Container = styled.div`
    background: ${(props) => props.theme.colors.gradients.violet};
    height: calc(100vh - 82px);
    width: 100%;
    position: relative;
    .container-img {
        position: absolute;
        top: 50%;
        left: 66px;
        transform: translateY(-50%);
        img {
            width: 38vw;
        }
    }

    .container-login {
        width: 60%;
        height: calc(100vh - 82px);
        background-color: ${(props) => props.theme.colors.white};
        position: absolute;
        right: 0px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`;
