import styled from "styled-components";

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 50px;
  height: 82px;
  width: 1370px;
  margin: 0 auto;
  .container-img {
    position: relative;
    display: flex;
    justify-content: center;
    padding-left: 50px;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #343434;
    &:last-child {
      font-size: 12px;
    }
  }

  @media (max-width: 1366px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    .container-img {
      display: none;
    }
    span {
      display: none;
      margin: 0px;
      &:first-child {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
      }
    }
  }
`;
