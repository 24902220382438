import imgSetaDir from "@/presentation/assets/icone/seta-dir.svg";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    button {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.colors.tertiarybright};
        margin-right: 38px;
        transition: all 0.2s;
        position: relative;
        &.active {
            color: ${(props) => props.theme.colors.primary};
            font-size: 22px;
            font-weight: 700;
            transition: all 0.2s;
        }

        &:last-child {
            &:before {
                display: none;
            }
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: -18px;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.tertiarybright};
            -webkit-mask: url(${imgSetaDir}) no-repeat;
            width: 7px;
            height: 11px;
            z-index: 20;
        }
    }
`;
