import { useAction } from "@/main/hooks/action";
import { Pagination } from "@/presentation/components/ActionTable";
import { Header } from "@/presentation/components/Header";
import { ButtonsHeaderActions } from "@/presentation/components/ButtonsHeaderActions";
import { StockList } from "@/presentation/components/StockList";
import { Loader } from "@/presentation/components/Loader";
import { FilterMyActions } from "@/presentation/components/FilterMyActions";
import imgIconeAction from "@/presentation/assets/icone/menu/acao.svg";
import { Container } from "./styles";
import { When } from "../when";

export function Action() {
    const { actions, loading, activeGroup, activeFilter, fetchData } =
        useAction();

    return (
        <Container>
            <div className="container-header">
                <Header icone={imgIconeAction} title="MINHAS AÇÕES">
                    <ButtonsHeaderActions />
                </Header>
                <FilterMyActions />
            </div>

            <div
                className={`container-cards ${activeGroup ? "active" : ""} ${
                    activeFilter ? "filter" : ""
                }`}
            >
                <When expr={loading}>
                    <Loader />
                </When>

                <When expr={!loading}>
                    <>
                        {actions.map((action) => (
                            <StockList {...action} key={action.id} />
                        ))}
                        <Pagination
                            total={actions[0]?.pagination?.lastPage}
                            currentPage={async (page) => {
                                await fetchData(page);
                            }}
                            page={actions[0]?.pagination?.currentPage}
                        />
                    </>
                </When>
            </div>
        </Container>
    );
}
