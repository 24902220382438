import styled from "styled-components";

export const Container = styled.div`
    width: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.white};
    padding: 32px 0px 16px;

    button {
        width: fit-content;
        margin-bottom: 16px;
    }
    .marquee {
        width: 100% !important;
        display: flex;
        justify-content: space-between;
        margin-right: 30px;
        .initial-child-container {
            width: 100%;
        }
    }
    .child {
        width: 100%;

        .container-img {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
        }
    }

    h1 {
        margin-bottom: 32px;
        line-height: 56px;
    }

    p {
        color: ${(props) => props.theme.colors.black};
        text-transform: unset;
        margin-bottom: 24px;
        margin-right: 30px;
        margin-left: 30px;
    }

    &.blob {
        background-color: ${(props) => props.theme.colors.white};

        border-radius: 5px;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

        height: auto;
        width: 500px;
        transform: scale(1);
        /* animation: pulse-black 2s infinite; */
    }

    @keyframes pulse-black {
        0% {
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
        }

        70% {
            box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
    }
`;
