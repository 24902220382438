import styled from "styled-components";

export const Container = styled.div`
    min-height: 218px;
    width: 500px;

    border: 1px solid ${(props) => props.theme.colors.secondarydark};
    border-radius: 10px;
    padding: 5px;

    .rdw-link-modal {
        top: 5px !important;
        border-radius: 10px !important;
    }

    .rdw-link-modal-btn {
        border-radius: 10px !important;
        height: 40px !important;
        font-family: Lato;
        font-style: normal;
    }

    #openLinkInNewWindow {
        width: auto;
        height: auto;
    }

    .rdw-colorpicker-modal-options {
        display: flex;
    }

    .rdw-link-modal-input {
        margin-bottom: 5px;
    }

    a {
        color: blue;
        text-decoration: underline;
        cursor: pointer;
    }

    span {
        display: inline;
    }

    .container-title {
        display: flex;
        align-items: center;
        margin-left: 12px;
        margin-bottom: 16px;
        margin-top: 16px;
        p {
            text-transform: unset;
            font-family: Lato;
            font-size: 14px;
            font-weight: 700;
            line-height: 16.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
    }
`;

export const ContainerDimmed = styled(Container)`
    background-color: #f0f0f0;
    opacity: 0.5;
    border: 1px solid #e2e2e2;
`;
