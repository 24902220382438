import { ChangeEvent } from "react";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { Text, Flex } from "alisson-application";
import { useNewAction } from "@/main/hooks/newAction";
import Select, { options } from "@/presentation/components/Select";
import { CheckBox } from "@/presentation/components/CheckBox";
import { CustomField } from "@/presentation/components/CustomField";
import { Container as Button } from "@/presentation/components/Button";
import { When } from "@/presentation/components/when";
import {
    FormNewActionDataAccessFormik,
    initialValues,
    validation,
} from "./config";
import { Container } from "./styles";
import { IOSSwitch } from "@/presentation/components/Switch";
import imgUser from "@/presentation/assets/icone/user.svg";
export function FormNewActionDataAccess() {
    const { updateTab, setData, data } = useNewAction();

    const options: options[] = [
        {
            label: "CPF",
            value: 1,
        },
        {
            label: "Personalizado",
            value: 2,
        },
    ];
    const validator: options[] = [
        {
            label: "Data de nascimento",
            value: "BIRTH_DATE",
        },
        {
            label: "Personalizado",
            value: "Personalizado",
        },
    ];

    return (
        <Container>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={(values: any) => {
                    setData({ ...data, ...values });
                    updateTab("Revisão e aprovação");
                }}
            >
                {(props: FormikProps<FormNewActionDataAccessFormik>) => {
                    const { setFieldValue, values, setValues } = props;

                    return (
                        <Form>
                            <div className="container-select">
                                <Text as="h5" mb="8px">
                                    Tipo Login
                                </Text>
                                <Select
                                    options={options}
                                    label="Tipo de login"
                                    onChange={(values) => {
                                        setFieldValue(
                                            "login_type",
                                            values.label
                                        );
                                    }}
                                />

                                <div
                                    className={`container-input personalizado ${
                                        values.login_type ? "active" : ""
                                    }`}
                                >
                                    <Text as="h5" mb="8px">
                                        Label do login
                                    </Text>
                                    <Field
                                        type="text"
                                        placeholder="Ex: Digite sua funcional"
                                        name="login_label"
                                        data-testid="login_label"
                                    />
                                    <ErrorMessage
                                        name="login_label"
                                        data-testid="error"
                                        component="span"
                                    />
                                </div>
                            </div>

                            <div className="container-checkBox">
                                <CheckBox
                                    onChange={(values) =>
                                        setFieldValue(
                                            "login_type_cpf_truncated",
                                            values.target.checked ? 1 : 0
                                        )
                                    }
                                />
                                <Text as="span" color="secondarydark">
                                    Truncado
                                </Text>
                            </div>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                mb={`${
                                    values.use_validator === "1"
                                        ? "0px"
                                        : "32px"
                                }`}
                            >
                                <Flex alignItems="center">
                                    <IOSSwitch
                                        onChange={(e) => {
                                            const checked = e.target.checked;
                                            setValues({
                                                ...values,
                                                use_validator: checked ? 1 : 0,
                                                validator_type: checked
                                                    ? values.validator_type
                                                    : 0,
                                                validator_label: checked
                                                    ? values.validator_label
                                                    : "",
                                            });
                                        }}
                                    />

                                    <Text
                                        as="p"
                                        fontSize="xs"
                                        color="secondarydark"
                                        ml="9px"
                                    >
                                        Validador
                                    </Text>
                                </Flex>
                            </Flex>

                            <ErrorMessage
                                name="use_validator"
                                data-testid="error"
                                component="span"
                            />

                            <When expr={values.use_validator === "1"}>
                                <div className="container-select">
                                    <Text as="h5" mb="8px">
                                        &nbsp;
                                    </Text>
                                    <Select
                                        options={validator}
                                        label="Tipo de validação"
                                        onChange={(values) => {
                                            setFieldValue(
                                                "validator_type",
                                                values.value
                                            );
                                        }}
                                    />

                                    <div
                                        className={`container-input personalizado ${
                                            values.validator_type ===
                                            "Personalizado"
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        <Text as="h5" mb="8px">
                                            Label do validador
                                        </Text>
                                        <Field
                                            type="text"
                                            placeholder="Ex: Digite o nome da sua mãe"
                                            name="validator_label"
                                        />
                                        <ErrorMessage
                                            name="contractor"
                                            data-testid="error"
                                            component="span"
                                        />
                                    </div>
                                </div>
                            </When>

                            <Field component={CustomField} />

                            <div className="container-input checkbox">
                                <Flex alignItems="center" mb="20px">
                                    <IOSSwitch
                                        onChange={(e, checked) =>
                                            setFieldValue(
                                                "send_whatsapp",
                                                checked ? "1" : "0"
                                            )
                                        }
                                    />

                                    <Text
                                        as="p"
                                        fontSize="xs"
                                        color="secondarydark"
                                        ml="9px"
                                    >
                                        Enviar WhatsApp ao visualizar voucher
                                    </Text>
                                </Flex>
                            </div>

                            <div className="container-input checkbox">
                                <Flex alignItems="center" mb="20px">
                                    <IOSSwitch
                                        onChange={(e, checked) =>
                                            setFieldValue(
                                                "book_stock",
                                                checked ? 1 : 0
                                            )
                                        }
                                    />

                                    <Text
                                        as="p"
                                        fontSize="xs"
                                        color="secondarydark"
                                        ml="9px"
                                    >
                                        Reservar estoque
                                    </Text>
                                </Flex>
                            </div>

                            <div className="container-input checkbox">
                                <Flex alignItems="center" mb="54px">
                                    <IOSSwitch
                                        onChange={(e, checked) =>
                                            setFieldValue(
                                                "generate_users",
                                                checked ? 1 : 0
                                            )
                                        }
                                    />

                                    <Text
                                        as="p"
                                        fontSize="xs"
                                        color="secondarydark"
                                        ml="9px"
                                    >
                                        Gerar participantes
                                    </Text>

                                    <Flex
                                        className="container-input transparent"
                                        width="80px"
                                        ml="auto"
                                    >
                                        <img src={imgUser} alt="" />
                                        <Field
                                            type="text"
                                            placeholder="Qtd"
                                            name="generate_users_quantity"
                                            disabled={
                                                props.values.generate_users ===
                                                0
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) => {
                                                if (e.target.value.length > 4) {
                                                    return;
                                                }
                                                setFieldValue(
                                                    "generate_users_quantity",
                                                    parseInt(
                                                        e.target.value.replace(
                                                            /[^\d]+/g,
                                                            ""
                                                        )
                                                    )
                                                );
                                            }}
                                            pattern="[0-9]*"
                                            inputMode="numeric"
                                            value={
                                                props.values
                                                    .generate_users_quantity
                                            }
                                            data-testid="name"
                                        />
                                        <ErrorMessage
                                            name="generate_users_quantity"
                                            data-testid="error"
                                            component="span"
                                        />
                                    </Flex>
                                </Flex>
                            </div>

                            <Button
                                data-testid="button"
                                type="submit"
                                mb="30px"
                                disabled={!props.isValid}
                                isLoading={false}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                Avançar
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
