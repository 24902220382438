import { useModal } from "@/main/contexts/modal";
import { useDetailAction } from "@/main/hooks/detailAction";
import BlockedUserIcon from "@/presentation/assets/icone/blocked-user.svg";
import { Container } from "./styles";
import { Container as Button } from "@/presentation/components/Button";

export function ModalBlockedUser() {
    const {handleClose } = useModal();

    return (
        <Container>
            <img src={BlockedUserIcon} alt="Blocked User Icon" />
            <h1>Seu acesso foi bloqueado!</h1>
            <h2>Você excedeu o máximo de tentativas de <br/>
                Login e, por segurança, sua conta foi bloqueada.</h2>

            <p>Fale com o seu contato na Yetz para <br/>
                efetuar o desbloqueio</p>
            <Button
                data-testid="button"
                type="submit"
                mb="30px"
                spiner="BallTriangle"
                spinerColor="primary"
                onClick={() => handleClose("blockedUser")}
            >
                Ok
            </Button>
        </Container>
    );
}
