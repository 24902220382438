import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

export class RemoteActionProductRestore {
    constructor(protected readonly httpClient: AxiosHttpClient) {}

    async restoreProduct(params: { product_id: number }): Promise<void> {
        const response = await this.httpClient.request({
            url: `action/products/restore/${params.product_id}`,
            method: 'post',
        });

        if (response.statusCode !== 200) {
            throw new Error('Erro ao restaurar o produto');
        }
    }
}
