import { Container } from "./styles";
import { DetailActionLoadsPedding } from "@/presentation/components/DetailActionLoadsPedding";
import { DetailActionUserUpload } from "@/presentation/components/DetailActionUserUpload";
import { DetailActionUserActions } from "@/presentation/components/DetailActionUserActions";
import { DetailActionUserTable } from "@/presentation/components/DetailActionUserTable";
import { ModalUploadUserLoad } from "@/presentation/components/ModalUploadUserLoad";

export function DetailActionUser() {
    return (
        <Container>
            <DetailActionLoadsPedding />
            <DetailActionUserUpload />
            <DetailActionUserActions />
            <DetailActionUserTable />
            <ModalUploadUserLoad />
        </Container>
    );
}
