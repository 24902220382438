import { Text } from "alisson-application";
import { CheckBox } from "@/presentation/components/CheckBox";
import { Container } from "./styles";

export interface ListVouchersProps {
    foto_capa: string;
    nome: string;
    id: number;
    onChange: (id: number) => void;
    checked: boolean;
}

export function ListGroup(props: ListVouchersProps) {
    const { foto_capa, nome, onChange, id, checked } = props;

    return (
        <Container>
            <div className="container-check">
                <CheckBox
                    onChange={() => onChange(id)}
                    checked={checked ? true : false}
                />
            </div>

            <div className="container-text-img">
                <img src={foto_capa} alt="" />

                <Text as="p" fontSize="xs" color="secondarydark" ml="16px">
                    {nome}
                </Text>
            </div>
        </Container>
    );
}
