import styled from "styled-components";

export const Container = styled.div`
    button {
        &.btn-close {
            position: absolute;
            top: 48px;
            right: 72px;
        }
        &.btn-upload-img {
            width: 279px;
            pointer-events: none;
        }
    }

    .container-input {
        margin-bottom: 12px;
        &.saiba-mais {
            font-family: Lato;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
            transition: all 0.5s ease 0s;
            margin-top: 20px;
            > div {
                width: 100%;
            }
        }
    }

    .container-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f0f0f0;
        padding: 22px 20px 22px 42px;
        span {
            display: block;
            margin: 0px 16px;
        }
    }

    .container-list-vouchers {
        height: 303px;
        overflow-y: scroll;
        scrollbar-color: rgba(89, 92, 118, 0.5) rgba(255, 255, 255, 0.1);
        border: 1px solid #5e5f5f;
        border-radius: 5px;
        margin-top: 15px;
        padding-left: 15px;
        position: relative;
        div[data-testid="loading"] {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .container-foto-capa {
        display: flex;
        align-items: center;
        padding-left: 26px;
        padding-right: 32px;
        background: #f0f0f0;
        border-radius: 5px;
        height: 84px;
        .container-btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 190px;
            margin-left: auto;
            label {
                button {
                    width: fit-content !important;
                }
            }
        }
        img {
            &.foto-capa {
                width: 70px;
                height: 70px;
                border-radius: 5px;
                object-fit: cover;
            }
        }

        button {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            display: flex;
            align-items: center;
            color: ${(props) => props.theme.colors.primary};
            img {
                display: block;
                margin-right: 14px;
            }
        }
        .btn-upload-img {
            display: flex;
            align-items: center;
        }
    }
`;
