import { useApi } from "@/main/contexts/apiContext";
import imgLogoMenu from "@/presentation/assets/icone/logoMenu.svg";

import { MenuIcone } from "@/presentation/components/MenuIcone";
import { Container } from "./styles";

import { Me } from "@/domain/models";

export function Menu() {
    const { getStorage, clearStorage } = useApi();
    const user = getStorage<Me>("me");

    return (
        <Container>
            <div className="container-img">
                <img src={imgLogoMenu} alt="" />
            </div>

            <MenuIcone />

            <div className="container-footer">
                <p>
                    Olá <br />
                    {user.name}
                </p>
                <button onClick={() => clearStorage()}>
                    <p>Sair</p>
                </button>
            </div>
        </Container>
    );
}
