import { memo } from "react";
import { Text } from "alisson-application";
import Countdown, { CountdownRenderProps } from "react-countdown";

import Timer from "@/presentation/assets/icone/timer.svg";

interface CountdownProps {
    setTimer: (value: boolean) => void;
    setDisabled?: (value: boolean) => void;
}

function CountDown(props: CountdownProps) {
    const { setTimer, setDisabled } = props;
    return (
        <Countdown
            date={Date.now() + 20000}
            onComplete={() => {
                setTimer(false);
                if (setDisabled) {
                    setDisabled(false);
                }
            }}
            renderer={(props: CountdownRenderProps) => {
                const { hours, minutes, seconds } = props;

                return (
                    <div className="timer">
                        <img src={Timer} alt="" />
                        <Text bold={true} ml="5px">
                            {hours <= 9 ? "0" + hours : hours}
                        </Text>
                        :
                        <Text bold={true}>
                            {minutes <= 9 ? "0" + minutes : minutes}
                        </Text>
                        :
                        <Text bold={true}>
                            {seconds <= 9 ? "0" + seconds : seconds}
                        </Text>
                    </div>
                );
            }}
        />
    );
}

export const CountDownMemo = memo(CountDown);
