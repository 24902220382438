import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { CryptoParam, CryptoModel } from "@/domain/models";
import { Crypto } from "@/domain/useCases";

export class RemoteCrypto implements Crypto {
    constructor(
        private readonly httpClient: HttpClient<CryptoModel>,
        private readonly url: string = "crypto"
    ) {}

    async encrypt(params: CryptoParam): Promise<CryptoModel> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.url,
            body: this.formatParams(params),
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response?.body) {
                    return response.body;
                }
                return {} as CryptoModel;
            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }

    formatParams(value: CryptoParam): CryptoParam {
        return {
            value: value.value.replace(/\D/g, ""),
        };
    }
}
