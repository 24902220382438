import { Text, BoxProps } from "alisson-application";
import { Container } from "./styles";

export interface ComplementBlock extends BoxProps {
    values: Values[];
}
export interface Values {
    label: string;
    value: string;
}

export function ComplementBlock(props: ComplementBlock) {
    const { values, ...box } = props;

    return (
        <Container {...box}>
            <div>
                {values.map((item, key) => {
                    return (
                        <div
                            className={`item ${
                                values.length === 1 ? "span" : ""
                            }`}
                            key={key}
                        >
                            <Text
                                as="span"
                                fontWeight="700"
                                fontSize="xs"
                                mb="5px"
                            >
                                {item.label}
                            </Text>

                            <Text
                                color="secondarydark"
                                as="p"
                                style={{
                                    textTransform: "unset",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: item.value,
                                }}
                            ></Text>
                        </div>
                    );
                })}
            </div>
        </Container>
    );
}
