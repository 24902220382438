import { useEffect, useState, CSSProperties } from "react";
import { Text } from "alisson-application";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Container, ContainerDimmed } from "./styles";
import imgRegulamento from "@/presentation/assets/icone/regulamento.svg";

interface DrafEditorProps {
    onChange: (value: string) => void;
    values?: string;
    title: string;
    testid?: string;
    dimmed?: boolean;
    style?: CSSProperties;
}

export function DrafEditor(props: DrafEditorProps) {
    const { onChange, values, title, dimmed, style } = props;

    const [editorState, setEditorState] = useState<EditorState>(
        EditorState.createEmpty()
    );

    useEffect(() => {
        if (values) {
            const contentState = ContentState.createFromBlockArray(
                htmlToDraft(values).contentBlocks
            );

            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, [values]);

    const SelectedContainer = dimmed ? ContainerDimmed : Container;

    return (
        <SelectedContainer style={style}>
            <div className="container-title">
                <img src={imgRegulamento} alt="" />
                <Text as="p" color="primary" ml="11px">
                    {title}
                </Text>
            </div>

            <div data-testid={props.testid} id={props.testid}>
                <Editor
                    toolbarClassName="toolbar-class"
                    toolbar={{
                        options: [
                            "inline",
                            "fontSize",
                            "list",
                            "textAlign",
                            "colorPicker",
                            "link",
                        ],
                        inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                            ],
                        },

                        fontSize: {
                            options: [
                                8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48,
                                60, 72, 96,
                                                        ],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },

                        textAlign: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ["left", "center", "right", "justify"],
                            left: { className: undefined },
                            center: { className: undefined },
                            right: { className: undefined },
                            justify: { className: undefined },
                        },

                        list: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: [
                                "unordered",
                                "ordered",
                                "indent",
                                "outdent",
                            ],
                            unordered: { className: undefined },
                            ordered: { className: undefined },
                            indent: { className: undefined },
                            outdent: { className: undefined },
                        },

                        colorPicker: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            colors: [
                                "rgb(97,189,109)",
                                "rgb(26,188,156)",
                                "rgb(84,172,210)",
                                "rgb(44,130,201)",
                                "rgb(147,101,184)",
                                "rgb(71,85,119)",
                                "rgb(204,204,204)",
                                "rgb(65,168,95)",
                                "rgb(0,168,133)",
                                "rgb(61,142,185)",
                                "rgb(41,105,176)",
                                "rgb(85,57,130)",
                                "rgb(40,50,78)",
                                "rgb(0,0,0)",
                                "rgb(247,218,100)",
                                "rgb(251,160,38)",
                                "rgb(235,107,86)",
                                "rgb(226,80,65)",
                                "rgb(163,143,132)",
                                "rgb(239,239,239)",
                                "rgb(255,255,255)",
                                "rgb(250,197,28)",
                                "rgb(243,121,52)",
                                "rgb(209,72,65)",
                                "rgb(184,49,47)",
                                "rgb(124,112,107)",
                                "rgb(209,213,216)",
                            ],
                        },
                    }}
                    editorState={editorState}
                    onEditorStateChange={(newState) => {
                        setEditorState(newState);
                        onChange(
                            draftToHtml(
                                convertToRaw(newState.getCurrentContent())
                            )
                        );
                    }}
                />
            </div>
        </SelectedContainer>
    );
}
