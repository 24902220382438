import { QuantityCard } from "@/presentation/components/QuantityCard";

import { Container } from "./styles";

interface QuantityListProps {
    total: number;
    rewarded: number;
    avaliable: number;
    revoked: number;
    book_stock?: number;
}

export function QuantityList(props: QuantityListProps) {
    const { avaliable, revoked, rewarded, total, book_stock } = props;

    return (
        <Container>
            <div className="container-cards">
                <QuantityCard
                    color="primary"
                    title={
                        book_stock === 1
                            ? "Vouchers disponíveis"
                            : "Vouchers resgatados"
                    }
                    quantity={total}
                />
                <QuantityCard
                    color="warning"
                    title="Qtd. de resgates"
                    quantity={rewarded}
                />
                <QuantityCard
                    color="success"
                    title="Qtd. disponíveis"
                    quantity={avaliable}
                />
                <QuantityCard
                    color="failure"
                    title="Qtd. estornados"
                    quantity={revoked}
                />
            </div>
        </Container>
    );
}
