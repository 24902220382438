import { Theme } from "alisson-application";

export const lightColors = {
    white: "#FFFFFF",
    black: "#000",

    primary: "#6F3E98",
    primaryBright: "#0A7AC4",
    primaryDark: "#0261A0",

    secondary: " #efefef",
    secondarybright: "#f9f9f9",
    secondarydark: "#5E5F5F",

    tertiary: "#EDEDED",
    tertiarybright: "#D4D4D4",
    tertiarydark: "#D8D8D8",

    input: "#5E5F5F",

    backgroundAlt: "#F17E21",

    failure: "#D50632",

    success: "#57B847",
    warning: "#F17E21",

    contrast: "#fff",
};

export const fonts = {
    xl: "2.9375rem", // 47px
    lg: "1.625rem", // 26px
    mdl: "1.25rem", // 24px
    md: "1rem", // 16px
    sm: "0.9375rem", // 15px
    xs: "0.875rem", // 14px
    xss: "0.75rem", // 12px
};

export const styleGuide = {
    h1: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "900",
        lineHeight: "22px",
        letterSpacing: "auto",
        textTransform: "uppercase",
        fontSize: fonts.mdl,
    },
    h2: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "31px",
        letterSpacing: "auto",
        textTransform: "none",
        fontSize: fonts.lg,
    },
    h3: {
        fontFamily: "Lexend",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "120%",
        letterSpacing: "auto",
        textTransform: "uppercase",
        fontSize: fonts.mdl,
    },
    h4: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "900",
        lineHeight: "120%",
        letterSpacing: "auto",
        textTransform: "uppercase",
        fontSize: "24px",
    },
    h5: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "100%",
        letterSpacing: "auto",
        fontSize: fonts.xs,
    },
    h6: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "17px",
        letterSpacing: "0.02em",
        fontSize: fonts.md,
    },
    p: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "100%",
        letterSpacing: "auto",
        textTransform: "uppercase",
        fontSize: fonts.sm,
    },

    span: {
        fontFamily: "Lato",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "100%",
        letterSpacing: "auto",
        textTransform: "none",
        fontSize: fonts.xss,
    },
};

export const colors = {
    ...lightColors,
    background: "#FAF9FA",
    backgroundDisabled: "#E9EAEB",
    cardBorder: "#E7E3EB",
    dropdown: "#F6F6F6",
    dropdownDeep: "#EEEEEE",
    invertedContrast: "#FFFFFF",
    inputSecondary: "#d7caec",
    text: "#280D5F",
    textDisabled: "#BDC2C4",
    textSubtle: "#7A6EAA",
    disabled: "#E9EAEB",
    gradients: {
        bubblegum: "linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)",
        inverseBubblegum:
            "linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)",
        cardHeader: "linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)",
        blue: "linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)",
        violet: "linear-gradient(180deg, #8b288c 0%, #6f3e98 92.92%);",
        violetAlt: "linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)",
        gold: "linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)",
    },
};

export const themeColors: Theme = {
    siteWidth: 1200,
    isDark: false,
    colors: colors,
    fonts: fonts,
    styledGuide: styleGuide,
};
