import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { DetailVoucherModel } from "@/domain/models";
import { ListDetailVoucher } from "@/domain/useCases";
import { NumberFormat } from "@/services/number";

export class RemoteDetailVoucher implements ListDetailVoucher {
    constructor(
        protected readonly httpClient: HttpClient<DetailVoucherModel>,
        protected readonly urlDetailVoucher: string = "action/detailVoucher"
    ) {}

    async loadAllDetailVoucher(id: number): Promise<DetailVoucherModel> {
        const response = await this.httpClient.request({
            method: "get",
            url: `${this.urlDetailVoucher}/${id}`,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    return this.format(response.body);
                }
                return [] as DetailVoucherModel;

            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [] as DetailVoucherModel;
            default:
                throw new UnexpectedError();
        }
    }

    format(detailVoucher: DetailVoucherModel): DetailVoucherModel {
        const numberFormat = new NumberFormat();
        const updatedDetailVoucher = detailVoucher.map((voucher) => {
            return {
                ...voucher,
                qtd_total: numberFormat.formatNumber(voucher.qtd_total),
                qtd_assigned: numberFormat.formatNumber(voucher.qtd_assigned),
                qtd_saldo: numberFormat.formatNumber(voucher.qtd_saldo),
                qtd_estornado: numberFormat.formatNumber(voucher.qtd_estornado),
            };
        }) as unknown as DetailVoucherModel;

        return updatedDetailVoucher;
    }

    convertNumnberInDecimal(number: number) {
        return parseFloat(
            number.toLocaleString("pt-BR", { minimumFractionDigits: 2 })
        );
    }
}
