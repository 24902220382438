import React, { createContext, useContext, useState } from "react";
import { useDetailAction } from "@/main/hooks/detailAction";
import { ActionCustumizeParams } from "@/domain/models";
import { toast } from "react-toastify";

interface UseDetailActionInformationProps {
    children: React.ReactNode | React.ReactNode[];
}

export interface UseStateDetailActionInformation {
    handleChange(
        key: keyOfActionProductsCustomize<ActionCustumizeParams>,
        value: string
    ): Promise<void>;
    loading: boolean;
}

const Context = createContext<UseStateDetailActionInformation>(
    {} as UseStateDetailActionInformation
);

export type keyOfActionProductsCustomize<T> = keyof T;
export function UseDetailActionInformationProvider(
    props: UseDetailActionInformationProps
) {
    const { children } = props;
    const { custumize, information, stateHistory, setFindActionData } =
        useDetailAction();
    const [loading, setLoading] = useState(false);

    async function handleChange(
        key: keyOfActionProductsCustomize<ActionCustumizeParams>,
        value: string
    ) {
        try {
            setLoading(true);
            const response = await custumize.actionCustumize({
                [key]: value,
                id: stateHistory.id,
            });

            setFindActionData(information.formatFindById(response));
            toast.success("Atualizado com sucesso");
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao atualizar");
            setLoading(false);
        }
    }

    return (
        <Context.Provider
            value={{
                handleChange,
                loading,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useDetailActionInformation() {
    const context = useContext(Context);
    return context;
}
