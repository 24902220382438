import { ContractorsActionsProvider } from "@/main/hooks/userContractorActions";
import { UseUserProvider } from "@/main/hooks/userADM";
import { CreateADMUserProvider } from "@/main/hooks/useCreateADMUserContext";
import { EditUsersADM } from "@/presentation/components/EditUsersADM";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { RemoteListUsers } from "@/data/useCases/listUsersADM/remoteListUsersADM";
import { RemoteListContractorsActions } from "@/data/useCases/listContractorsActions/remoteListContractorsActions";
import { RemoteCreateADMUser } from "@/data/useCases/remoteCreateADMUser/remoteCreateADMUser";


export function PageEditUsersADM() {
  const axios = new AxiosHttpClient();
  const listUsers = new RemoteListUsers(axios);
  const listContractorsActions = new RemoteListContractorsActions(axios);
  const createADMUser = new RemoteCreateADMUser(axios);

  return (
    <UseUserProvider listUsers={listUsers}>
      <ContractorsActionsProvider listContractorsActions={listContractorsActions}>
        <CreateADMUserProvider createADMUserUseCase={createADMUser}>
          <EditUsersADM />
        </CreateADMUserProvider>
      </ContractorsActionsProvider>
    </UseUserProvider>
  );
}
