import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { ActionProductsRevokeParams } from "@/domain/models";
import { ActionProductsRevoke } from "@/domain/useCases";

export class RemoteActionProductsRevoke implements ActionProductsRevoke {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlProductRevoke: string = "action/products/revoke"
    ) {}

    async revoke(params: ActionProductsRevokeParams): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: `${this.urlProductRevoke}/${params.id}`,
            body: params,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            default:
                throw new UnexpectedError();
        }
    }
}
