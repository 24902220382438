import styled from "styled-components";
import { Container as Button } from "@/presentation/components/Button";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    background-color: ${(props) => props.theme.colors.tertiary};
    padding: 0px 30px;

    ${Button} {
        width: 170px;
    }

    .container-text {
        width: 224px;
        span {
            position: relative;
            div[role="status"] {
                position: relative;
                top: 8px;
                left: 7px;
            }
        }
    }

    label {
        width: 546px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${(props) => props.theme.colors.white};
        border-radius: 5px;
        padding-left: 23px;
        padding-right: 11px;
        cursor: pointer;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: ${(props) => props.theme.colors.secondarydark};
        }

        button {
            width: 32px;
            height: 32px;
            background-color: ${(props) => props.theme.colors.secondarydark};
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }
`;
