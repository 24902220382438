import { ListDetailVoucher, ListActions } from "@/domain/useCases";

import React, { createContext, useContext, useEffect, useState } from "react";
import { Action } from "@/domain/models";
interface UseActionProps {
    children: React.ReactNode | React.ReactNode[];
    action: ListActions;
    detailVoucher: ListDetailVoucher;
}

export interface UseStateAction {
    actions: Action[];
    loading: boolean;

    fetchData(page?: number): Promise<void>;

    action: ListActions;
    detailVoucher: ListDetailVoucher;

    activeGroup: boolean;
    setActiveGroup: (value: boolean) => void;

    activeFilter: boolean;
    setActiveFilter: (value: boolean) => void;

    setFilter: (value: string) => void;

    rangeDate: string[];
    setRangeDate: (value: string[]) => void;

    status: any;
    setStatus: any;
}

const Context = createContext<UseStateAction>({} as UseStateAction);

export function UseActionProvider(props: UseActionProps) {
    const { children, action, detailVoucher } = props;
    const [activeGroup, setActiveGroup] = useState(false);
    const [activeFilter, setActiveFilter] = useState(false);
    const [actions, setActions] = useState<Action[]>([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState<string>("");
    const [rangeDate, setRangeDate] = useState<string[]>([]);
    const [status, setStatus] = useState<string>("");
    const [pagina, setPagina] = useState<number>();

    async function fetchData(page?: number) {
        try {
            const data = await action.filterBack(filter, status, page);
            setPagina(page);
            setActions(data);
            setLoading(false);
        } catch (error: any) {
            console.log(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        async function fetchDataInicio() {
            try {
                const data = await action.loadAll();
                setActions(data);
                setLoading(false);
            } catch (error: any) {
                console.log(error);
                setLoading(false);
            }
        }
        fetchDataInicio();
    }, []);

    useEffect(() => {
        // setActions(action.filter(filter, rangeDate));
        async function fetchDataFiltro() {
            const dataFiltro = await action.filterBack(filter, status, pagina);
            setActions(dataFiltro);
        }
        fetchDataFiltro();
    }, [filter, rangeDate, status]);

    return (
        <Context.Provider
            value={{
                action,
                actions,
                rangeDate,
                setRangeDate,
                loading,
                detailVoucher,
                activeGroup,
                setActiveGroup,
                activeFilter,
                setActiveFilter,
                setFilter,
                status,
                setStatus,
                fetchData,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useAction() {
    const context = useContext(Context);
    return context;
}
