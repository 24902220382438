import { useEffect, useState } from "react";

import { Header } from "@/presentation/components/Header";
import { ModalSync } from "@/presentation/components/ProcessandoSync/modal";
import { Container as Button } from "@/presentation/components/Button";

import imgConversor from "@/presentation/assets/icone/conversor-header.svg";

import { Container } from "./styles";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { HttpStatusCode } from "@/data/protocols/http";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { UnexpectedError } from "@/domain/errors";

type PendingOrdersResponse = {
    ordersWaiting: number;
};

type ProcessPendingOrdersRequest = {
    qty: number;
};

type ProcessPendingOrdersResponse = {
    message: string;
};

export function ProcessandoSync() {
    const [pendingOrders, setPendingOrders] = useState(0);

    const [isOpen, setIsOpen] = useState(false);
    const [mensagem, setMensagem] = useState();

    async function fetchPendingOrders() {
        const httpClient = new AxiosHttpClient<PendingOrdersResponse>();

        const response = await httpClient.request({
            url: "devtools/sync/list",
            method: "get",
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                setPendingOrders(response.body.ordersWaiting);
                break;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            default:
                throw new UnexpectedError();
        }
    }

    async function processPendingOrders(quantity: any) {
        const httpClient = new AxiosHttpClient<ProcessPendingOrdersResponse>();

        const response = await httpClient.request({
            url: "devtools/sync/dispatch",
            method: "post",
            body: { qty: quantity.value } satisfies ProcessPendingOrdersRequest,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                setIsOpen(true);
                setMensagem(response.body.message);
                console.log(response.body.message);
                break;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            default:
                throw new UnexpectedError();
        }
    }

    useEffect(() => {
        fetchPendingOrders();
    }, []);

    return (
        <>
            <Container>
                <Header
                    icone={imgConversor}
                    title="Resgates PROCESSANDO"
                ></Header>
                <div className="conteudo">
                    <h1>{pendingOrders.toString()}</h1>
                    <div className="processo">
                        <p>
                            Disparar processo para fazer sincronia separadamente
                        </p>
                        <p>Quantos deseja processar?</p>
                        <div className="container-input">
                            <input
                                type="text"
                                placeholder="Digite"
                                id="qtd-process"
                            />
                        </div>
                        <Button
                            type="button"
                            onClick={() =>
                                processPendingOrders(
                                    document.getElementById("qtd-process")
                                )
                            }
                        >
                            Processar agora
                        </Button>
                    </div>
                </div>
            </Container>
            <ModalSync
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                message={mensagem}
            />
        </>
    );
}
