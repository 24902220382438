import styled from "styled-components";

export const Container = styled.div`
    .container-choices {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0px;
        }
        .item {
            display: flex;
            align-items: center;
            background: ${(props) => props.theme.colors.tertiary};
            border-radius: 5px;
            padding: 0px 47px;
            height: 151px;
            h1 {
                margin-left: 31px;
                text-transform: unset;
            }
        }
    }
`;
