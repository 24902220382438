import { useEffect, useState } from "react";
import { Box } from "alisson-application";
import { Container as Button } from "@/presentation/components/Button";
import { DrafEditor } from "@/presentation/components/Editor";
import { useModal } from "@/main/contexts/modal";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";

import { FormFiles } from "./file";
import { FormList } from "./list";
import { validationLogin, Values, initialValues } from "./config";
import { Text } from "alisson-application";

import imgFechar from "@/presentation/assets/icone/close.svg";

import { Container } from "./styles";

export function FormEditGroup() {
    const [values, setValues] = useState<Values>(initialValues);
    const { handleClose } = useModal();
    const {
        handleUpdateGroup,
        handleRemoveProductsFromGroup,
        handleAddProductsToGroup,
        group,
    } = useDetailActionVoucher();
    const [initialSelectedProducts, setInitialSelectedProducts] = useState<number[]>([]);

    const regex =
        /(?:|[^"]*[;\s])(background-color\s*:[^";]*)|(?:|[^"]*[;\s])(background\s*:[^";]*)/g;

    useEffect(() => {
        setValues({
            name: group.name,
            img: group.image,
            product_configuration_ids: group.products.map(
                (product) => product.product_configuration_id
            ),
            name_description: group.name_description,
            know_more_text: group.know_more_text,
        });

        setInitialSelectedProducts(
            group.products.map((product) => product.product_configuration_id)
        );
    }, [group]);

    return (
        <Container>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={values}
                validationSchema={validationLogin}
                onSubmit={async (values, actions) => {
                    const deselectedProducts = initialSelectedProducts.filter(
                        (id) => !values.product_configuration_ids.includes(id)
                    );
                    const addedProducts =
                        values.product_configuration_ids.filter(
                            (id) => !initialSelectedProducts.includes(id)
                        );
                    console.log("Produtos adicionados:", addedProducts);
                    console.log("ID do grupo:", group.id);
                    await handleUpdateGroup(values, actions);
                    if (deselectedProducts.length > 0) {
                        await handleRemoveProductsFromGroup(
                            group.id,
                            deselectedProducts
                        );
                    }
                    if (addedProducts.length > 0) {
                        await handleAddProductsToGroup(group.id, addedProducts);
                    }
                }}
            >
                {(props: FormikProps<Values>) => {
                    const { values, handleChange, setFieldValue } = props;              
                    return (
                        <Form>
                            <div className="container-input">
                                <Text as="h5" mb="10px">
                                    Nome do grupo
                                </Text>
                                <Field
                                    type="text"
                                    placeholder="Nome do grupo"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                />
                                <ErrorMessage name="name" component="span" />
                            </div>

                            <div className="container-input">
                                <Text as="h5" mb="10px">
                                    Legenda
                                </Text>
                                <Field
                                    type="text"
                                    placeholder="Legenda"
                                    name="name_description"
                                    onChange={handleChange}
                                />
                            </div>

                            <Field component={FormFiles} />
                            <Field 
                                component={FormList} 
                                hideDeleted={true} 
                            />
                            <ErrorMessage
                                name="product_configuration_ids"
                                component="span"
                            />

                            <div className="container-input saiba-mais">
                                <DrafEditor
                                    title="Saiba mais"
                                    testid="know_more_text"
                                    values={
                                        group.know_more_text
                                            ? group.know_more_text
                                            : ""
                                    }
                                    onChange={(values) => {
                                        const html = values
                                            .trim()
                                            .replace(regex, "");

                                        if (
                                            html.replace(/<.*?>/g, "").trim()
                                                .length === 0
                                        ) {
                                            setFieldValue("know_more_text", "");
                                        } else {
                                            setFieldValue(
                                                "know_more_text",
                                                html
                                            );
                                        }
                                    }}
                                />
                            </div>

                            <button
                                onClick={() => handleClose("editGroup")}
                                className="btn-close"
                                type="button"
                            >
                                <img src={imgFechar} alt="" />
                            </button>

                            <Box m="32px auto" width={383}>
                                <Button
                                    disabled={!props.isValid}
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                >
                                    Editar grupo
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
