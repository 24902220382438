import { useMemo, useState } from "react";
import { Money } from "@/services/money/money";

import { ActionProductsListModel } from "@/domain/models";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

type FetchParams = {
    page?: number;
    search?: string;
};

type UseProductsData = {
    data?: ActionProductsListModel;
    isLoading: boolean;
    fetch: (params?: FetchParams) => Promise<void>;
};

function useProducts(): UseProductsData {
    const [isLoading, setIsLoading] = useState(false);
    // const [data, setData] = useState(undefined);
    const [data, setData] = useState<ActionProductsListModel>(
        {} as ActionProductsListModel
    );

    const httpClient = useMemo(() => new AxiosHttpClient(), []);

    function formatProductsList(params: ActionProductsListModel) {
        if (!params.data?.length) return {} as ActionProductsListModel;
        const money = new Money();
        const update = params.data
            .map((param) => {
                return {
                    ...param,
                    valor_reais: money.convertToBRL(param.valor_reais),
                };
            })
            .sort((a, b) => {
                const nomeComparison = a.nome.localeCompare(b.nome);
                const valorA = parseFloat(a.valor_reais.replace(/\D/g, ""));
                const valorB = parseFloat(b.valor_reais.replace(/\D/g, ""));

                if (nomeComparison !== 0) {
                    return nomeComparison;
                }

                return valorA - valorB;
            });
        setData({ ...params, data: update });
    }

    async function fetch(params?: FetchParams) {
        setIsLoading(true);

        const query = new URLSearchParams();

        if (params?.page && params.page > 1) {
            query.set("page", params.page.toString());
        }

        if (params?.search) {
            query.set("filter", params.search);
        }

        httpClient
            .request({
                url: `action/products/listFromStock?${query}`,
                method: "get",
            })
            .then(({ body }) => formatProductsList(body))
            .catch(console.error)
            .finally(() => setIsLoading(false));
    }

    return { fetch, data, isLoading };
}

export { useProducts };
