import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 52px;
    margin-top: 58px;
    button {
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 12px;
        img {
            display: block;
            margin-right: 7px;
        }
    }

    .container-input {
        &.add {
            margin-bottom: 25px;
        }
        button {
            margin-left: auto;
        }
    }
`;
