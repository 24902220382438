import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

interface ReorderVouchersParams {
    action_id: string;
    products: {
        [key: string]: {
            is_group: boolean;
            order: number;
        };
    };
}

export class RemoteReorderVouchers {
    constructor(protected readonly httpClient: AxiosHttpClient) {}

    async reorderVouchers(params: ReorderVouchersParams): Promise<void> {
        const response = await this.httpClient.request({
            url: `action/sort/products`,
            method: 'patch',
            body: params,
        });

        console.log("response", response);

        if (response.statusCode !== 200) {
            throw new Error('Erro ao reordenar os vouchers');
        }
    }
}
