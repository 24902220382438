import styled from "styled-components";

export const Container = styled.div`
    position: relative;

    .container-header {
        margin-bottom: 56px;
        h1 {
            text-transform: unset;
        }
    }

    .container-form {
        form {
            width: 668px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 0px 64px;
            .container-input {
                margin-bottom: 22px;
            }
            /* button {
                display: none;
            } */
        }
    }

    .container-actions-form {
        display: flex;
        justify-content: space-between;
        width: 232px;
        position: absolute;
        top: 0px;
        right: 0px;
    }
`;
