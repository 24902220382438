import styled from "styled-components";

interface ContainerProps {
    icone: string;
}

export const Container = styled.div<ContainerProps>`
    background: transparent;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    position: relative;
    &.active {
        background: ${(props) => props.theme.colors.primary};
        &:before {
            background-color: ${(props) => props.theme.colors.white};
        }
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${(props) => props.theme.colors.primary};
        -webkit-mask: url(${(props) => props.icone}) no-repeat;
        -webkit-mask-position: 50% 50%;
        width: 27px;
        height: 27px;
        z-index: 20;
    }
`;
