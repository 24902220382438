import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { ValidationDate } from "@/services/date";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { FindActionModel } from "@/domain/models";
import { FindAction } from "@/domain/useCases";

export class RemoteFindAction implements FindAction {
    constructor(
        protected readonly httpClient: HttpClient<FindActionModel>,
        protected readonly url: string = "action/find"
    ) {}

    async loadByid(id: number): Promise<FindActionModel> {
        const response = await this.httpClient.request({
            method: "get",
            url: `${this.url}/${id}`,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    return this.formatFindById(response.body);
                }
                return {} as FindActionModel;

            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return {} as FindActionModel;
            default:
                throw new UnexpectedError();
        }
    }

    formatFindById(data: FindActionModel) {
        const start_date = new ValidationDate(data.start_date);
        const end_date = new ValidationDate(data.end_date);
        return {
            ...data,
            start_date: start_date.userDateTime(),
            end_date: end_date.userDateTime(),
        };
    }
}
