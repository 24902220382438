import { CreateADMUser } from "@/domain/useCases/createADMUser";
import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";

export class RemoteCreateADMUser implements CreateADMUser {
  private readonly url: string = "users/create";

  constructor(private readonly httpClient: HttpClient<CreateADMUser.Result>) { }

  async execute(data: CreateADMUser.Params): Promise<CreateADMUser.Result> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: data,
    });

    if (
      response.statusCode === HttpStatusCode.created ||
      response.statusCode === HttpStatusCode.ok
    ) {
      if (response.body) {
        return response.body;
      } else {
        return {
          id: 1,
          name: data.name,
          username: data.username,
          email: data.email,
          mobile: data.mobile,
          access_polices_id: data.access_polices_id,
          notify: data.notify,
          actions: data.actions,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        };
      }
    }

    throw new UnexpectedError();
  }
}
