import styled from "styled-components";
import { Container as Button } from "@/presentation/components/Button";

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 5px;
    height: 72px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(5, 1fr);
    text-align: center;
    .item {
        border-right: 1px solid ${(props) => props.theme.colors.white};
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .action-user {
        display: flex;
        align-items: center;
        padding-left: 39px;
        padding-right: 29px;
        .warn {
            margin-right: 60px;
        }
        .show-and-download {
            display: flex;
            justify-content: space-between;
            width: 71px;
            margin-right: 32px;
        }

        .actions-aprove-and-reprove {
            display: flex;
            justify-content: space-between;
        }
    }

    ${Button} {
        width: 81px;
        height: 40px;
        &.reprove {
            font-size: ${(props) => props.theme.fonts.xs};
            color: red;
        }

        &.to-approve {
            color: ${(props) => props.theme.colors.white};
            font-size: ${(props) => props.theme.fonts.xs};
        }
    }
`;
