import styled from "styled-components";

export const Container = styled.div`
    position: relative;

    .container-header {
        margin-bottom: 56px;
        h1 {
            text-transform: unset;
        }
    }

    .container-form {
        form {
            width: 302px;
            .container-input {
                margin-bottom: 22px;
            }
            /* button {
                display: none;
            } */
        }
    }

    .container-actions-form {
        display: flex;
        justify-content: space-between;
        width: 232px;
        position: absolute;
        top: 0px;
        right: 0px;
    }
`;
