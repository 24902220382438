import { Text } from "alisson-application";
import { Modal } from "@/presentation/components/Modal";
import { ContainerModal } from "./styles";

interface ModalSyncProps {
    isOpen: any;
    setIsOpen: any;
    message: any;
}

export function ModalSync(props: ModalSyncProps) {
    const { message, setIsOpen, isOpen } = props;

    function handelClose() {
        setIsOpen(false);
    }

    return (
        <Modal open={isOpen} handleClose={handelClose}>
            <ContainerModal>
                <Text as="p" color="secondarydark">
                    {message}
                </Text>
            </ContainerModal>
        </Modal>
    );
}
