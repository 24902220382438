import { Mixin } from "ts-mixer";
import { RemoteCrypto } from "@/data/useCases/crypto/remoteCrypto";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

const axios = new AxiosHttpClient();

export class Converter extends Mixin(RemoteCrypto) {
    constructor() {
        super(axios);
    }
}
