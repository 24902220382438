import { Box } from "alisson-application";
import { HeaderConversor } from "./Header";
import { ContentText } from "./ContentText";
import { ConversorForm } from "./Form";
import { Cryptography } from "./Cryptography";
import { Container } from "./styles";
import { useConverter } from "@/main/hooks/useConverter";
import { When } from "../when";
import { Loader } from "../Loader";

export function Conversor() {
    const { loading, state } = useConverter();

    return (
        <Container>
            <HeaderConversor />
            <div className="container-convert">
                <Box width="372px">
                    <ContentText />
                    <ConversorForm />
                </Box>

                <Box width="472px" position="relative" height="300px">
                    <When expr={loading}>
                        <Loader />
                    </When>

                    <When expr={Boolean(!loading && state.value_encrypted)}>
                        <Cryptography />
                    </When>
                </Box>
            </div>
        </Container>
    );
}
