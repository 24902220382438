import { Text } from "alisson-application";
import { FormAddGroup } from "@/presentation/components/FormAddGroup";
import imgGruop from "@/presentation/assets/icone/acoes-detalhes.svg";
import { Container } from "./styles";

export function AddGroup() {
    return (
        <Container>
            <div className="container-header">
                <img src={imgGruop} alt="" />
                <Text as="h1" ml="10px">
                    Criar novo grupo
                </Text>
            </div>
            <FormAddGroup />
        </Container>
    );
}
