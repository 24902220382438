import { Text } from "alisson-application";
import { FormEditGroup } from "@/presentation/components/FormEditGroup";
import imgGruop from "@/presentation/assets/icone/acoes-detalhes.svg";
import { Container } from "./styles";

export function EditGroup() {
    return (
        <Container>
            <div className="container-header">
                <img src={imgGruop} alt="" />
                <Text as="h1" ml="10px">
                    Editar grupo
                </Text>
            </div>
            <FormEditGroup />
        </Container>
    );
}
