import React, { createContext, useContext, useCallback } from "react";
import { CreateADMUser } from "@/domain/useCases/createADMUser";

interface CreateADMUserContextType {
  createUser: (data: CreateADMUser.Params) => Promise<CreateADMUser.Result>;
}

const CreateADMUserContext = createContext<CreateADMUserContextType | undefined>(undefined);

export const useCreateADMUserContext = (): CreateADMUserContextType => {
  const context = useContext(CreateADMUserContext);
  if (!context) {
    throw new Error("useCreateADMUserContext deve ser usado dentro de um CreateADMUserProvider");
  }
  return context;
};

interface CreateADMUserProviderProps {
  createADMUserUseCase: CreateADMUser;
  children: React.ReactNode;
}

export const CreateADMUserProvider: React.FC<CreateADMUserProviderProps> = ({
  createADMUserUseCase,
  children,
}) => {
  const createUser = useCallback(async (data: CreateADMUser.Params): Promise<CreateADMUser.Result> => {
    return await createADMUserUseCase.execute(data);
  }, [createADMUserUseCase]);

  return (
    <CreateADMUserContext.Provider value={{ createUser }}>
      {children}
    </CreateADMUserContext.Provider>
  );
};
