import { Route, Redirect } from "react-router-dom";
import { useApi } from "@/main/contexts/apiContext";
import { Menu } from "@/presentation/components/Menu";
import { Loader } from "@/presentation/components/Loader";
import { routesProps } from "@/main/routes/types";
import { AccountModel } from "@/domain/models";
import { usePermissions } from "@/main/contexts/permissions";

import { Container } from "./styles";

type PrivateRouteProps = routesProps;

export default function PrivateRoute(props: PrivateRouteProps) {
    const { isPrivate = false, path, permissions } = props;
    const { getStorage } = useApi();

    const rotasSemMenu = ["/", "/dashboard"];

    const token = getStorage<AccountModel>("access_token");

    const { isLoadingAcessibleRoutes, hasPermission } = usePermissions();

    // nao renderiza a pagina enquanto nao carregar as permissoes
    if (token && isLoadingAcessibleRoutes) {
        return (
            <Container>
                <div className="loader">
                    <Loader />
                </div>
            </Container>
        );
    }

    // redireciona para a raiz quando o usuario nao tem permissao para acessar a pagina
    if (
        permissions &&
        !permissions.every((permission) =>
            hasPermission(permission.path, permission.method)
        )
    ) {
        return <Redirect to="/" from={path} />;
    }

    if (path === "*") {
        return <Redirect to="/" from={path} />;
    }

    // redirecionar rotas inexistentes
    if (path === "*" || path === "/") {
        return <Redirect to="/" from={path} />;
    }

    // rota privada sem token
    if (isPrivate && !token) {
        return <Redirect to="/" from={path} />;
    }

    return (
        <>
            {!rotasSemMenu.includes(path) && <Menu />}
            <div className={`${!rotasSemMenu.includes(path) ? "content" : ""}`}>
                <Route {...props} />
            </div>
        </>
    );
}
