import { ActionCustumizeParams } from "@/domain/models";
import * as Yup from "yup";

export type IValues = Omit<ActionCustumizeParams, "id">;

export const initialValues: IValues = {
    choose_reward_title: "",
    choose_reward_description: "",
    choose_reward_modal_text: "",
    choose_reward_subtitle: "",
    choose_reward_button_text: "",
    disable_stock_number: 0,
};

export const validationLogin = Yup.object().shape({
    choose_reward_title: Yup.string().min(
        4,
        "Nome deve ter no mínimo de 4 caracteres"
    ),
    choose_reward_description: Yup.string().min(
        4,
        "Nome deve ter no mínimo de 4 caracteres"
    ),
    choose_reward_modal_text: Yup.string().min(
        4,
        "Nome deve ter no mínimo de 4 caracteres"
    ),
    choose_reward_subtitle: Yup.string().min(
        4,
        "Nome deve ter no mínimo de 4 caracteres"
    ),
    choose_reward_button_text: Yup.string().min(
        4,
        "Nome deve ter no mínimo de 4 caracteres"
    ),
    disable_stock_number: Yup.number(),
});
