import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 2px;
    width: 880px;
    margin-top: 12px;
    button {
        background-color: ${(props) => props.theme.colors.tertiarydark};
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 5px 5px 0px 0px;
        transition: all 0.5s;
        h5 {
            color: ${(props) => props.theme.colors.secondarydark};
            transition: all 0.5s;
        }
        &.active {
            background-color: ${(props) => props.theme.colors.secondarybright};
            transition: all 0.5s;
            h5 {
                color: ${(props) => props.theme.colors.primary};
                transition: all 0.5s;
            }
        }
    }
`;
