export type CharCounterProps = {
    value?: string;
    total: number;
};

function CharCounter({ value, total }: CharCounterProps) {
    const length = value?.length ?? 0;

    if (length === 0) {
        return (
            <span className="charCounter">{total} caracteres restantes</span>
        );
    }

    if (length === 1) {
        return (
            <span className="charCounter">
                {total - length} caractere restante
            </span>
        );
    }

    return (
        <span className="charCounter">
            {total - length} caracteres restantes
        </span>
    );
}

export { CharCounter };
