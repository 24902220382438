import { useDetailActionCustumize } from "@/presentation/components/DetailContent/hooks/detailActionCustumize";
import { ActionChoiceBox } from "@/presentation/components/ActionChoiceBox";
import { ActionCustomRescued } from "@/presentation/components/ActionCustomRescued";
import { ActionCustomgift } from "@/presentation/components/ActionCustomgift";
import { ActionCustomVideo } from "@/presentation/components/ActionCustomVideo";
import { DetailCustomLogin } from "@/presentation/components/DetailCustomLogin";
import { Container } from "./styles";
export function DetailActionCustomization() {
    const { tabs } = useDetailActionCustumize();

    return (
        <Container>
            <div
                className={`container-tab remove-padding ${
                    tabs[0].active ? "active" : ""
                }`}
                data-testid="container-tab-choice"
            >
                {tabs[0].viewer && <ActionChoiceBox />}
            </div>

            <div
                className={`container-tab remove-padding ${
                    tabs[1].active ? "active" : ""
                }`}
            >
                {tabs[1].viewer && <DetailCustomLogin />}
            </div>
            <div
                className={`container-tab remove-padding ${
                    tabs[4].active ? "active" : ""
                }`}
            >
                {tabs[4].viewer && <ActionCustomVideo />}
            </div>
            <div
                className={`container-tab remove-padding ${
                    tabs[2].active ? "active" : ""
                }`}
            >
                {tabs[2].viewer && <ActionCustomgift />}
            </div>
            <div
                className={`container-tab remove-padding ${
                    tabs[3].active ? "active" : ""
                }`}
            >
                {tabs[3].viewer && <ActionCustomRescued />}
            </div>
        </Container>
    );
}
