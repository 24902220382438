import { Text, Flex } from "alisson-application";
import { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik, FormikProps } from "formik";
import { useModal } from "@/main/contexts/modal";
import { CheckBox } from "@/presentation/components/CheckBox";
import { CharCounter } from "@/presentation/components/CharCounter";
import { Container as Button } from "@/presentation/components/Button";
import { useDetailActionCustumize } from "@/presentation/components/DetailContent/hooks/detailActionCustumize";
import { validationLogin, initialValues, IValues } from "./config";
import { useDetailAction } from "@/main/hooks/detailAction";

export function FormActionCustomgift() {
    const { updatePremioResgate, loading } = useDetailActionCustumize();
    const { findActionData } = useDetailAction();
    const [values, setValues] = useState<IValues>(initialValues);

    const { setState, state } = useModal();

    useEffect(() => {
        if (findActionData.id) {
            setValues({
                choose_reward_title: findActionData.choose_reward_title
                    ? findActionData.choose_reward_title
                    : "",
                choose_reward_description:
                    findActionData.choose_reward_description
                        ? findActionData.choose_reward_description
                        : "",
                choose_reward_subtitle: findActionData.choose_reward_subtitle
                    ? findActionData.choose_reward_subtitle
                    : "",
                choose_reward_button_text:
                    findActionData.choose_reward_button_text
                        ? findActionData.choose_reward_button_text
                        : "",
                disable_stock_number: findActionData.disable_stock_number
                    ? findActionData.disable_stock_number
                    : 0,
            });
        }
    }, [findActionData]);

    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={values}
            validationSchema={validationLogin}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={async (values) =>
                setState({
                    ...state,
                    alert: {
                        active: true,
                        onclick: async () => {
                            await updatePremioResgate(values);
                        },
                        text: "Você deseja realmente editar esses campos?",
                    },
                })
            }
        >
            {(props: FormikProps<IValues>) => {
                const {
                    values,
                    setFieldValue,
                    handleChange: handleChangeFormik,
                } = props;

                return (
                    <Form data-testid="form-login">
                        <div className="item">
                            <Text as="h5" ml="6px" mb="10px">
                                Título
                            </Text>
                            <div className="container-input">
                                <textarea
                                    value={values.choose_reward_title}
                                    name="choose_reward_title"
                                    disabled={loading}
                                    maxLength={65}
                                    onChange={(e) => {
                                        handleChangeFormik(e);
                                    }}
                                />
                                <CharCounter
                                    total={65}
                                    value={values.choose_reward_title}
                                />
                                <ErrorMessage
                                    name="text"
                                    data-testid="error"
                                    component="span"
                                />
                            </div>
                        </div>

                        <div className="item">
                            <Text as="h5" ml="6px" mb="10px">
                                Descrição
                            </Text>
                            <div className="container-input">
                                <textarea
                                    value={values.choose_reward_description}
                                    name="choose_reward_description"
                                    disabled={loading}
                                    maxLength={100}
                                    onChange={(e) => {
                                        handleChangeFormik(e);
                                    }}
                                />
                                <CharCounter
                                    total={100}
                                    value={values.choose_reward_description}
                                />
                                <ErrorMessage
                                    name="textoResgate"
                                    data-testid="error"
                                    component="span"
                                />
                            </div>
                        </div>

                        {/* <div className="item">
                            <Text as="h5" ml="6px" mb="10px">
                                Texto 03
                            </Text>
                            <div className="container-input">
                                <DebounceInput
                                    type="text"
                                    debounceTimeout={1000}
                                    value={values.choose_reward_modal_text}
                                    name="choose_reward_modal_text"
                                    onChange={handleChange}
                                    disabled={loading}
                                />

                                <ErrorMessage
                                    name="textoResgate"
                                    data-testid="error"
                                    component="span"
                                />
                            </div>
                        </div> */}

                        <div className="item">
                            <Text as="h5" ml="6px" mb="10px">
                                Subtítulo
                            </Text>
                            <div className="container-input">
                                <textarea
                                    value={values.choose_reward_subtitle}
                                    name="choose_reward_subtitle"
                                    disabled={loading}
                                    maxLength={100}
                                    onChange={(e) => {
                                        handleChangeFormik(e);
                                    }}
                                />
                                <CharCounter
                                    total={100}
                                    value={values.choose_reward_subtitle}
                                />
                                <ErrorMessage
                                    name="textoResgate"
                                    data-testid="error"
                                    component="span"
                                />
                            </div>
                        </div>

                        <div className="item">
                            <Text as="h5" ml="6px" mb="10px">
                                Texto do botão de escolher prêmio
                            </Text>
                            <div className="container-input">
                                <input
                                    type="text"
                                    value={values.choose_reward_button_text}
                                    name="choose_reward_button_text"
                                    disabled={loading}
                                    maxLength={25}
                                    onChange={(e) => {
                                        handleChangeFormik(e);
                                    }}
                                />
                                <CharCounter
                                    total={25}
                                    value={values.choose_reward_button_text}
                                />
                                <ErrorMessage
                                    name="textoResgate"
                                    data-testid="error"
                                    component="span"
                                />
                            </div>
                        </div>

                        <Flex alignItems="center">
                            <CheckBox
                                name="disable_stock_number"
                                value={values.disable_stock_number ? 0 : 1}
                                checked={
                                    values.disable_stock_number ? true : false
                                }
                                onChange={(values) =>
                                    setFieldValue(
                                        "disable_stock_number",
                                        values.target.checked ? 1 : 0
                                    )
                                }
                            />
                            <Text as="span" color="secondarydark" ml="10px">
                                Ocultar estoque
                            </Text>
                        </Flex>

                        <Button type="submit" disabled={loading}>
                            Salvar
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
}
