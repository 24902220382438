import { useHistory } from "react-router-dom";
import { Text } from "alisson-application";
import imgSetEsq from "@/presentation/assets/icone/seta-esq.svg";
import { Container } from "./styles";

export function ButtonGoback() {
    const history = useHistory();
    return (
        <Container onClick={() => history.goBack()}>
            <img src={imgSetEsq} alt="" />
            <Text as="span" color="secondarydark" ml="11px">
                Voltar
            </Text>
        </Container>
    );
}
