import { AcessibleRoutes } from "@/domain/models";
import React, { createContext, useContext, useEffect, useState } from "react";
import { RemotePermissions } from "@/data/useCases/permissions";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";

type PermissionsProviderProps = {
    children: React.ReactNode;
};

type PermissionsState = {
    acessibleRoutes: AcessibleRoutes[];
    isLoadingAcessibleRoutes: Boolean;
    loadAcessibleRoutes: (token: string) => Promise<void>;
    hasPermission: (path: string, method: string) => Boolean;
};

export const PermissionsContext = createContext<PermissionsState>({
    acessibleRoutes: [],
    isLoadingAcessibleRoutes: true,
    loadAcessibleRoutes: () => Promise.resolve(),
    hasPermission: () => false,
});

export function PermissionsProvider({ children }: PermissionsProviderProps) {
    const [acessibleRoutes, setAcessibleRoutes] = useState<AcessibleRoutes[]>(
        []
    );

    const [isLoadingAcessibleRoutes, setIsLoadingAcessibleRoutes] =
        useState<Boolean>(true);

    useEffect(() => {
        const token = JSON.parse(
            localStorage.getItem("access_token") ?? "null"
        );

        if (token?.access_token) {
            loadAcessibleRoutes(token.access_token);
        }
    }, []);

    async function loadAcessibleRoutes(token: string) {
        const remotePermissions = new RemotePermissions(new AxiosHttpClient());

        try {
            setIsLoadingAcessibleRoutes(true);

            const acessibleRoutes = await remotePermissions.loadAcessibleRoutes(
                token
            );

            setAcessibleRoutes(acessibleRoutes);
        } catch (err) {
            setAcessibleRoutes([]);
            console.error(err);
        } finally {
            setIsLoadingAcessibleRoutes(false);
        }
    }

    function hasPermission(path: string, method: string): Boolean {
        return acessibleRoutes.some(
            (route) => route.path === path && route.methods.includes(method)
        );
    }

    return (
        <PermissionsContext.Provider
            value={{
                acessibleRoutes,
                isLoadingAcessibleRoutes,
                loadAcessibleRoutes,
                hasPermission,
            }}
        >
            {children}
        </PermissionsContext.Provider>
    );
}

export function usePermissions() {
    return useContext(PermissionsContext);
}
