import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";
import { ActionLoadsPedding } from "./actionLoadsPedding";

export function DetailActionLoadsPedding() {
    const { dados } = useDetailActionUser();

    return (
        <>
            {dados.map((data) => {
                return <ActionLoadsPedding {...data} key={data.id} />;
            })}
        </>
    );
}
