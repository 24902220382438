import { useDetailAction, TabId } from "@/main/hooks/detailAction";
import { Text } from "alisson-application";
import { Container } from "./styles";
export function TabDetailActions() {
    const { tabs, updateTab } = useDetailAction();

    return (
        <Container>
            {tabs.map((tab) => {
                if (tab.permission) {
                    return (
                        <button
                            key={tab.id}
                            data-testid={`tab-${tab.className}`}
                            className={`${tab.active ? "active" : ""}`}
                            onClick={() => updateTab(tab.title as TabId)}
                        >
                            <Text as="h5">{tab.title}</Text>
                        </button>
                    );
                }
            })}
        </Container>
    );
}
