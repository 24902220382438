// import { Period } from "@/presentation/components/Period";
import Select, { options } from "@/presentation/components/Select";
import { Container } from "./styles";
import { useAction } from "@/main/hooks/action";
export function FilterMyActions() {
    const { activeFilter, setFilter, setStatus } = useAction();

    const status: options[] = [
        {
            label: "Ativo",
            value: 0,
        },
        {
            label: "Inativo",
            value: 1,
        },
    ];

    return (
        <Container className={`${activeFilter ? "active" : ""}`}>
            <div className="container-input buscar">
                <input
                    type="text"
                    placeholder="Buscar"
                    onChange={(e) => setFilter(e.target.value)}
                />
            </div>

            <Select
                options={status}
                // initalValue={hasObtainMmethods}
                label="Status"
                onChange={(values) => setStatus(values)}
            />
            {/* <FilterActionType /> */}
            {/* <Period /> */}
        </Container>
    );
}
