import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    position: fixed;
    top: 0px;
    left: 137px;
    background-color: ${(props) => props.theme.colors.secondarybright};
    display: grid;
    grid-template-columns: 383px 126px 165px;
    align-items: center;
    padding: 0px 24px;
    grid-gap: 26px;

    height: 0px;
    overflow: hidden;
    transition: all 0.5s;

    z-index: 10;
    &.active {
        height: 92px;
        top: 92px;
        transition: all 0.5s;
    }
    .container-input {
        margin-bottom: 0px;
    }

    h5 {
        font-weight: 400;
    }
`;
