import { Converter } from "@/data/composition";
import { CryptoModel, CryptoParam } from "@/domain/models";
import React, { createContext, useContext, useState } from "react";
interface UseConverterProps {
    children: React.ReactNode;
    converter: Converter;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseConverter {
    convert(values: CryptoParam): Promise<void>;
    loading: boolean;
    state: CryptoModel;
}

const Context = createContext<UseConverter>({} as UseConverter);

export function UseConverterProvider(props: UseConverterProps) {
    const { children, converter } = props;
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({} as CryptoModel);

    async function convert(values: CryptoParam) {
        try {
            setLoading(true);
            const response = await converter.encrypt(values);
            setLoading(false);
            setState(response);
        } catch (error) {
            console.log(error);
            setLoading(false);
            setState({} as CryptoModel);
        }
    }

    return (
        <Context.Provider value={{ convert, state, loading }}>
            {children}
        </Context.Provider>
    );
}

export function useConverter() {
    const context = useContext(Context);
    return context;
}
