import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { SmsSendParams } from "@/domain/models";
import { SmsSend } from "@/domain/useCases/smsSend";

export class RemoteSmsSend implements SmsSend {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly url: string = "sms/send"
    ) {}

    async send(params: SmsSendParams): Promise<Array<string>> {
        const { token, user_id } = params;
        const response = await this.httpClient.request({
            method: "post",
            url: this.url,
            body: {
                user_id,
                by: "sms",
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;
            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
}
