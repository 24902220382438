import { Header } from "@/presentation/components/Header";
import { useNewAction } from "@/main/hooks/newAction";
import { ButtonGoback } from "@/presentation/components/ButtonGoback";
import { TabsNewAction } from "@/presentation/components/TabsNewAction";
import { FormNewActionDataAction } from "@/presentation/components/FormNewActionDataAction";
import { FormNewActionDataAccess } from "@/presentation/components/FormNewActionDataAccess";
import { FormNewActionReviewAndApproval } from "@/presentation/components/FormNewActionReviewAndApproval";
import imgIconeAction from "@/presentation/assets/icone/icon-nova-acao.svg";
import { Container } from "./styles";

export function NewAction() {
    const { tabs } = useNewAction();

    return (
        <Container>
            <Header icone={imgIconeAction} title="NOVA AÇÃO">
                <ButtonGoback />
            </Header>

            <TabsNewAction />

            <div className="forms">
                <div
                    className={`container-tab ${
                        tabs[0].active ? "active" : ""
                    }`}
                >
                    <FormNewActionDataAction />
                </div>

                <div
                    className={`container-tab ${
                        tabs[1].active ? "active" : ""
                    }`}
                >
                    <FormNewActionDataAccess />
                </div>

                <div
                    className={`container-tab ${
                        tabs[2].active ? "active" : ""
                    }`}
                >
                    <FormNewActionReviewAndApproval />
                </div>
            </div>
        </Container>
    );
}
