import styled from "styled-components";

export const Container = styled.div`
    padding: 32px 46px;
    height: 100%;

    .loader {
        position: relative;
        min-height: 300px;
    }
`;
