import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";

import { CreateActionModel } from "@/domain/models/creatActionModel";
import { CreateAction } from "@/domain/useCases/creatAction";
import { ValidationDate } from "@/services/date";

export class RemoteCreatAction implements CreateAction {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly url: string = "action/create"
    ) {}

    async create(params: CreateActionModel): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.url,
            body: this.format(params),
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }

    private format(params: CreateActionModel): CreateActionModel {
        const start_date = new ValidationDate(params.start_date).database();
        const end_date = new ValidationDate(params.end_date).database();

        return this.validate({
            ...params,
            start_date: start_date,
            end_date: end_date,
        });
    }

    private validate(params: CreateActionModel): CreateActionModel {
        const arrValidatorType = ["BIRTH_DATE", "0"];

        if (params?.validator_type) {
            if (arrValidatorType.includes(params?.validator_type)) {
                delete params.validator_label;
            }
        }

        if (parseInt(params.use_validator) === 0) {
            delete params.validator_type;
            delete params.validator_label;
        }

        if (params.book_stock === "") {
            delete params.book_stock;
        }

        return params;
    }
}
