import { Flex, Text } from "alisson-application";
import { Container as Button } from "@/presentation/components/Button";
import { useDetailAction } from "@/main/hooks/detailAction";
import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";
import imgUser from "@/presentation/assets/icone/user.svg";
import { Container } from "./styles";

export function DetailActionUserActions() {
    const { findActionData } = useDetailAction();
    const { loading, setLoading, setSearchUser, fetchData } =
        useDetailActionUser();

    return (
        <Container>
            <Flex className="info-arq" alignItems="center" mr="28px">
                <Text as="span" color="secondarydark" fontSize="xs" mr="7px">
                    Total de usuários
                </Text>

                <img src={imgUser} alt="" />

                <Text as="h5" ml="9px" fontSize="md">
                    {findActionData?.detailed?.quantity_of_users} usuários
                </Text>
            </Flex>

            <div className="fetch-container">
                <div className="container-input buscar">
                    <input
                        type="text"
                        placeholder="Busca"
                        onChange={(e) => setSearchUser(e.target.value)}
                    />
                </div>

                <Button
                    onClick={() => {
                        setLoading(true);
                        fetchData();
                    }}
                    data-testid="button-search"
                    disabled={loading}
                >
                    Buscar
                </Button>
            </div>

            {/* <div className="container-btn">
                <button>
                    <img src={imgDownload} alt="" />
                    <Text fontSize="xs" as="h5">
                        Exportar
                    </Text>
                </button>
                <button>
                    <img src={imgUserPlus} alt="" />
                    <Text fontSize="xs" as="h5">
                        Adicionar usuário
                    </Text>
                </button>
            </div> */}
        </Container>
    );
}
