import { useHistory, Link } from "react-router-dom";
import { Box } from "alisson-application";
import { Container } from "@/presentation/components/Icone";
import { paths } from "./paths";
export function MenuIcone() {
    const history = useHistory();

    return (
        <>
            {paths.map((path, key) => (
                <Box mb="15px" key={key}>
                    <Link to={path.path}>
                        <Container
                            className={`${
                                history.location.pathname === path.path
                                    ? "active"
                                    : ""
                            }`}
                            icone={path.icone}
                            title={path.title}
                        />
                    </Link>
                </Box>
            ))}
        </>
    );
}
