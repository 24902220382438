import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 82px;
    .fetch-container {
        display: grid;
        align-items: center;
        grid-gap: 5px;
        grid-template-columns: 1fr 89px;
        width: 521px;
        .container-input {
            margin-bottom: 0px;
        }
        button {
            width: 89px;
        }
    }

    .container-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 269px;

        button {
            display: flex;
            align-items: center;
            img {
                display: block;
                margin-right: 12px;
            }
        }
    }
`;
