import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    .container-actions {
        width: 485px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 76px;
        position: relative;

        .loader {
            position: relative;
            right: -15px;
            top: 0px;
        }

        button {
            display: flex;
            align-items: center;
        }

        .btn-action-toogle {
            transform: rotateX(0deg);
            transition: all 0.5s;
            position: absolute;
            top: 40%;
            left: -40px;

            &.active {
                transform: rotateX(180deg);
                transition: all 0.5s;
            }
        }
    }
`;
