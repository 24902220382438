import { Mixin } from "ts-mixer";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { RemoteActionCustumize } from "../actionCustumize/remoteActionCustumize";

const axios = new AxiosHttpClient();

export class Custumize extends Mixin(RemoteActionCustumize) {
    constructor() {
        super(axios);
    }
}
