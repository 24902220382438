import { Text } from "alisson-application";

import { Container } from "./styles";

interface CardDetailActionInfoProps {
    img: string;
    title: string;
    name: string;
    onClick?: () => void;
}

export function CardDetailActionInfo(props: CardDetailActionInfoProps) {
    const { img, title, name, onClick } = props;
    return (
        <Container cursor={onClick ? true : false} onClick={onClick}>
            <img src={img} alt="" />
            <Text as="h5" mb="5px" mt="7px">
                {title}
            </Text>

            {title === "URL" ? (
                <a
                    color={name === "Regulamento" ? "secondarydark" : "primary"}
                    href={`https://yetzpromo.com.br/${name}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {name}
                </a>
            ) : (
                <Text
                    as={title === "URL" ? "a" : "p"}
                    fontSize="xs"
                    color={name === "Regulamento" ? "secondarydark" : "primary"}
                >
                    {name}
                </Text>
            )}
        </Container>
    );
}
