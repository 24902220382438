import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { UnexpectedError } from "@/domain/errors";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import {
    ActionReportsDownloadData,
    ActionReportsDownloadModel,
} from "@/domain/models";
import { ActionReportsDownload } from "@/domain/useCases";
import { ValidationDate } from "@/services/date";

export class RemoteActionReportsDownload implements ActionReportsDownload {
    constructor(
        readonly httpGetClient: HttpClient<ActionReportsDownloadData>,
        readonly urlLoadReport: string = "action/reports/download"
    ) {}

    async loadReport(id: number): Promise<ActionReportsDownloadModel[]> {
        const response = await this.httpGetClient.request({
            url: `${this.urlLoadReport}/${id}`,
            method: "get",
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    return this.formatReport(response.body.data);
                }
                return [] as ActionReportsDownloadModel[];
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [] as ActionReportsDownloadModel[];
            default:
                throw new UnexpectedError();
        }
    }
    //Ontem eu fui em duas oficinas, que atendem os requisitos pedidos,
    formatReport(
        data: ActionReportsDownloadModel[]
    ): ActionReportsDownloadModel[] {
        return data.map((item) => {
            const date = new ValidationDate(item.date);

            return {
                ...item,
                date: date.userDateTime(),
            };
        });
    }
}
