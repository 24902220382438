import { Flex, Text } from "alisson-application";
import { useDetailActionCustumize } from "@/presentation/components/DetailContent/hooks/detailActionCustumize";

import { DetailCustomConfig } from "./DetailCustomConfig";

import imgSetaEsqRoxo from "@/presentation/assets/icone/seta-esq-roxo.svg";
import imgSave from "@/presentation/assets/icone/save.svg";

import { Container } from "./styles";

export function DetailCustomLogin() {
    const { updateTab } = useDetailActionCustumize();
    return (
        <Container>
            <div className="container-forms">
                <DetailCustomConfig />
                {/* <DetailCustomInfo />
                <DetailCustomManutencao /> */}
                {/* <DetailCustomForm /> */}
            </div>
            <div className="container-actions-form">
                <button onClick={() => updateTab("personalização")}>
                    <Flex alignItems="center" mr="20px">
                        <img src={imgSetaEsqRoxo} alt="" />
                        <Text as="h5" ml="6px">
                            Voltar
                        </Text>
                    </Flex>
                </button>

                <button>
                    <Flex alignItems="center">
                        <img src={imgSave} alt="" />
                        <Text as="h5" ml="4px">
                            Ver modelo de página
                        </Text>
                    </Flex>
                </button>
            </div>
        </Container>
    );
}
