import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";

export class RemoteDownloadReport {
    constructor(
        private readonly httpPostClient: HttpClient<string>,
        private readonly url: string = "action/reports/download/"
    ) {}

    async download(reportId: string): Promise<void> {
        const response = await this.httpPostClient.request({
            url: `${this.url}${reportId}`,
            method: "post",
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                if (response.body) {
                    window.open(response.body, '_blank');
                } else {
                    throw new UnexpectedError(HttpStatusCode.noContent, "Arquivo não encontrado");
                }
                break;
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            default:
                throw new UnexpectedError(response.statusCode, "Erro inesperado ao tentar baixar o relatório.");
        }
    }
}
