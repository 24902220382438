import { ActionCustumizeParams } from "@/domain/models";
import * as Yup from "yup";

export type IValues = Omit<ActionCustumizeParams, "id">;

export const initialValues: IValues = {
    my_rewards_title: "",
    my_rewards_button_text: "",
    my_rewards_subtitle: "",
    disable_text_my_rewards: 0,
};

export const validationLogin = Yup.object().shape({
    my_rewards_title: Yup.string().min(
        4,
        "Texto (Titulo) deve ter no mínimo de 4 caracteres"
    ),
    my_rewards_button_text: Yup.string().min(
        4,
        "Texto do botão resgatar prêmio deve ter no mínimo de 4 caracteres"
    ),
    my_rewards_subtitle: Yup.string().min(
        4,
        "Texto (Subtitulo) deve ter no mínimo de 4 caracteres"
    ),
    disable_text_my_rewards: Yup.number(),
});
