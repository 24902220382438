import { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik, FormikProps, Field } from "formik";
import { Flex, Text } from "alisson-application";
import { useModal } from "@/main/contexts/modal";
import { IOSSwitch } from "@/presentation/components/Switch";
import { CheckBox } from "@/presentation/components/CheckBox";
import { Container as Button } from "@/presentation/components/Button";

import { useDetailActionCustumize } from "@/presentation/components/DetailContent/hooks/detailActionCustumize";
import { CharCounter } from "@/presentation/components/CharCounter";
import { useDetailAction } from "@/main/hooks/detailAction";

import { Thumbnail } from "./thumbnail";
import { validationLogin, initialValues, IValues } from "./config";
import { StylesDetailCustomForm } from "./styles";
export function FormActionCustomVideo() {
    const [check, setCheck] = useState(false);
    const { findActionData } = useDetailAction();
    const { updateTelaVideo, handleChange, loading } =
        useDetailActionCustumize();
    const [values, setValues] = useState<IValues>(initialValues);
    const use_Video = parseInt(findActionData.use_video + "");

    const { setState, state } = useModal();

    useEffect(() => {
        if (findActionData.id) {
            setCheck(use_Video ? true : false);
            setValues({
                use_video: use_Video ? use_Video : 0,
                video_required: findActionData.video_required
                    ? findActionData.video_required
                    : 0,
                video_title: findActionData.video_title
                    ? findActionData.video_title
                    : "",
                video_subtitle: findActionData.video_subtitle
                    ? findActionData.video_subtitle
                    : "",
                video_link: findActionData.video_link
                    ? findActionData.video_link
                    : "",
                video_thumbnail: findActionData.video_thumbnail
                    ? findActionData.video_thumbnail
                    : "",
                video_button_to_rewards: findActionData.video_button_to_rewards
                    ? findActionData.video_button_to_rewards
                    : "",
            });
        }
    }, [findActionData]);

    return (
        <StylesDetailCustomForm className={`${!check ? "disabled" : ""}`}>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={values}
                validationSchema={validationLogin}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={async (values) =>
                    setState({
                        ...state,
                        alert: {
                            active: true,
                            onclick: async () => {
                                await updateTelaVideo(values);
                            },
                            text: "Você deseja realmente editar esses campos?",
                        },
                    })
                }
            >
                {(props: FormikProps<IValues>) => {
                    const {
                        values,
                        setFieldValue,
                        handleChange: handleChangeFormik,
                    } = props;
                    return (
                        <Form>
                            <div className="container-input header-loagin">
                                <Flex alignItems="center">
                                    <IOSSwitch
                                        name="use_video"
                                        value={values.use_video ? 0 : 1}
                                        checked={
                                            values.use_video ? true : false
                                        }
                                        onChange={async (event: any) => {
                                            await handleChange(event);

                                            setCheck(
                                                values.use_video ? true : false
                                            );
                                        }}
                                    />
                                    <Text
                                        as="span"
                                        color="secondarydark"
                                        ml="10px"
                                    >
                                        Exibir vídeo após login
                                    </Text>
                                </Flex>

                                <Flex alignItems="center">
                                    <CheckBox
                                        name="video_required"
                                        value={values.video_required ? 0 : 1}
                                        checked={
                                            values.video_required ? true : false
                                        }
                                        onChange={(values) =>
                                            setFieldValue(
                                                "video_required",
                                                values.target.checked ? 1 : 0
                                            )
                                        }
                                    />
                                    <Text
                                        as="span"
                                        color="secondarydark"
                                        ml="10px"
                                    >
                                        Vídeo obrigatório
                                    </Text>
                                </Flex>
                            </div>

                            <div className="container-itens">
                                <div className="container-item">
                                    <div className="container-input">
                                        <Text as="h5" mb="10px" pl="16px">
                                            Título do vídeo
                                        </Text>

                                        <input
                                            type="text"
                                            placeholder="Título do vídeo"
                                            value={values.video_title}
                                            name="video_title"
                                            disabled={loading}
                                            maxLength={50}
                                            onChange={(e) => {
                                                handleChangeFormik(e);
                                            }}
                                        />
                                        <CharCounter
                                            total={50}
                                            value={values.video_title}
                                        />

                                        <ErrorMessage
                                            name="video_title"
                                            data-testid="error"
                                            component="span"
                                        />
                                    </div>

                                    <div className="container-input">
                                        <Text as="h5" mb="10px" pl="16px">
                                            Subtítulo do vídeo
                                        </Text>

                                        <textarea
                                            value={values.video_subtitle}
                                            name="video_subtitle"
                                            disabled={loading}
                                            placeholder="Subtítulo do vídeo"
                                            maxLength={115}
                                            onChange={(e) => {
                                                handleChangeFormik(e);
                                            }}
                                        />
                                        <CharCounter
                                            total={115}
                                            value={values.video_subtitle}
                                        />

                                        <ErrorMessage
                                            name="video_subtitle"
                                            data-testid="error"
                                            component="span"
                                        />
                                    </div>
                                </div>

                                <div className="container-item">
                                    <div className="container-input">
                                        <Text as="h5" mb="10px" pl="16px">
                                            Link do vídeo
                                        </Text>

                                        <input
                                            type="text"
                                            value={values.video_link}
                                            name="video_link"
                                            disabled={loading}
                                            placeholder="Link do vídeo"
                                            onChange={(e) => {
                                                handleChangeFormik(e);
                                            }}
                                        />

                                        <ErrorMessage
                                            name="video_link"
                                            data-testid="error"
                                            component="span"
                                        />
                                    </div>

                                    <div className="container-input">
                                        <Text as="h5" mb="10px" pl="16px">
                                            Texto do botão ir para o resgate
                                        </Text>

                                        <input
                                            type="text"
                                            value={
                                                values.video_button_to_rewards
                                            }
                                            name="video_button_to_rewards"
                                            disabled={loading}
                                            placeholder="Resgatar"
                                            maxLength={50}
                                            onChange={(e) => {
                                                handleChangeFormik(e);
                                            }}
                                        />
                                        <CharCounter
                                            total={50}
                                            value={
                                                values.video_button_to_rewards
                                            }
                                        />

                                        <ErrorMessage
                                            name="video_button_to_rewards"
                                            data-testid="error"
                                            component="span"
                                        />
                                    </div>
                                </div>

                                <Field component={Thumbnail} />
                            </div>
                            <Button
                                className="btn-salvar-final"
                                type="submit"
                                disabled={loading}
                            >
                                Salvar
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </StylesDetailCustomForm>
    );
}
