import { Text } from "alisson-application";
import { Voucher } from "./styles";

interface ImgVoucherProps {
    title: string;
    img: string;
}

export function ImgVoucher(props: ImgVoucherProps) {
    return (
        <Voucher>
            <img src={props.img} alt="" />
            <Text as="p" color="black" ml="13px">
                {props.title}
            </Text>
        </Voucher>
    );
}
