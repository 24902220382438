import { Container } from "./styles";
import { Text } from "alisson-application";

interface CardsMinhasAcoesProps {
    title: string;
    src: string;
    path: string;
}

export function CardsMinhasAcoes(props: CardsMinhasAcoesProps) {
    return (
        <Container>
            <div className="container-cards-dashboard">
                <div className="container-logo">
                    <img src={props.src} alt="" />
                </div>
                <Text as="p">{props.title}</Text>
            </div>
        </Container>
    );
}
