import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { ActionLoadsPeddingModel } from "@/domain/models";
import { Container as Button } from "@/presentation/components/Button";
import imgOlho from "@/presentation/assets/icone/olho.svg";
import imgDownload from "@/presentation/assets/icone/download.svg";
import imgAtencao from "@/presentation/assets/icone/atencao-branco.svg";
import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";
import { ValidationDate } from "@/services/date";
import { Container } from "./styles";
import { useState } from "react";

type ActionLoadsPeddingProps = ActionLoadsPeddingModel;

export function ActionLoadsPedding(props: ActionLoadsPeddingProps) {
    const history = useHistory();
    const { date, file, id, identifier, user, qtd_users } = props;
    const { approveUserLoad, repproveUserLoad } = useDetailActionUser();
    const validationDate = new ValidationDate(date);

    const [loadingApprove, setLoadingApprove] = useState(false);
    const [loadingRepprove, setLoadingRepprove] = useState(false);

    return (
        <Container>
            <Text as="h5" color="white" className="item">
                {identifier}
            </Text>
            <Text as="h5" color="white" className="item">
                {validationDate.userDateTime().replace(/-/g, "|")}
            </Text>
            <Text as="h5" color="white" className="item">
                {user}
            </Text>
            <Text as="h5" color="white" className="item">
                {qtd_users}
            </Text>

            <div className="action-user">
                <button className="warn">
                    <img src={imgAtencao} alt="" />
                </button>

                <div className="show-and-download">
                    <button
                        data-testid="button-show-detail-action-user"
                        onClick={() => {
                            history.push(`/detalhe/acao/carga/${id}`);
                        }}
                    >
                        <img src={imgOlho} alt="" />
                    </button>
                    <button
                        onClick={() => {
                            window.open(file, "_blank");
                        }}
                    >
                        <img src={imgDownload} alt="" />
                    </button>
                </div>

                <div className="actions-aprove-and-reprove">
                    <Button
                        variant="white"
                        className="reprove"
                        mr="8px"
                        isLoading={loadingRepprove}
                        disabled={loadingApprove || loadingRepprove}
                        onClick={async () => {
                            setLoadingRepprove(true);
                            await repproveUserLoad(id);
                            setLoadingRepprove(false);
                        }}
                    >
                        Reprovar
                    </Button>
                    <Button
                        variant="success"
                        className="to-approve"
                        isLoading={loadingApprove}
                        disabled={loadingApprove || loadingRepprove}
                        onClick={async () => {
                            setLoadingApprove(true);
                            await approveUserLoad(id);
                            setLoadingApprove(false);
                        }}
                    >
                        Aprovar
                    </Button>
                </div>
            </div>
        </Container>
    );
}
