import { GridColumns } from "@mui/x-data-grid-pro";
import Table from "@/presentation/components/ActionTable";
import imgEditar from "@/presentation/assets/icone/editar.svg";
import imgExcluir from "@/presentation/assets/icone/lixeira.svg";
import { Container } from "./styles";
import { Data } from "./config";

export function TableNeedHelp() {
    const dados: Data[] = [
        {
            id: 1,
            show: "LOJA",
            title: "O que é YetzPromo?",
            description:
                "Uma forma de realizar premiações com vouchers digitais...",
            user_maker: "Edney",
            date_time: "01/01/2021 00:00",
            edit: "",
            delete: "",
        },
        {
            id: 2,
            show: "LOJA",
            title: "O que é YetzPromo?",
            description:
                "Uma forma de realizar premiações com vouchers digitais...",
            user_maker: "Edney",
            date_time: "01/01/2021 00:00",
            edit: "",
            delete: "",
        },
        {
            id: 3,
            show: "LOJA",
            title: "O que é YetzPromo?",
            description:
                "Uma forma de realizar premiações com vouchers digitais...",
            user_maker: "Edney",
            date_time: "01/01/2021 00:00",
            edit: "",
            delete: "",
        },
        {
            id: 4,
            show: "LOJA",
            title: "O que é YetzPromo?",
            description:
                "Uma forma de realizar premiações com vouchers digitais...",
            user_maker: "Edney",
            date_time: "01/01/2021 00:00",
            edit: "",
            delete: "",
        },
    ];

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "Id",
            width: 75,
        },
        {
            field: "show",
            headerName: "Exibir",
            width: 75,
        },
        {
            field: "title",
            headerName: "Título",
            width: 200,
        },
        {
            field: "description",
            headerName: "Descrição",
            width: 253,
        },
        {
            field: "user_maker",
            headerName: "Criado por",
            width: 136,
        },
        {
            field: "date_time",
            headerName: "Data/Hora",
            width: 194,
        },
        {
            field: "edit",
            headerName: "Editar",
            renderCell: () => {
                return (
                    <button>
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },
        {
            field: "delete",
            headerName: "Excluir",
            renderCell: () => {
                return (
                    <button>
                        <img src={imgExcluir} alt="" />
                    </button>
                );
            },
        },
    ];

    return (
        <Container>
            <Table
                rows={dados}
                columns={columns}
                nameCSV="csvName"
                header={false}
            />
        </Container>
    );
}
