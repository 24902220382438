import styled from "styled-components";

export const Container = styled.div`
    padding: 32px 46px;
    width: 800px;
    .container-input {
        input {
            padding-left: 16px;
        }
        &.buscar {
            &:before {
                left: unset;
                right: 22px;
                transform: translateY(-55%);
            }
        }
    }

    .list-vouhcer {
        .loader {
            position: relative;
            min-height: 300px;
        }
    }

    .container-close {
        position: absolute;
        top: 32px;
        right: 46px;
    }
`;
