import styled from "styled-components";
import { Container as Button } from "@/presentation/components/Button";
import ArrowDownIcon from "@/presentation/assets/icone/arrow-down.svg";

export const Container = styled.div`
    width: 1080px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .container-search {
        display: grid;
        grid-template-columns: 1fr 89px;
        grid-gap: 10px;
        align-items: center;
        width: 442px;
        .container-input {
            margin-bottom: 0px;
        }

        ${Button} {
            width: 89px;
        }
    }
`;

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

export const Popup = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #ffffff;
    border-radius: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    max-height: 98vh;
    overflow: hidden;
`;

export const PopupBody = styled.div`
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 98vh;

    input, select {
        height: 40px;
        border-radius: 100px;
        border: 1px solid #ccc;
        color: rgba(0, 0, 0, 0.50);
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        padding: 10px;
    }

    select {
        appearance: none;
        padding-right: 20px;
        background: url(${ArrowDownIcon}) no-repeat right 15px center;
        background-size: 10px;
    }
`;

export const PopupBodyMessage = styled.div`
    padding: 20px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza horizontalmente */
    justify-content: center; /* Centraliza verticalmente */
    overflow-y: auto;
    max-height: 98vh;
    z-index: 2;
    min-height: 100px;

    p {
        font-style: normal;
        font-size: 0.9375rem;
        color: rgb(94, 95, 95);
        line-height: 100%;
        font-family: Lato;
        font-weight: 400;
        text-align: center;
    }
    button {
        width: 50% !important;
    }
`;

export const PopupHeader = styled.div`
    color: #6F3E98;
    padding: 20px;
    text-align: start;
    font-size: 18px;
    font-weight: bold;
    font-family: Lato;

    div {
        display: flex;
        align-items: center;
        height: 20px;
        margin-top: 10px;
        margin-bottom: 10px;

    }

    h2 {
        font-size: 20px;
        font-weight: 900;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;        
    }


`;

export const Divider = styled.div`
    border-bottom: 1px solid #ccc;
    margin-left: 31px;
    margin-right: 31px;
    margin-bottom: 10px;
`;


export const PopupFooter = styled.div`
    padding: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
`;

export const Input = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
`;

export const Select = styled.select`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
`;

export const AddButton = styled.button`
    background: #f68013;
    color: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    width: 100%;

    &:hover {
        background: #d86f11;
    }
`;
export const AddButtonMessage = styled.button`
    background: #f68013;
    color: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    width: 50%;

    &:hover {
        background: #d86f11;
    }
`;

export const PopupMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  z-index: 1050;
  width: 400px;
  max-width: 90%;
`;

export const BackdropMessage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1049;
`;


export const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
`;

export const SwitchLabel = styled.span`
    margin-left: 8px;
    font-size: 14px;
    color: #5E5F5F;
    user-select: none;
`;

export const SearchInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding-bottom: 15px;
    margin-top: 10px;

    input {
        border: none;
    }

    .result-container {
        width: 100%;
        background: #ffffff;
        border-radius: 5px;
        padding: 8px;
        max-height: 100px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }

    .result-item {
        padding: 10px;
        cursor: pointer;
        font-size: 14px;
        background: #fff;
    }

    .result-item:hover {
        background-color: #f5f5f5;
    }
`;

export const SearchIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-left: 25px;
`;

export const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ActionItemStyled = styled.div`
  display: flex;
  align-items: center;
  padding-right: 25px;
  padding-left: 25px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  color: #5E5F5F;
  font-family: Lato;
  font-weight: 400;
  box-sizing: border-box;
  min-height: 40px;

  &:hover {
    background: #EFEFEF;
  }
`;




