import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Text } from "alisson-application";
import { Container as Button } from "@/presentation/components/Button";
import { Url } from "@/services/generateValidUrl/generateValidUrl";
import { ErrorMessage, FieldProps } from "formik";
import { Values } from "./config";
import imgGaleria from "@/presentation/assets/icone/galeria.svg";
import imgEditar from "@/presentation/assets/icone/editar.svg";
import imgLixeira from "@/presentation/assets/icone/lixeira.svg";

export type FormFilesProps = FieldProps<Values>;

export function FormFiles(props: FormFilesProps) {
    const { form } = props;
    const { setFieldValue, values } = form;
    const labelId = "file-" + uuidv4();
    const [file, setFile] = useState<File | null>(null);

    const url = new Url();
    const hasImg = url.isValidURL(values.img);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const currentFile = e.target.files?.[0];
        if (currentFile) {
            const img = new Image();
            img.src = URL.createObjectURL(currentFile);
            img.onload = () => {
                const objFile = {} as any;
                objFile.img = currentFile;

                if (objFile) {
                    setFile(currentFile);

                    setFieldValue("img", currentFile);
                }
            };
        }
    }

    return (
        <>
            {!file?.name && !values.img && (
                <div className="container-upload">
                    <img src={imgGaleria} alt="" />

                    <Text as="span" fontSize="xs" color="secondarydark">
                        Faça o upload da imagem do grupo
                    </Text>

                    <label htmlFor={labelId} className="container-text">
                        <Button className="btn-upload-img">
                            Enviar imagem
                        </Button>
                    </label>
                </div>
            )}

            {file?.name || hasImg ? (
                <div className="container-foto-capa">
                    <img
                        src={
                            file?.name ? URL.createObjectURL(file) : values.img
                        }
                        alt=""
                        className="foto-capa"
                    />
                    <div className="container-btns">
                        <label htmlFor={labelId} className="container-text">
                            <button className="btn-upload-img">
                                <img src={imgEditar} alt="" />
                                Editar
                            </button>
                        </label>
                        <button
                            onClick={() => {
                                setFile(null);
                                setFieldValue("img", null);
                            }}
                        >
                            <img src={imgLixeira} alt="" />
                            Excluir
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )}
            <ErrorMessage name="img" component="span" />

            <input
                type="file"
                name={labelId}
                id={labelId}
                accept="image/*"
                onChange={handleChange}
            />
        </>
    );
}
