import { ActionLoadAll } from "@/domain/useCases";
import React, { createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import { historyProps } from "./detailAction";

interface UseHistoryActionProps {
    children: React.ReactNode | React.ReactNode[];
    action: ActionLoadAll;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseHistoryStateAction {
    stateHistory: historyProps;
    action: ActionLoadAll;
}

const Context = createContext<UseHistoryStateAction>(
    {} as UseHistoryStateAction
);

export function UseActionProvider(props: UseHistoryActionProps) {
    const history = useHistory<historyProps>();
    const stateHistory = history.location.state;
    const { children, ...actions } = props;

    if (history.location.state === undefined) {
        history.push("/acoes");
        return <></>;
    }

    return (
        <Context.Provider value={{ stateHistory, ...actions }}>
            {children}
        </Context.Provider>
    );
}

export function useHistoryAction() {
    const context = useContext(Context);
    return context;
}
