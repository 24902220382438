import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;

    .btn-actions {
        display: flex;
        align-items: center;
        button {
            margin-right: 10px;
        }
    }
`;
