import styled from "styled-components";
import { SimpleChips } from "@/presentation/components/SimpleChips/";
interface ContainerProps {
    background: string;
}

export const Container = styled.div<ContainerProps>`
    width: 136px;
    .container-img {
        position: relative;
        width: 100%;
        height: 140px;
        border-radius: 5px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px;
        background-color: ${(props) =>
            props.background ? props.background : "transparent"};
        ${SimpleChips} {
            position: absolute;
            top: 0px;
            right: 0px;
        }
        img {
            width: 100%;
        }
        button {
            position: absolute;
            top: 6px;
            right: 6px;
        }
    }

    .container-chips {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;
