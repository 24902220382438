import styled from "styled-components";

export const Container = styled.div`
    padding: 32px 46px;
    .container-close {
        position: absolute;
        top: 32px;
        right: 46px;
    }

    .container-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 383px;
        margin: 0 auto;
    }
`;
