import { Text } from "alisson-application";
import { Container as Button } from "@/presentation/components/Button";
import { useModal } from "@/main/contexts/modal";
import { useDetailAction } from "@/main/hooks/detailAction";
import imgLiberarRelatorio from "@/presentation/assets/icone/relatorio.svg";
import imgClose from "@/presentation/assets/icone/close.svg";
import { Container } from "./styles";
import { useState } from "react";
import { toast } from "react-toastify";

export function ModalReport() {
    const [loading, setLoading] = useState(false);
    const { handleClose } = useModal();
    const { report, stateHistory, updateTab } = useDetailAction();

    async function fetchVoucher() {
        setLoading(true);
        try {
            await report.NewReport(stateHistory.id);
            await report.loadReport(stateHistory.id);
            toast.success("Relatório em processamento!");
            updateTab("Relatórios");
            handleClose("report");
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    return (
        <Container>
            <div className="container-header">
                <Text as="h1">
                    <img src={imgLiberarRelatorio} alt="Relatório" /> Exportar
                    relatório
                </Text>
            </div>

            <div className="container-btns">
                <Button
                    onClick={fetchVoucher}
                    isLoading={loading}
                    spinerColor="primary"
                    mb="15px"
                >
                    Relatório de resgates
                </Button>
                {/* <Button>Relatório de acessos (Logins dos Participantes)</Button> */}
            </div>

            <button className="btn-close" onClick={() => handleClose("report")}>
                <img src={imgClose} alt="close" />
            </button>
        </Container>
    );
}
