import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import {
    ActionLoadsPeddingModel,
    DetailActionUserModel,
    FormActionUserLoadModel,
} from "@/domain/models";
import { useDetailAction } from "@/main/hooks/detailAction";
import { useModal } from "@/main/contexts/modal";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";

interface UseDetailActionUserProps {
    children: React.ReactNode | React.ReactNode[];
}

// export interface  LoadAllUser

export interface UseStateDetailActionUser {
    users: DetailActionUserModel;
    setUsers: (data: DetailActionUserModel) => void;

    loading: boolean;
    setLoading: (value: boolean) => void;

    searchUser: string | null;
    setSearchUser: (value: string | null) => void;

    fetchData(page?: number): Promise<void>;

    file: File | null;
    setFile: (file: File | null) => void;

    onSubmitUserLoad(
        values: FormActionUserLoadModel,
        action: FormikHelpers<FormActionUserLoadModel>
    ): Promise<void>;

    dados: ActionLoadsPeddingModel[];

    approveUserLoad(id: number): Promise<void>;
    repproveUserLoad(id: number): Promise<void>;

    resetUser(action_id: number, user_id: number): Promise<void>;
}

const Context = createContext<UseStateDetailActionUser>(
    {} as UseStateDetailActionUser
);

export function UseDetailActionUserProvider(props: UseDetailActionUserProps) {
    const { children } = props;
    const { user, stateHistory } = useDetailAction();
    const { handleClose } = useModal();

    const [users, setUsers] = useState<DetailActionUserModel>(
        {} as DetailActionUserModel
    );
    const [loading, setLoading] = useState(true);
    const [searchUser, setSearchUser] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const [dados, setDados] = useState<ActionLoadsPeddingModel[]>([]);

    async function fetchData(page?: number) {
        const search = searchUser ? searchUser : "";
        const hasSearch = search
            ? `${stateHistory.id}/${search}`
            : stateHistory.id;
        const url = `${hasSearch}${page ? "?page=" + page : ""}`;
        setLoading(true);
        try {
            const response = await user.loadAllUser(url);
            setUsers(response || []);
            setLoading(false);
        } catch (error) {
            setSearchUser(null);
            setLoading(false);
        }
    }

    async function loadsPeddingList() {
        try {
            const response = await user.loadAllPedding({
                id: stateHistory.id,
            });
            setDados(response);
        } catch (error) {
            console.log(error);
        }
    }

    async function onSubmitUserLoad(
        values: FormActionUserLoadModel,
        action: FormikHelpers<FormActionUserLoadModel>
    ) {
        try {
            const response = await user.upload({
                ...values,
                file,
                action_id: stateHistory.id,
            });

            await load();
            action.setSubmitting(false);
            action.resetForm();
            setFile(null);
            toast.success(
                `${response.success} Está carga está em uma fila de processamento`
            );
            handleClose("loadUsers");
        } catch (error) {
            handleClose("loadUsers");
            action.setSubmitting(false);
            action.resetForm();
            setFile(null);
        }
    }

    async function approveUserLoad(id: number) {
        try {
            await user.approve({
                approved: 1,
                load_id: id,
            });
            await loadsPeddingList();
            toast.success("Aprovado com sucesso!");
        } catch (error) {
            console.log(error);
        }
    }

    async function repproveUserLoad(id: number) {
        try {
            await user.repprove({
                approved: 2,
                load_id: id,
            });
            await loadsPeddingList();
            toast.success("Reprovado com sucesso!");
        } catch (error) {
            console.log(error);
        }
    }

    async function resetUser(action_id: number, user_id: number) {
        try {
            await user.reset({
                action_id,
                user_id,
            });
            toast.success("Resetado com sucesso!");
        } catch (error) {
            console.log(error);
        }
    }

    const load = useCallback(async () => {
        const promises = [fetchData, loadsPeddingList];
        return (await Promise.all(promises)).map((promise) => promise());
    }, []);

    useEffect(() => {
        load();
    }, []);

    return (
        <Context.Provider
            value={{
                users,
                dados,
                setUsers,
                loading,
                setLoading,
                searchUser,
                setSearchUser,
                fetchData,
                file,
                setFile,
                onSubmitUserLoad,
                approveUserLoad,
                repproveUserLoad,
                resetUser,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useDetailActionUser() {
    const context = useContext(Context);
    return context;
}
