import { useState } from "react";
import { Box, Text } from "alisson-application";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";
import { useDetailAction } from "@/main/hooks/detailAction";
import imgEditar from "@/presentation/assets/icone/editar.svg";
import { Container } from "./styles";
import { Loader } from "../Loader";
import { When } from "../when";

interface ShowClienAndActionProps {
    contractorName: string;
    name: string;
}

export function ShowClienAndAction(props: ShowClienAndActionProps) {
    const { custumize, stateHistory } = useDetailAction();
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);

    async function handleHandleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setLoading(true);
        e.target.blur();
        try {
            await custumize.actionCustumize({
                name: e.target.value,
                id: stateHistory.id,
            });
            toast.success("Nome da ação alterado com sucesso");
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error("Erro ao editar nome da ação");
            setLoading(false);
        }
    }

    return (
        <Container>
            <Box mb="auto" className="container-client">
                <Text color="secondarydark" as="span">
                    Cliente
                </Text>
                <Text as="h1">{props.contractorName}</Text>
            </Box>

            <Box className="container-acao">
                <Text color="secondarydark" as="span">
                    Nome da Ação
                </Text>

                <When expr={stateHistory?.id ? true : false}>
                    <DebounceInput
                        type="text"
                        debounceTimeout={1000}
                        value={props.name}
                        name="name"
                        onChange={handleHandleChange}
                        disabled={loading || disabled}
                    />
                </When>

                <When expr={!stateHistory?.id ? true : false}>
                    <Text as="h1">{props.name}</Text>
                </When>

                <div className="container-btn">
                    <When expr={loading}>
                        <Loader width={20} height={20} />
                    </When>

                    <When expr={stateHistory?.id ? true : false}>
                        <button
                            onClick={() => {
                                setDisabled(!disabled);
                                if (disabled) {
                                    toast.info("Edição habilitada!");
                                }
                            }}
                        >
                            <img src={imgEditar} alt="Editar" />
                        </button>
                    </When>
                </div>
            </Box>
        </Container>
    );
}
