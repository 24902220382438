import { Formik, Form, Field } from "formik";
import { useNewAction } from "@/main/hooks/newAction";
import { initialValues, validation } from "./config";
import { Left } from "./left";
import { Right } from "./right";
import { Container } from "./styles";

export function FormNewActionDataAction() {
    const { updateTab, setData } = useNewAction();
    return (
        <Container>
            <Formik
                validateOnMount
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={(values: any) => {
                    setData(values);
                    updateTab("Acesso e resgate");
                }}
            >
                {() => {
                    return (
                        <Form>
                            <Field component={Left} />
                            <Field component={Right} />
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
