import { useState } from "react";
import { Text } from "alisson-application";
import { useModal } from "@/main/contexts/modal";
import { useDetailActionVoucher } from "@/presentation/components/DetailContent/hooks/detailActionVoucher";

import imgGruop from "@/presentation/assets/icone/acoes-detalhes.svg";
import imgEditar from "@/presentation/assets/icone/editar.svg";
import imgExcluir from "@/presentation/assets/icone/lixeira.svg";
import imgCheck from "@/presentation/assets/icone/icone-check.svg";
import imgIndicadorCima from "@/presentation/assets/img/indicaro-cima.svg";

import { Container } from "./styles";
import { ActionProductsGroupListModel } from "@/domain/models";

export function VoucherGroup(props: ActionProductsGroupListModel) {
    const { name, image, name_description, know_more_text } = props;
    const { handleDeleteGroup, setGroup } = useDetailActionVoucher();
    const { handleOpen, state, setState } = useModal();

    const [saibaMais, setSaibaMais] = useState<any>(false);

    return (
        <Container>
            <div className="container-detail-group">
                <div className="container-img">
                    <img src={imgGruop} alt="" />
                    <Text as="h5">GRUPO</Text>
                </div>

                <Text as="h5" mb="8px">
                    Nome do grupo
                </Text>
                <Text as="p" color="secondarydark">
                    {name}
                </Text>
                {name_description && (
                    <div>
                        <Text as="h5" mb="6px" mt="10px">
                            Legenda
                        </Text>
                        <Text
                            as="p"
                            color="secondarydark"
                            style={{ textTransform: "lowercase" }}
                        >
                            {name_description}
                        </Text>
                    </div>
                )}
            </div>

            <div className="container-vouchers">
                <Text as="h5" mb="16px">
                    Vouchers agrupados
                </Text>
                <div className="container-voucher">
                    {props.products.map((product) => {
                        return (
                            <Text
                                as="p"
                                ml="3px"
                                color="secondarydark"
                                key={product.id}
                            >
                                <img src={imgCheck} alt="" /> {product.name}
                            </Text>
                        );
                    })}
                </div>
                <div className="container-input saiba-mais">
                    <p
                        className={`link-saiba-mais ${
                            saibaMais === true ? "active" : ""
                        } `}
                        onClick={() => {
                            setSaibaMais(!saibaMais);
                        }}
                    >
                        Saiba mais
                        <img src={imgIndicadorCima} alt="" />
                    </p>
                    <div
                        className={`container-saiba-mais ${
                            saibaMais === true ? "active" : ""
                        } `}
                    >
                        <Text
                            color="secondarydark"
                            as="p"
                            style={{
                                textTransform: "unset",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: know_more_text ? know_more_text : "",
                            }}
                        ></Text>
                    </div>
                </div>
            </div>

            <div className="container-img-group">
                <div className="group">
                    <Text as="h5" mb="8px">
                        Imagem do grupo
                    </Text>
                    <div className="container-img">
                        <img src={image} alt="Group Image" />
                    </div>
                </div>

                <button
                    onClick={() => {
                        setGroup(props);
                        handleOpen("editGroup");
                    }}
                >
                    <img src={imgEditar} alt="Edit Icon" />
                </button>

                <Text
                    as="button"
                    onClick={() => {
                        setState({
                            ...state,
                            alert: {
                                active: true,
                                onclick: () => handleDeleteGroup(props.id),
                            },
                        });
                    }}
                >
                    <img src={imgExcluir} alt="Remove Icon" />
                </Text>
            </div>
        </Container>
    );
}
