import * as Yup from "yup";

export interface FormNewActionDataActionFormik {
    contractor: string;
    contractor_id: number;
    name: string;
    job: string;
    description: string;
    key: string;
    url_da_acao: string;
    start_date: string;
    end_date: string;
    regulamento: string;
    use_lgpd: number;
    lgpd_text: string;
}

export const initialValues: FormNewActionDataActionFormik = {
    contractor_id: 0,
    contractor: "",
    name: "",
    job: "",
    description: "",
    key: "",
    url_da_acao: "",
    start_date: "",
    end_date: "",
    regulamento: "",
    use_lgpd: 0,
    lgpd_text: "",
};

export const validation = Yup.object().shape({
    contractor_id: Yup.number().required("Campo obrigatório"),
    contractor: Yup.string(),
    name: Yup.string()
        .min(4, "Nome deve ter no mínimo de 4 caracteres")
        .required("Campo obrigatório"),
    job: Yup.string()
        .min(4, "Job deve ter no mínimo de 4 caracteres")
        .required("Campo obrigatório"),
    description: Yup.string()
        .min(4, "Descrição deve ter no mínimo de 4 caracteres")
        .required("Campo obrigatório"),
    key: Yup.string().required("Campo obrigatório"),
    url_da_acao: Yup.string().required("Campo obrigatório"),
    start_date: Yup.string().required("Campo obrigatório"),
    end_date: Yup.string().required("Campo obrigatório"),
    regulamento: Yup.string().required("Campo obrigatório"),
    use_lgpd: Yup.number(),
    lgpd_text: Yup.string().when("use_lgpd", {
        is: (val: number) => val === 1,
        then: Yup.string().required("Campo obrigatório"),
        otherwise: Yup.string(),
    }),
});
