import styled from "styled-components";
import { Container as ShowClienAndAction } from "@/presentation/components/ShowClienAndAction/styles";
import { Container as InfoCardAction } from "@/presentation/components/InfoCardAction/styles";
import { Container as ShowDetailAction } from "@/presentation/components/ShowDetailAction/styles";
import { Container as QuantityList } from "@/presentation/components/QuantityList/styles";

export const Container = styled.div`
    position: relative;
    .content-detalhes {
        background: ${(props) => props.theme.colors.secondarybright};
        border: 1px solid ${(props) => props.theme.colors.secondary};
        border-radius: 8px;
        padding: 22px 58px 24px 24px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        box-shadow: 0px;
        transition: box-shadow 0.5s;
        &.table {
            position: absolute;
            z-index: 99;
            box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.7);
            transition: box-shadow 0.5s;
        }
        &.active {
            padding: 21px 29px;
            width: 100%;
            .header {
                span {
                    display: none;
                }
            }
            .detalhes {
                flex-direction: column;
                align-items: unset;
                .content-action {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 16px;
                    position: relative;

                    ${ShowDetailAction} {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;
                        height: auto;
                        .container-item {
                            border-bottom: none;
                            position: relative;
                            &:last-child {
                                &:before {
                                    display: none;
                                }
                            }
                            &:before {
                                content: "";
                                display: block;
                                width: 1px;
                                height: 80%;
                                position: absolute;
                                top: 50%;
                                right: -30%;
                                transform: translateY(-50%);
                                background-color: #d4d4d4;
                            }
                        }
                    }

                    .show-actions {
                        margin-bottom: 16px;
                        height: 140px;
                        display: flex;
                        align-items: center;
                        ${InfoCardAction} {
                            .container-img {
                                margin-bottom: 0px;
                            }
                            .container-chips {
                                position: absolute;
                                bottom: 88px;
                                right: 0px;
                                button {
                                    &:last-child {
                                        margin-left: 4px;
                                    }
                                }
                            }
                        }

                        ${ShowClienAndAction} {
                            padding-top: 0px;
                            height: 97px;
                        }
                    }
                }

                .quantity-list {
                    margin-left: 0px;
                    ${QuantityList} {
                        width: 100%;
                        .container-cards {
                            width: 100%;
                            & > div {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .content-table {
                .container-table {
                    width: 100%;
                }
            }
        }

        ${ShowClienAndAction} {
            margin-left: 32px;
        }

        ${ShowDetailAction} {
            margin-left: auto;
        }

        ${QuantityList} {
            display: flex;
            align-items: center;
            margin-left: auto;
            position: relative;
        }

        .btn-action-toogle {
            position: relative;
            height: 15px;
            width: 15px;
        }

        .header {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 22px;
            right: 58px;
            width: 468px;
            a {
                display: flex;
                align-items: center;
                margin-left: auto;
                color: ${(props) => props.theme.colors.primary};
                img {
                    display: block;
                    margin-bottom: -3px;
                    margin-right: 5px;
                }
            }

            span {
                display: inline-block;
            }
        }

        .detalhes {
            display: flex;
            align-items: center;
            .content-action {
                display: flex;
                .show-actions {
                    display: flex;
                }
            }

            .quantity-list {
                margin-left: 75px;
            }
        }

        .btn-action-toogle {
            position: absolute;
            top: 27px;
            right: 24px;
            transform: rotateX(180deg);
            transition: all 0.5s;
            &.active {
                transform: rotateX(0deg);
                transition: all 0.5s;
            }
        }
    }
`;

export const Voucher = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    img {
        width: 73px;
        border-radius: 8px;
    }
    p {
    }
`;
