import { UserDate } from "@/services/protocols";
export class ValidationDate implements UserDate {
    constructor(private readonly date: string | Date) {}

    user(): string {
        const date = this.date;
        if (!date) return "";
        const dateTime =
            typeof this.date === "object" ? this.date : new Date(date);
        const dateFormated = dateTime.toLocaleDateString("pt-BR");

        if (dateFormated === "Invalid Date") return `${this.date}`;

        const fullDate = dateFormated;
        return fullDate;
    }

    userDateTime(): string {
        const date = this.date;
        if (!date) return "";
        const dateTime =
            typeof this.date === "object" ? this.date : new Date(date);
        const dateFormated = dateTime.toLocaleDateString("pt-BR");
        const timeFormated = dateTime.toLocaleTimeString("pt-BR");

        if (dateFormated === "Invalid Date") return `${this.date}`;

        const fullDate = `${dateFormated} - ${timeFormated}`;
        return fullDate;
    }

    database(): string {
        const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
        if (regex.test(this.date as string)) return this.date as string;
        const partes = (this.date as string).split(" ");
        const dataPartes = partes[0].split("/");
        const horaPartes = partes[1].split(":");

        // Obtém as partes de data e hora
        const dia = dataPartes[0];
        const mes = dataPartes[1];
        const ano = dataPartes[2];
        const hora = horaPartes[0];
        const minuto = horaPartes[1];
        const segundo = horaPartes[2];

        // Formata a data e hora para o padrão MySQL
        const dataMySQL = `${ano}-${mes}-${dia} ${hora}:${minuto}:${segundo}`;

        return dataMySQL;
    }

    convertDateIso(): string {
        const date = this.date;
        if (!date) return "";
        const dateTime =
            typeof this.date === "object" ? this.date : new Date(date);
        const dateFormated = dateTime
            .toLocaleDateString()
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-");

        return dateFormated;
    }
}
