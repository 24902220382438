import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { AccountModel } from "@/domain/models";
import { Authentication, AuthenticationParams } from "@/domain/useCases";

export class RemoteAuthentication implements Authentication {
    constructor(
        private readonly httpClient: HttpClient<AccountModel>,
        private readonly url: string = "login"
    ) {}

    async auth(params: AuthenticationParams): Promise<AccountModel> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.url,
            body: params,
        });

        switch (response.statusCode) {
            
            case HttpStatusCode.ok:
                if (response.body) {
                    return response.body;
                } else {
                    throw new UnexpectedError(HttpStatusCode.badRequest);
                }

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros(response.statusCode);

            default:
                throw new UnexpectedError(response.statusCode);
        }
    }
}
