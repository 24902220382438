import { useState } from "react";
import { toast } from "react-toastify";
import { Text } from "alisson-application";
import { useDetailAction } from "@/main/hooks/detailAction";
import { DataActionProductsListModel } from "@/domain/models";
import { Container } from "./styles";

interface ListQtdProps extends DataActionProductsListModel {
    onchange: (quantity: number, id: number) => void;
}

export function ActionItemQuantityVoucher(props: ListQtdProps) {
    const { findActionData } = useDetailAction();
    const [value, setValue] = useState<number | null>(null);
    const { onchange, ...rest } = props;
    const { foto_capa, nome, valor_reais, total_disponivel } = rest;

    function handlechange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = parseInt(e.target.value.replace(/[^\d]+/g, ""));
        if (total_disponivel < value) {
            toast.error("Quantidade indisponível");
            setValue(0);
            onchange(0, rest.id);
        } else {
            setValue(value);
            onchange(value ? value : 0, rest.id);
        }
    }

    return (
        <Container>
            <div className="container-img-item">
                <div className="container-img">
                    <img src={foto_capa} alt="" />
                </div>

                <div className="container-item">
                    <Text as="h5" color="black" mb="6px">
                        {nome}
                    </Text>
                    <Text as="p" fontSize="xs" color="secondarydark">
                        {valor_reais}
                    </Text>
                </div>
            </div>

            <div className="container-qtd-estoque">
                <Text as="p" fontSize="xs" color="secondarydark">
                    Estoque: {total_disponivel}
                </Text>
            </div>

            {findActionData.book_stock ? (
                <div className="container-qtd">
                    <p>Qtd.</p>
                    <input
                        type="tel"
                        value={value ? value : ""}
                        onChange={handlechange}
                        maxLength={3}
                    />
                </div>
            ) : (
                <> </>
            )}
        </Container>
    );
}
