import styled from "styled-components";
import { Container as Button } from "@/presentation/components/Button";

export const Container = styled.div`
    margin-bottom: 56px;
    .container-input {
        display: flex;
        width: 355px;
        ${Button} {
            position: absolute;
            right: -130px;
            width: 120px;
        }
    }
`;
