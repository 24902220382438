import { Text } from "alisson-application";
import imgCargaModal from "@/presentation/assets/icone/carga.svg";
import imgClose from "@/presentation/assets/icone/close.svg";
import { Container } from "./styles";

export interface ModalUploadLoadProps {
    children: React.ReactNode | React.ReactNode[];
    title: string;
    description?: string;
    handleClose: () => void;
}
export function ModalUploadLoad(props: ModalUploadLoadProps) {
    const {
        children,
        description = "Preencha os campos abaixo para enviar o arquivo.",
        title,
        handleClose,
    } = props;
    return (
        <Container>
            <div className="container-header">
                <Text as="p">{title}</Text>
                <button type="button" onClick={handleClose}>
                    <img src={imgClose} alt="" />
                </button>
            </div>

            <div className="container-content">
                <div className="container-img">
                    <img src={imgCargaModal} alt="" />
                </div>

                <Text as="span">{description}</Text>
            </div>
            {children}
        </Container>
    );
}
