import styled from "styled-components";

export const Container = styled.div`
    .loading {
        min-height: 400px;
        position: relative;
    }

    .container-button button{
        width: auto;
    }
`;
