import styled from "styled-components";

export const Container = styled.div`
    padding: 32px 46px;
    width: 700px;
    .container-input {
        input {
            padding-left: 16px;
        }
        &.buscar {
            &:before {
                left: unset;
                right: 22px;
                transform: translateY(-55%);
            }
        }
    }

    .list-vouhcer {
        .loader {
            position: relative;
            min-height: 300px;
        }
    }

    .container-close {
        position: absolute;
        top: 32px;
        right: 46px;
    }

    .sortable-item img{
        width: 50px;
        height: 50px;
    }

    .opacity {
    opacity: 0.5;
    }
    .opacity img {
        opacity: 0.2;
    }

    .scroll-container {
        max-height: calc(100vh - 270px);
        overflow-y: auto;
        padding-right: 8px;
    }

    .scroll-container::-webkit-scrollbar {
        width: 6px; /* Width of the scrollbar */
    }

    .scroll-container::-webkit-scrollbar-track {
        background: transparent; 
    }

    .scroll-container::-webkit-scrollbar-thumb {
        background: #eee;
        border-radius: 10px;
    }

    .scroll-container::-webkit-scrollbar-thumb:hover {
        background: #ddd; /* Color of the scrollbar thumb on hover */
    }

`;
