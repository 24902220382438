import { toast } from "react-toastify";
import { Text } from "alisson-application";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as FileSaver from "file-saver";
import { useHistory } from "react-router-dom";
import { useDetailAction } from "@/main/hooks/detailAction";
import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";
import { Container as Button } from "@/presentation/components/Button";
import { When } from "@/presentation/components/when";
import { Loader } from "@/presentation/components/Loader";
import imgSetaCima from "@/presentation/assets/icone/seta-cima.svg";
import { useModal } from "@/main/contexts/modal";
import { Container } from "./styles";

export function DetailActionUserUpload() {
    const refFile = `file-${uuidv4()}`;
    const history = useHistory<{ id: number }>();
    const { stateHistory, user } = useDetailAction();
    const { handleOpen } = useModal();
    const { loading, setFile, file } = useDetailActionUser();

    const [loadingExport, setLoadingExport] = useState(false);

    const hasFile = file?.name ? true : false;

    async function handleTemplate() {
        setLoadingExport(true);
        const outputFilename = `template-${Date.now()}.xlsx`;
        try {
            const response = await user.file({
                action_id: stateHistory.id,
            });

            const blob = new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            FileSaver.saveAs(blob, outputFilename);
            toast.success("Download realizado com sucesso!");
            setLoadingExport(false);
        } catch (error) {
            setLoadingExport(false);
        }
    }

    function handleFile(e: React.ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0];

        if (!file) {
            toast.error("Nenhum arquivo selecionado!");
            return;
        }

        setFile(file);
    }

    return (
        <Container>
            <div className="container-text">
                <Text as="span" fontSize="xs" color="secondarydark">
                    Use o campo ao lado para inserir sua carga de usuários&nbsp;
                    {loadingExport ? (
                        <Loader width={15} height={15} />
                    ) : (
                        <button
                            onClick={handleTemplate}
                            data-testid="btn-download-template"
                        >
                            <Text as="h5">baixar template</Text>
                        </button>
                    )}
                </Text>
            </div>

            <label htmlFor={refFile} className={`${loading ? "disabled" : ""}`}>
                <input
                    type="file"
                    disabled={loading}
                    name={refFile}
                    id={refFile}
                    onChange={handleFile}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />

                <When expr={hasFile}>
                    <p>{file?.name}</p>
                </When>

                <When expr={!hasFile}>
                    <p>Selecione o arquivo</p>
                </When>

                <button
                    disabled={!hasFile}
                    onClick={() => handleOpen("loadUsers")}
                >
                    <img src={imgSetaCima} alt="" />
                </button>
            </label>

            <Button
                onClick={() => {
                    console.log(stateHistory.id);
                    history.push({
                        pathname: `/detalhe/acao/historico/${stateHistory.id}`,
                        state: stateHistory,
                    });
                }}
                disabled={loading}
            >
                Histórico de cargas
            </Button>
        </Container>
    );
}
