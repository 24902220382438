import React, { createContext, useContext, useState } from "react";
import { FormNewActionDataActionFormik } from "@/presentation/components/FormNewActionDataAction/config";
import { FormNewActionDataAccessFormik } from "@/presentation/components/FormNewActionDataAccess/config";
import { Contractor, CreateAction } from "@/domain/useCases";

interface UseNewActionProps {
    children: React.ReactNode | React.ReactNode[];
    create: CreateAction;
    contractor: Contractor;
}

export interface Tabs {
    id: number;
    title: string;
    className: string;
    active: boolean;
    viewer: boolean;
    permission: boolean;
}

export type TabId =
    | "Dados da ação"
    | "Acesso e resgate"
    | "Revisão e aprovação";

interface Data
    extends FormNewActionDataActionFormik,
        FormNewActionDataAccessFormik {}

export interface UseStateNewAction {
    tabs: Tabs[];
    setTabs: (tabs: Tabs[]) => void;
    updateTab(id: TabId): void;

    data: Data | any;
    setData: (data: Data | any) => void;
    action: CreateAction;
    contractor: Contractor;
}

const itens = [
    {
        id: 1,
        title: "Dados da ação",
        className: "dados_da_acao",
        active: true,
        viewer: true,
        permission: true,
    },
    {
        id: 2,
        title: "Acesso e resgate",
        className: "acesso_e_resgate",
        active: false,
        viewer: false,
        permission: false,
    },
    {
        id: 3,
        title: "Revisão e aprovação",
        className: "revisao_e_aprovacao",
        active: false,
        viewer: false,
        permission: false,
    },
];

const Context = createContext<UseStateNewAction>({} as UseStateNewAction);

export function UseNewActionProvider(props: UseNewActionProps) {
    const { children, create, contractor } = props;

    const [tabs, setTabs] = useState<Tabs[]>(itens);
    const [data, setData] = useState<Data>({} as Data);

    function updateTab(id: TabId) {
        const update = tabs.map((tab) => {
            if (tab.title === id) {
                return {
                    ...tab,
                    active: true,
                    viewer: true,
                    permission: true,
                };
            }

            return {
                ...tab,
                active: false,
                viewer: tab.viewer,
                permission: false,
            };
        });

        setTabs(update);
    }

    return (
        <Context.Provider
            value={{
                tabs,
                setTabs,
                updateTab,
                setData,
                data,
                action: create,
                contractor,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useNewAction() {
    const context = useContext(Context);
    return context;
}
