import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 48px;
    small {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: ${({ theme }) => theme.colors.secondarydark};
    }
`;
