import styled from "styled-components";
import { Container as Header } from "@/presentation/components/Header/styles";
export const Container = styled.div`
    .container-convert {
        padding-left: 55px;
    }

    ${Header} {
        .container-img {
            &:before {
                -webkit-mask-size: 75%;
            }
        }
    }
`;
