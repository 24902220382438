import { BrowserRouter } from "react-router-dom";
import { SwitchRoutes } from "@/presentation/components/SwitchRoutes";
import { ToastContainer } from "react-toastify";

import { Interceptor } from "@/presentation/components/Interceptor";
import { GlobalStyle } from "@/presentation/styles/global";
import { useModal } from "@/main/contexts/modal";
import { Alert } from "@/presentation/components/Alert";
import { Modal } from "./presentation/components/Modal";
import { Footer } from "@/presentation/components/Footer";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-toastify/dist/ReactToastify.css";
import "@/presentation/styles/fonts.css";

export function App() {
    const { state, setState } = useModal();

    return (
        <BrowserRouter>
            <GlobalStyle />
            <Interceptor />
            <SwitchRoutes />
            <ToastContainer />
            <Modal
                open={state?.alert?.active || false}
                handleClose={() => {
                    setState({
                        ...state,
                        alert: {
                            active: false,
                            onclick: () => {},
                        },
                    });
                }}
            >
                <Alert
                    handleAcepet={async () => {
                        if (state?.alert?.onclick) {
                            await state.alert.onclick();
                        }
                    }}
                />
            </Modal>
            <Footer />
        </BrowserRouter>
    );
}
