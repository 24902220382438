import { Text } from "alisson-application";
import { Container, ContainerProps } from "./styles";

interface QuantityCardProps extends ContainerProps {
    title: string;
    quantity: number;
}

export function QuantityCard(props: QuantityCardProps) {
    const { color, quantity, title } = props;

    return (
        <Container color={color}>
            <Text color="secondarydark" as="span" mb="6px">
                {title}
            </Text>
            <Text as="h1" color={color}>
                {quantity}
            </Text>
        </Container>
    );
}
