import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { Mixin } from "ts-mixer";
import { CombinedActionProductsMixins } from "@/data/useCases/combinedMixinsProducts/combinedActionProcuctsMixins";
import { RemoteActionProductGroupManagement } from "./RemoteActionProductGroupManagement";
import { RemoteActionProductSoftDelete } from "@/data/useCases/actionSoftDeleteProduct/remoteActionProductSoftDelete";
import { RemoteActionProductRestore } from "@/data/useCases/actionRestoreProduct/remoteActionProductRestore";
import { RemoteReorderVouchers } from "@/data/useCases/actionReorderVouchers/remoteActionReorderVouchers";

const axios = new AxiosHttpClient();

export class Voucher extends Mixin(
    CombinedActionProductsMixins,
    RemoteActionProductGroupManagement,
    RemoteActionProductSoftDelete,
    RemoteActionProductRestore,
    RemoteReorderVouchers
) {
    constructor() {
        super(axios);
    }
}
