/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Text } from "alisson-application";
import { FormLogin } from "./form";
import { Modal } from "@/presentation/components/Modal";
import imgLogin from "@/presentation/assets/img/login.png";
import imgLogo from "@/presentation/assets/img/logo-tela-login.svg";
import { Container } from "./styles";
import { SmsValidation } from "@/presentation/components/SmsValidation";
import { useModal } from "@/main/contexts/modal";
import { ModalBlockedUser } from "@/presentation/components/ModalBlockedUser";
import { Button } from 'alisson-application';

export function Login() {
    const { state, handleClose, handleOpen } = useModal();
    return (
        <Container>
            <div className="container-img">
                <img src={imgLogin} alt="" />
            </div>

            <div className="container-login">
                <Box className="container-logo" mb="88px">
                    <img src={imgLogo} alt="" />
                </Box>

                <Text as="h2" mb="19px" textAlign="center">
                    Acesso administrador
                </Text>
                <FormLogin />
            </div>

            <Modal
                handleClose={() => handleClose("smsValidation")}
                open={state.smsValidation}
            >
                {state.smsValidation ? <SmsValidation /> : <></>}
            </Modal>

            <Modal
                handleClose={() => handleClose("blockedUser")}
                open={state.blockedUser}
            >
                <ModalBlockedUser />
            </Modal>

        </Container>
    );
}
