import { ErrorMessage, Form, Formik, Field } from "formik";
import { validationWhats, valuesWhats } from "./config";
import { Container as Button } from "@/presentation/components/Button";
import imgWhats from "@/presentation/assets/icone/whats-login.svg";

export function FormWhats(props: any) {
    const { setTimer, setDisabled, sendWhatsapp, phone, fecha } = props;
    // const [loading, setLoading] = useState(false);
    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={valuesWhats}
            validationSchema={validationWhats}
            onSubmit={async () => {
                await sendWhatsapp();
                fecha();
                setTimer(true);
                setDisabled(true);
            }}
        >
            {() => {
                return (
                    <Form>
                        <img src={imgWhats} alt="" />

                        <div className="container-input">
                            <h3>Receber por Whatsapp</h3>
                            <Field
                                name="sms"
                                type="tel"
                                value={phone}
                                disabled={true}
                            />
                            <ErrorMessage
                                name="text"
                                data-testid="error"
                                component="span"
                            />
                        </div>

                        <Button type="submit">Enviar</Button>
                    </Form>
                );
            }}
        </Formik>
    );
}
