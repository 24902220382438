import styled from "styled-components";

export const Container = styled.div`
    width: 392px;
    height: auto;
    background: ${(props) => props.theme.colors.white};
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container-header {
        margin-bottom: 37px;
        h1 {
            display: flex;
            align-items: center;
            img {
                display: block;
                margin-right: 12px;
                margin-bottom: 33px;
                margin-top: 60px;
            }
        }
    }
    img {
        margin-bottom: 33px;
        margin-top: 60px;
    }

    h1 {
        display: flex;
        align-items: center;
        color: #D50632;
        text-align: center;
        font-family: Lato;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    h2 {
        color: #5E5F5F;
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;
    }
    p {
        color: #5E5F5F;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 50px;
        text-align: center;
    }

    button {
        max-width: 334px;
        background-color: #6F3E98;
        margin-bottom: 43px;
        &.btn-close {
            position: absolute;
            top: 30px;
            right: 30px;
        }
    }
`;
