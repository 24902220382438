import styled from "styled-components";

export interface ContainerProps {
    color: "primary" | "warning" | "success" | "failure";
}

export const Container = styled.div<ContainerProps>`
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid #d4d4d4;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    height: 71px;
    width: 114px;
    position: relative;
    span {
    }
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: ${(props) => props.theme.colors[props.color]};
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
`;
