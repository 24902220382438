import { NewAction } from "@/presentation/components/NewAction";
import { UseNewActionProvider } from "@/main/hooks/newAction";
import { AxiosHttpClient } from "@/infra/http/axiosClient/axiosClient";
import { RemoteCreatAction } from "@/data/useCases/creatAction/remoteCreatAction";
import { RemoteContractor } from "@/data/useCases/contractor/remoteContractor";

export function PageNewAction() {
    const axios = new AxiosHttpClient();
    const create = new RemoteCreatAction(axios);
    const contractor = new RemoteContractor(axios);

    return (
        <UseNewActionProvider create={create} contractor={contractor}>
            <NewAction />
        </UseNewActionProvider>
    );
}
