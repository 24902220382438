import * as Yup from "yup";


export const valuesWhats = {
  whatsapp: "",
};

export const validationWhats = Yup.object().shape({
  whatsapp: Yup.string(),
});

