import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DetailVoucherModel, FindActionModel } from "@/domain/models";

import { Voucher } from "@/data/useCases/voucher/voucher";
import { Custumize } from "@/data/useCases/custumize/custumize";
import { Information } from "@/data/useCases/information/information";
import { User } from "@/data/useCases/user/user";
import { Report } from "@/data/useCases/report/report";
import { usePermissions } from "../contexts/permissions";

interface UseDetailActionProps {
    children: React.ReactNode | React.ReactNode[];
    information: Information;
    user: User;
    voucher: Voucher;
    custumize: Custumize;
    report: Report;
}

export interface UseStateDetailAction {
    tabs: Tabs[];
    setTabs: (tabs: Tabs[]) => void;
    updateTab(id: TabId): void;

    information: Information;
    user: User;
    voucher: Voucher;
    custumize: Custumize;
    report: Report;

    findActionData: FindActionModel;
    setFindActionData: (data: FindActionModel) => void;

    loading: boolean;
    stateHistory: historyProps;

    ref: React.MutableRefObject<HTMLDivElement | null>;
    fetchDetailVoucher(): Promise<void>;
    handleClick(asyncheight?: number): void;
    calls: number;
    vouchers: DetailVoucherModel;
    activeBtnVoucher: boolean;
}

export interface Tabs {
    id: number;
    title: string;
    className: string;
    active: boolean;
    viewer: boolean;
    permission: Boolean;
}

export type TabId =
    | "Informações"
    | "Personalização"
    | "Usuários"
    | "Dashboard"
    | "Vouchers"
    | "Relatórios";

export interface historyProps {
    id: number;
    client: string;

    avaliable: number;
    revoked: number;
    rewarded: number;
    total: number;
}

const Context = createContext<UseStateDetailAction>({} as UseStateDetailAction);

export function UseDetailActionProvider(props: UseDetailActionProps) {
    const { children, ...actions } = props;
    const { hasPermission } = usePermissions();

    const itens = [
        {
            id: 1,
            title: "Informações",
            className: "informacoes",
            active: true,
            viewer: true,
            permission: hasPermission("api/action/find/{id}", "GET"),
        },
        {
            id: 2,
            title: "Personalização",
            className: "personalizacao",
            active: false,
            viewer: false,
            permission: hasPermission("api/action/customize/{id}", "POST"),
        },
        {
            id: 3,
            title: "Usuários",
            className: "acesso_e_resgate",
            active: false,
            viewer: false,
            permission: hasPermission("api/action/users/{id}/{search?}", "GET"),
        },
        {
            id: 4,
            title: "Dashboard",
            className: "revisao_e_aprovacao",
            active: false,
            viewer: false,
            permission: false,
        },
        {
            id: 5,
            title: "Vouchers",
            className: "voucher",
            active: false,
            viewer: false,
            permission: hasPermission("api/action/products/metrics", "POST"),
        },
        {
            id: 6,
            title: "Relatórios",
            className: "relatorios",
            active: false,
            viewer: false,
            permission: hasPermission(
                "api/action/reports/download/{id}",
                "GET"
            ),
        },
    ];

    const [tabs, setTabs] = useState<Tabs[]>(itens);
    const [loading, setLoading] = useState(true);
    const [findActionData, setFindActionData] = useState<FindActionModel>(
        {} as FindActionModel
    );
    const ref = useRef<HTMLDivElement | null>(null);
    const [calls, setCalls] = useState(0);
    const [activeBtnVoucher, setActiveBtnVoucher] = useState<boolean>(false);
    const [vouchers, setVouchers] = useState<DetailVoucherModel>([]);

    const history = useHistory<historyProps>();
    const stateHistory = history.location.state;

    function updateTab(id: TabId) {
        const update = tabs.map((tab) => {
            if (tab.title === id) {
                return {
                    ...tab,
                    active: true,
                    viewer: true,
                    permission: tab.permission,
                };
            }

            return {
                ...tab,
                active: false,
                viewer: tab.viewer,
                permission: tab.permission,
            };
        });

        setTabs(update);
    }

    async function fetch() {
        if (!stateHistory.id) {
            toast.error("Não foi possível carregar os detalhes da ação");
            setLoading(false);
            return;
        }
        try {
            const response = await actions.information.loadByid(
                stateHistory.id
            );
            setFindActionData(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setFindActionData({} as FindActionModel);
            console.log(error);
        }
    }

    async function fetchDetailVoucher() {
        if (calls >= 1) return;

        try {
            const response = await actions.voucher.loadAllDetailVoucher(
                stateHistory.id
            );
            setVouchers(response);
            setCalls(calls + 1);

            handleClick(response.length * 72 + 52 + 56);
        } catch (error) {
            toast.error("Não foi possível carregar os vouchers");
            console.log(error);
        }
    }

    function handleClick(asyncheight?: number) {
        if (ref.current) {
            const el = ref.current as HTMLDivElement;
            const target = el.parentElement;
            const height = el.offsetHeight;

            if (target) {
                const hasActive = target?.classList.value.includes("active");

                if (hasActive) {
                    target.classList.remove("active");
                    target.style.height = `0px`;
                    setActiveBtnVoucher(false);
                } else {
                    target.classList.add("active");
                    target.style.height = `${
                        asyncheight ? asyncheight : height
                    }px`;
                    setActiveBtnVoucher(true);
                }
            }
        }
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <Context.Provider
            value={{
                stateHistory,
                tabs,
                setTabs,
                updateTab,
                loading,
                findActionData,
                setFindActionData,
                ref,
                fetchDetailVoucher,
                handleClick,
                calls,
                vouchers,
                activeBtnVoucher,
                ...actions,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useDetailAction() {
    const context = useContext(Context);
    return context;
}
