import { Text } from "alisson-application";
import { useDetailActionCustumize } from "@/presentation/components/DetailContent/hooks/detailActionCustumize";
import { Container as Button } from "@/presentation/components/Button";
import imgUser from "@/presentation/assets/icone/user-action.svg";
import imgOlhoRoxo from "@/presentation/assets/icone/olho-roxo.svg";
import imgPremio from "@/presentation/assets/icone/premio.svg";
import imgResgate from "@/presentation/assets/icone/resgate.svg";
import { Container } from "./styles";

export function ActionChoiceBox() {
    const { updateTab } = useDetailActionCustumize();

    return (
        <Container>
            <div className="container-choices">
                <div className="item">
                    <img src={imgUser} alt="" />
                    <Text as="h1">Tela de login</Text>
                </div>
                <div className="item">
                    <img src={imgOlhoRoxo} alt="" />
                    <Text as="h1">Ver modelo de página</Text>
                </div>

                <div className="item">
                    <Button onClick={() => updateTab("Tela de login")}>
                        Editar
                    </Button>
                </div>
            </div>

            <div className="container-choices">
                <div className="item">
                    <img src={imgUser} alt="" />
                    <Text as="h1">Tela de vídeo</Text>
                </div>
                <div className="item">
                    <img src={imgOlhoRoxo} alt="" />
                    <Text as="h1">Ver modelo de página</Text>
                </div>

                <div className="item">
                    <Button onClick={() => updateTab("Tela de video")}>
                        Editar
                    </Button>
                </div>
            </div>

            <div className="container-choices">
                <div className="item">
                    <img src={imgPremio} alt="" />
                    <Text as="h1">Tela escolha do prêmio</Text>
                </div>
                <div className="item">
                    <img src={imgOlhoRoxo} alt="" />
                    <Text as="h1">Ver modelo de página</Text>
                </div>

                <div className="item">
                    <Button onClick={() => updateTab("Tela escolha do prêmio")}>
                        Editar
                    </Button>
                </div>
            </div>

            <div className="container-choices">
                <div className="item">
                    <img src={imgResgate} alt="" />
                    <Text as="h1">Tela resgate</Text>
                </div>
                <div className="item">
                    <img src={imgOlhoRoxo} alt="" />
                    <Text as="h1">Ver modelo de página</Text>
                </div>

                <div className="item">
                    <Button onClick={() => updateTab("Tela resgate")}>
                        Editar
                    </Button>
                </div>
            </div>
        </Container>
    );
}
