import { createGlobalStyle } from "styled-components";
import imgUser from "@/presentation/assets/icone/user.svg";
import imgCadeado from "@/presentation/assets/icone/cadeado.svg";
import imgSetaBaixo from "@/presentation/assets/icone/seta-baixo.svg";
import imgChave from "@/presentation/assets/icone/chave.svg";
import imgLink from "@/presentation/assets/icone/link.svg";
import imgBuscar from "@/presentation/assets/icone/buscar.svg";

export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        outline: 0;
        box-sizing: border-box;
    }

    #root{
        position: relative;
    }

    body {
        font-family: "Lato", Arial, Helvetica, sans-serif;
        margin: 0;
        background-color: ${({ theme }) => theme.colors.white};
        transition: all 0.5s;
    }

    input[type="file"]{
        display: none !important;
    }

    .remove-padding{
        padding: 0 !important;
    }

    div[style="position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;"]{
        display: none;
    }

    .public-DraftStyleDefault-ltr{
        text-align: -webkit-match-parent !important;
    }

    .charCounter{
        font-style: normal;
        font-size: 0.75rem;
        color: rgb(94, 95, 95);
        line-height: 100%;
        text-transform: none;
        font-weight: 400;
        margin-left: 10px;
        margin-top: 5px;
        font-family: Montserrat;
        font-style: italic;
        font-weight: 400;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    button{
        background-color: transparent;
        display: inline-block;
        border: none;
        cursor: pointer;
    }

    span {
        display: block;
    }

    a{
        text-decoration: none;
        color: inherit;
        &:hover {
            text-decoration:none; 
            cursor:pointer;  
        }
    }

    input[type="file"]{
        display: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    label{
        cursor: pointer;
    }

    .container{
        width: 1110px;
        margin: 0 auto;
        @media (max-width: 1440px){
            width: 90%
        }
    }

    .content{
        width: calc(100% - 153px);
        height: calc(100vh - 116px - 82px);
        overflow-x: hidden;
        overflow-y: scroll; 
        position: fixed;
        top: 116px;
        left: 153px;
        padding-right: 32px;
        padding-bottom: 32px;
        scrollbar-color: rgba(89, 92, 118, 0.5) rgba(255, 255, 255, 0.1);
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }


    div[data-testid="loading"]{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .button--disabled{
        color: rgb(189, 194, 196) !important;
    }

    label{
        &.disabled{
            opacity: 0.5;
            position: relative;
            cursor: not-allowed;
            &:before{
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                cursor: not-allowed;
                position: absolute;
                top: 0px;
                left: 0px;
                z-index: 10;
            }
        }
    }

    .container-input{
        position: relative;
        margin-bottom: 10px;
        &[data-loading="true"]{
                @keyframes spinner {
                to {transform: rotate(360deg);}
            }
        
            &::after {
                content: '';
                box-sizing: border-box;
                position: absolute;
                top: 65%;
                right: -32px;
                width: 15px;
                height: 15px;
                margin-top: -15px;
                margin-left: -15px;
                border-radius: 50%;
                border: 1px solid transparent;
                border-top-color: ${(props) => props.theme.colors.primary};
                animation: spinner .7s linear infinite;
            }
        }

        &.transparent{
            input{
                border:none;
                padding-left: 0px;
                padding-top: 6px;
                border-radius: 0px;
                transition: all .5s;
                &:disabled{
                    background-color: transparent;
                    opacity: 0.5;
                    transition: all .5s;
                }
            }
        }

        &.calendario{
            input{
                text-align: center;
                padding-left: 0px;
            }
        }
        &.seta-baixo{
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 55%;
                right: 20px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.primary};
                -webkit-mask: url(${imgSetaBaixo}) no-repeat;
                width: 14px;
                height: 10px;
                z-index: 20;
                transition: background-color 0.5s;
            }
        }

        &.user{
            input{
                padding-left: 56px;
            }
            &:before{
                -webkit-mask: url(${imgUser}) no-repeat;
                width: 24px;
                height: 24px;
                left: 15px;
            }
        }
        &.cadeado{
            input{
                padding-left: 56px;
            }
            &:before{
                -webkit-mask: url(${imgCadeado}) no-repeat;
                width: 24px;
                height: 24px;
                left: 15px;
            }
        }
        &.chave{
            input{
                padding-left: 47px;
            }
            &:before{
                -webkit-mask: url(${imgChave}) no-repeat;
                width: 16px;
                height: 16px;
                -webkit-mask-size: contain;
                left: 15px;
            }
        }
        &.link{
            input{
                padding-left: 47px;
            }
            &:before{
                -webkit-mask: url(${imgLink}) no-repeat;
                width: 16px;
                height: 16px;
                -webkit-mask-size: contain;
                left: 15px;
            }
        }
        &.buscar{
            input{
                padding-left: 47px;
            }
            &:before{
                -webkit-mask: url(${imgBuscar}) no-repeat;
                width: 18px;
                height: 18px;
                top: 55%;
                -webkit-mask-size: contain;
                left: 15px;
            }
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.primary};
            z-index: 20;
        }

        input, textarea{
            border: 1px solid ${(props) => props.theme.colors.input};
            border-radius: 100px;

            height: 40px;
            width: 100%;

            padding-left: 24px;

            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.5);
            transition: all .5s;
            &::placeholder{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: rgba(0, 0, 0, 0.5);
            }

            &[disabled]{
                background-color: #EAEAEA;
                cursor: not-allowed;
                transition: all .5s;
            }
        }

        textarea{
            padding-top: 10px;
            height: 119px;
            border-radius: 10px;
        }
    }
`;
