import styled from "styled-components";
export const Container = styled.div`
    padding-top: 52px;
    padding-left: 79px;

    .forms {
        padding-top: 52px;
        position: relative;
        .container-input {
            &.text-LGPD {
                transition: all.5s;
                position: relative;

                &.disabled {
                    cursor: not-allowed;
                    .container-draf {
                        filter: grayscale(1);
                        transition: all 0.5s;
                        pointer-events: none;
                        position: relative;

                        z-index: 10;
                        &::after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            z-index: 999;
                        }
                    }
                }

                .container-draf {
                    width: 101%;
                    height: 100%;
                    border-radius: 10px;
                    transition: all 0.5s;
                    z-index: 20;
                }
            }
        }

        .container-tab {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            pointer-events: none;
            opacity: 0;
            transition: all 0.3s ease;
            padding-top: 59px;
            &.remove-padding {
                padding: 0px;
            }
            &.active {
                pointer-events: all;
                opacity: 1;
                transition: all 0.3s ease;
            }
        }
    }
`;
