import { HttpStatusCode } from "@/data/protocols/http";
import { HttpClient } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import {
    ActionUserLoadModel,
    ActionUserLoadModelParams,
} from "@/domain/models";

import { ActionUserLoad } from "@/domain/useCases";
export type keyOfActionProductsCustomize<T> = keyof T;
export class RemoteActionUserload implements ActionUserLoad {
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlUserLoad: string = "action/users/import"
    ) {}

    async upload(
        params: ActionUserLoadModelParams
    ): Promise<ActionUserLoadModel> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlUserLoad,
            body: this.formatUserLoad(params),
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }

    formatUserLoad(params: ActionUserLoadModelParams): FormData {
        const formData = new FormData();
        const keys = Object.keys(
            params
        ) as keyOfActionProductsCustomize<ActionUserLoadModelParams>[];

        keys.forEach((key) => {
            if (key !== "file") {
                formData.append(key, params[key] as string);
            }
        });

        if (params.file) {
            formData.append("file", params.file);
        }

        return formData;
    }
}
