import styled from "styled-components";
import { Container as Button } from "@/presentation/components/Button";

export const Container = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    .container-search {
        display: grid;
        grid-template-columns: 1fr 89px;
        grid-gap: 10px;
        align-items: center;
        width: 442px;
        .container-input {
            margin-bottom: 0px;
        }

        ${Button} {
            width: 89px;
        }
    }
`;
