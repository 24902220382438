import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { InvalidCredentialsErros, UnexpectedError } from "@/domain/errors";
import { ActionProductsGroupDeleteParams } from "@/domain/models";

import { ActionProductsGroupDelete } from "@/domain/useCases/actionProductsGroupDelete";

export class RemoteActionProductsGroupDelete
    implements ActionProductsGroupDelete
{
    constructor(
        protected readonly httpClient: HttpClient,
        protected readonly urlDeleteGroup: string = "action/products/groups/delete"
    ) {}

    async deleteGroup(params: ActionProductsGroupDeleteParams): Promise<void> {
        const response = await this.httpClient.request({
            method: "post",
            url: this.urlDeleteGroup,
            body: params,
        });

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                return response.body;

            case HttpStatusCode.unauthorized:
                throw new InvalidCredentialsErros();
            default:
                throw new UnexpectedError();
        }
    }
}
