import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .container-item {
        display: flex;
        align-items: flex-end;
        border-bottom: 1px solid #d4d4d4;
        padding-bottom: 11px;
        &:last-child {
            padding-bottom: 0px;
            border-bottom: unset;
        }
        .container-icone {
            margin-right: 12px;
            img {
                width: 17px;
                height: 18px;
            }
        }
    }
`;
