export class Url {
    slug(url: string): string {
        const textoSemEspacos = url.trim();

        // Substitui espaços em branco por hífens
        const urlComHifens = textoSemEspacos.replace(/\s+/g, "-");

        // Converte para minúsculas e remove acentos
        const urlMinusculaSemAcentos = urlComHifens
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

        // Remove caracteres especiais, exceto hífens e underscores
        const urlSemCaracteresEspeciais = urlMinusculaSemAcentos.replace(
            /[^\w\s-]/g,
            "-"
        );

        // Remove caracteres repetidos
        const urlSemRepeticoes = urlSemCaracteresEspeciais.replace(
            /([-_])\1+/g,
            "$1"
        );

        const substituiUnderscoresPorHifen = urlSemRepeticoes.replace(
            /_/g,
            "-"
        );

        // Remove hífens e underscores no início e no final
        const urlSemHifensUnderscoresNoInicioFim =
            substituiUnderscoresPorHifen.replace(/^-+|-+$/g, "");

        // Retorna a URL válida
        return urlSemHifensUnderscoresNoInicioFim;
    }

    isValidURL(url: string): boolean {
        const urlPattern =
            /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
        return urlPattern.test(url);
    }
}
