import { TailSpin } from "react-loader-spinner";

interface LoaderProps {
    height?: number | string;
    width?: number | string;
}

export function Loader(props: LoaderProps) {
    const { width = 100, height = 100 } = props;

    return (
        <div data-testid="loading">
            <TailSpin
                height={height}
                width={width}
                color="#6F3E98"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </div>
    );
}
