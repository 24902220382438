import styles from "./newHeader.module.css";
import LogoYetzPromo from "../../assets/icone/logo-yetz-promo.svg";

const NewHeader = () => {
  return (
    <div className={styles.generalContainer}>
        <div className={styles.insiderContainer}>
            <img src={LogoYetzPromo} alt="Logo Yetz Link" />
        </div>
    </div>
  );
}

export default NewHeader;