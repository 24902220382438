import { HttpStatusCode } from "@/data/protocols/http";

export class InvalidCredentialsErros extends Error {
    status: HttpStatusCode;

    constructor(status: HttpStatusCode = HttpStatusCode.unauthorized) {
        super("Credenciais inválidas");
        this.name = "InvalidCredentialsErros";
        this.status = status;
    }
}