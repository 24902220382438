import { parse } from "date-fns";
import { HttpClient, HttpStatusCode } from "@/data/protocols/http";
import { AccessDeniedError } from "@/domain/errors/accessDeniedError";
import { ListActions } from "@/domain/useCases/listAction";
import { ActionModel, Action, Pagination } from "@/domain/models";
import { UnexpectedError } from "@/domain/errors";
import { ValidationDate } from "@/services/date";
import { NumberFormat } from "@/services/number";

export class RemoteListActions implements ListActions {
    private data: Action[] = [];
    constructor(
        private readonly httpGetClient: HttpClient<ActionModel>,
        private readonly url: string = "action/allPaginated"
    ) {}

    async loadAll(page?:number): Promise<Action[]> {
        const response = await this.httpGetClient.request({
            url: `${this.url}${page ? "?page=" + page : ""}`,
            method: "get",
        });
        const remoteAction = response.body || ({} as ActionModel);

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                this.data = this.format(remoteAction.actions, remoteAction.pagination);
                return this.format(remoteAction.actions, remoteAction.pagination);
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [] as Action[];
            default:
                throw new UnexpectedError();
        }
    }

    public format(actions: Action[] = [], pagination: Pagination): Action[] {
        const blockedList: Action[] = [];
        const numberFormat = new NumberFormat();
        const newList = actions.map((action) => {
            return {
                ...action,
                quantity_of_users: numberFormat.formatNumber(
                    action.quantity_of_users
                ),
                vouchers: {
                    avaliable: numberFormat.formatNumber(
                        action.vouchers.avaliable
                    ),
                    revoked: numberFormat.formatNumber(action.vouchers.revoked),
                    rewarded: numberFormat.formatNumber(
                        action.vouchers.rewarded
                    ),
                    total: numberFormat.formatNumber(action.vouchers.total),
                },
                pagination: {
                    currentPage: pagination.currentPage,
                    lastPage: pagination.lastPage,
                    perPage: pagination.perPage,
                    total: pagination.total,
                },
                start_date: new ValidationDate(action.start_date).user(),
                end_date: new ValidationDate(action.end_date).user(),
            };
        }) as unknown as Action[];

        for (let i = 0; i < newList.length; i++) {
            if (newList[i].blocked === 1) {
                blockedList.push(newList.splice(i, 1)[0]);
                i--;
            }
        }

        return [...this.orderBydate(newList), ...this.orderBydate(blockedList)];
    }

    orderBydate(actions: Action[]): Action[] {
        return actions.sort((a, b) => {
            const dateA = new Date(a.start_date);
            const dateB = new Date(b.start_date);
            return dateA.getTime() - dateB.getTime();
        });
    }

    filter(search?: string, dates?: string[]): Action[] {
        if (!search && !dates?.length) return this.data;
        let update = [...this.data];

        if (dates?.every((date) => date) && dates?.length === 2) {
            const [start_date, end_date] = dates;
            const dataInicioObj = parse(start_date, "yyyy-MM-dd", new Date());
            const dataFimObj = parse(end_date, "yyyy-MM-dd", new Date());

            update = update.filter((item) => {
                const startDate = parse(
                    item.start_date,
                    "dd/MM/yyyy",
                    new Date()
                );
                const endDate = parse(item.end_date, "dd/MM/yyyy", new Date());

                if (
                    dataInicioObj.getTime() <= startDate.getTime() &&
                    dataFimObj.getTime() >= endDate.getTime()
                ) {
                    return item;
                }
            });
        }

        if (search) {
            update = [
                ...update.filter((data) => {
                    return JSON.stringify(data, [
                        "contractor_name",
                        "end_date",
                        "job",
                        "name",
                        "start_date",
                    ])
                        .toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase());
                }),
            ];
        }

        return update;
    }

    async filterBack(search?: string, status?: any, page?: number): Promise<Action[]> {
        if (!search && !status && !page) return this.data;

        let params = ""
        if(search || status || page){
            params = "?"
        }

        const response = await this.httpGetClient.request({
            url: `action/allPaginated${params}search=${search ? search : ""}&status=${status && status?.value}&page=${page ? page : ""}`,
            method: "get",
        });
        const remoteAction = response.body || ({} as ActionModel);

        switch (response.statusCode) {
            case HttpStatusCode.ok:
                this.data = this.format(remoteAction.actions, remoteAction.pagination);
                return this.format(remoteAction.actions, remoteAction.pagination);
            case HttpStatusCode.forbidden:
                throw new AccessDeniedError();
            case HttpStatusCode.noContent:
                return [] as Action[];
            default:
                throw new UnexpectedError();
        }
    }
}
