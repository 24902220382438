import styled from "styled-components";

interface ContainerProps {
    cursor?: boolean;
}

export const Container = styled.div`
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    background-color: ${(props) => props.theme.colors.white};
    height: 124px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: ${(props: ContainerProps) =>
        props.cursor ? "pointer" : "default"};
    a {
        color: ${(props) => props.theme.colors.primary};
    }
`;
