import { Header } from "@/presentation/components/Header";
import { ButtonGoback } from "@/presentation/components/ButtonGoback";
import { useHistoryAction } from "@/main/hooks/historyAction";
import { TableLoad } from "./table";
import { Container } from "./styles";
import imgIconeAction from "@/presentation/assets/icone/detalhe-acao.svg";
export function HistoryActionLoad() {
    const { stateHistory } = useHistoryAction();
    return (
        <Container>
            <Header
                icone={imgIconeAction}
                title="Histórico de Cargas"
                subTitle={stateHistory.client}
            >
                <ButtonGoback />
            </Header>

            <TableLoad />
        </Container>
    );
}
