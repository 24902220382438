import { ActionCustumizeParams } from "@/domain/models";
import * as Yup from "yup";

export type IValues = Omit<ActionCustumizeParams, "id">;

export const initialValues: IValues = {
    primary_logo: undefined,
    secondary_logo: undefined,
    background: "",
    button_color: "",
    login_title: "",
    login_subtitle: "",

    use_video: 0,
    video_required: 0,
    video_title: "",
    video_subtitle: "",
    video_link: "",
    video_thumbnail: "",
    video_button_to_rewards: "",

    job: "",
    use_lgpd: 0,
    lgpd_text: "",
    internal_policy: "",
    logo: undefined,

    blocked_text: "",

    personalized_blocked_text: "",
    blocked_text_start_date: "",
    blocked_text_end_date: "",

    personalized_login_error_message: "",
    login_message_start_date: "",
    login_message_end_date: ""
};

export const validationLogin = Yup.object().shape({
    background: Yup.string(),
    button_color: Yup.string(),
    login_title: Yup.string(),
    login_subtitle: Yup.string(),
    primary_logo: Yup.mixed().test(
        "fileType",
        "Somente são permitidas imagens JPEG, PNG ou GIF",
        (value) => {
            if (!value) return true;
            if (typeof value === "string") return true;

            const validExtensions = ["jpg", "jpeg", "png", "gif"];
            const fileExtension = value.name.split(".").pop()?.toLowerCase();
            return (
                fileExtension !== undefined &&
                validExtensions.includes(fileExtension)
            );
        }
    ),

    secondary_logo: Yup.mixed().test(
        "fileType",
        "Somente são permitidas imagens JPEG, PNG ou GIF",
        (value) => {
            if (!value) return true;
            if (typeof value === "string") return true;

            const validExtensions = ["jpg", "jpeg", "png", "gif"];
            const fileExtension = value.name.split(".").pop()?.toLowerCase();
            return (
                fileExtension !== undefined &&
                validExtensions.includes(fileExtension)
            );
        }
    ),

    use_video: Yup.number(),
    video_required: Yup.number(),
    video_title: Yup.string(),
    video_subtitle: Yup.string(),
    video_link: Yup.string(),
    video_thumbnail: Yup.string(),
    video_button_to_rewards: Yup.string(),

    job: Yup.string(),
    use_lgpd: Yup.number(),
    lgpd_text: Yup.string(),
    internal_policy: Yup.string(),
    logo: Yup.mixed().test(
        "fileType",
        "Somente são permitidas imagens JPEG, PNG ou GIF",
        (value) => {
            if (!value) return true;
            if (typeof value === "string") return true;

            const validExtensions = ["jpg", "jpeg", "png", "gif"];
            const fileExtension = value.name.split(".").pop()?.toLowerCase();
            return (
                fileExtension !== undefined &&
                validExtensions.includes(fileExtension)
            );
        }
    ),

    blocked_text: Yup.string(),
    personalized_blocked_text: Yup.string(),
    blocked_text_start_date: Yup.string(),
    blocked_text_end_date: Yup.string(),

    personalized_login_error_message: Yup.string(),
    login_message_start_date: Yup.string(),
    login_message_end_date: Yup.string()
});
